<template>
  <div class="whole_page">
    <div class="page_container">
      <div style="display: flex; flex-direction: column; gap: 20px">
        <template v-if="!isLoading">
          <div class="content_container">
            <h1 class="title-h1-roboto-medium">
              Перевод между детьми
            </h1>
            <div>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
                <h4 class="body-text-roboto-medium">
                  Счет списания
                </h4>
                <div style="display: flex; gap: 12px; flex-direction: column">
                  <v-select
                    v-model="selectedUserFrom"
                    variant="solo"
                    density="compact"
                    hide-details="true"
                    label="Выбранный ребёнок"
                    :style="{width: '178px'}"
                    :items="userOptions"
                  />
                  <v-select
                    v-model="selectedFrom"
                    variant="solo"
                    density="compact"
                    label="Тип  счёта"
                    :style="{width: '178px'}"
                    :items="accountsOptions"
                  />
                </div>
              </v-row>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
                <h4 class="body-text-roboto-medium">
                  Счет зачисления
                </h4>
                <div style="display: flex; gap: 12px; flex-direction: column">
                  <v-select
                    v-model="selectedUserTo"
                    variant="solo"
                    density="compact"
                    hide-details="true"
                    label="Выбранный ребёнок"
                    :style="{width: '178px'}"
                    :items="userOptions"
                  />
                  <v-select
                    v-model="selectedTo"
                    variant="solo"
                    density="compact"
                    label="Тип  счёта"
                    :style="{width: '178px'}"
                    :items="accountsOptions"
                  />
                </div>
              </v-row>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: end; gap: 16px">
                <h4
                  class="body-text-roboto-medium"
                  style="align-self: center; margin-bottom: 20px"
                >
                  Сумма перевода
                </h4>
                <!--        <div style="width: 66px; padding: 12px 8.5px; background: #FFFFFF; border-radius: 7px; gap: 16px">-->
                <!--          <h3 class="button-text-roboto-medium">250 Р</h3>-->
                <!--        </div>-->
                <v-text-field
                  v-model="transferSum"
                  :rules="sumRules"
                  variant="solo"
                  density="compact"
                  style="max-width: 66px; min-height: 36px; border-radius: 7px;"
                />
              </v-row>
              <v-row style="justify-content: space-between; padding: 16px 12px; text-align: center">
                <h4
                  class="small-text-roboto-medium"
                  style="text-decoration: underline"
                >
                  Разрешить перевод между счетами
                </h4>
                <div style="align-self: center">
                  <label class="toggle-switch">
                    <input
                      v-model="transferAccountSetting"
                      type="checkbox"
                      :disabled="transferAccountSetting"
                      @change="saveUserLicenceAccount"
                    >
                    <div class="slider round" />
                  </label>
                </div>
              </v-row>
              <v-row style="justify-content: space-between; padding: 16px 12px; text-align: center">
                <h4
                  class="small-text-roboto-medium"
                  style="text-decoration: underline"
                >
                  Разрешить перевод между детьми
                </h4>
                <div style="align-self: center">
                  <label class="toggle-switch">
                    <input
                      v-model="transferChildrenSetting"
                      type="checkbox"
                      :disabled="transferChildrenSetting"
                      @change="saveUserLicenceChildren"
                    >
                    <div class="slider round" />
                  </label>
                </div>
              </v-row>
              <div style="display: grid; margin-top: 16px">
                <button
                  :disabled="isInvalidTransfer"
                  class="primary-green-button"
                  @click="transferMoneyBetweenAccounts"
                >
                  <!--            todo алидация кнопки в зависимости от настроек-->
                  Выполнить перевод
                </button>
              </div>
            </div>
          </div>
          <div
            class="attention_notification"
            style=" border-radius: 12px;"
          >
            <div class="attention-notification-text-box">
              <h4 class="body-text-roboto-medium">
                Обратите внимание что для осуществления перевода, оба счета должны принадлежать одному комбинату
              </h4>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import {
  transferBetweenAccounts,
  transferBetweenChildren,
  userLicensesGetByUserId,
  userLicensesSave
} from '@/shared/api/parents/moneyAccounting/api-requests'
import { TelegramAuthUsersApiRequest, TelegramAuthUsersItem } from '@/shared/api/preorders/api-types'
import { telegramAuthUsers } from '@/shared/api/preorders/api-requests'
import { defineComponent } from 'vue'
// todo добавить валидацию и отображение баланса
export default defineComponent({
  name: 'ParentsTransferBetweenChildren',
  computed: {
    ...mapGetters({ user: 'user/user' }),
    isInvalidTransfer () {
      if (this.selectedUserFrom.value === this.selectedUserTo.value && this.selectedFrom === this.selectedTo) {
        return true
      }
      if (isNaN(this.transferSum)) {
        return true
      }
      if (this.transferSum < 0) {
        return true
      }
      if (!this.transferSum) {
        return true
      }
      if (!this.transferAccountSetting) {
        return true
      }
      if (this.selectedUserFrom.value === this.selectedUserTo.value && !this.transferChildrenSetting) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      currentUser: {} as Person,
      transferSum: 0,
      authUsers: null as null | TelegramAuthUsersItem[],
      accountsOptions: [{ title: 'Горячее питание', value: 1 }, { title: 'Буфет', value: 2 }],
      userOptions: [] as any[],
      transferChildrenSetting: false as boolean,
      transferAccountSetting: false,
      selectedFrom: 1,
      selectedTo: 2,
      selectedUserFrom: { title: '', value: 0 },
      selectedUserTo: { title: '', value: 0 },
      isTelegram: false,
      isLoading: false,
      sumRules: [
        (value: any) => {
          if (value || value === undefined) return true
          return 'Пустое поле.'
        },
        // (value: any) => {
        //   if (value?.length <= 4 || value === undefined) return true
        //   return 'Максимум 4 цифры.'
        // },
        (value: any) => {
          if (!isNaN(value) || value === undefined) return true
          return 'Должно быть число.'
        },
        // (value: any) => {
        //   if (value <= this.maxInputValue || value === undefined) return true
        //   return 'Максимальная сумма оплаты 2000 руб.'
        // },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) || value === undefined) return true
          return 'Введите целое число'
        },
        (value: any) => {
          if (parseFloat(value) >= 0 || value === undefined) return true
          return 'Введите натуральное число'
        }
      ],
    }
  },
  async beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.isLoading = true
    this.currentUser = this.user.person
    this.selectedUserFrom.value = this.currentUser.id
    this.selectedUserFrom.title = this.currentUser.firstName + ' ' + this.currentUser.lastName.charAt(0) + '.'
    await this.getUserLicencesByUserId()
    await this.getAuthUsersTransfer()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getUserLicencesByUserId () {
      try {
        const getUserLicencesByUserIdAccountReqObj = {
          userId: this.currentUser.id,
          typeId: 1,
          id: null
        }
        const getUserLicencesByUserIdChildrenReqObj = {
          userId: this.currentUser.id,
          typeId: 3,
          id: null
        }
        const getUserLicencesByUserIdAccountRes = await userLicensesGetByUserId(getUserLicencesByUserIdAccountReqObj, this.isTelegram)
        if (getUserLicencesByUserIdAccountRes.data[0].removed === null) {
          this.transferAccountSetting = true
        } else {
          this.transferAccountSetting = false
        }
        const getUserLicencesByUserIdChildrenRes = await userLicensesGetByUserId(getUserLicencesByUserIdChildrenReqObj, this.isTelegram)
        console.log(getUserLicencesByUserIdChildrenRes.data, 'get')
        if (getUserLicencesByUserIdChildrenRes.data[0].removed === null) {
          this.transferChildrenSetting = true
        } else {
          this.transferChildrenSetting = false
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить настройку разрешения перевода между детьми!',
          timeout: 3000
        })
      }
    },
    async saveUserLicenceChildren () {
      try {
        const saveUserLicenceReqObj = {
          userId: this.currentUser.id,
          typeId: 3
        }
        const saveUserLicenceRes = await userLicensesSave(saveUserLicenceReqObj, this.isTelegram)
        await this.getUserLicencesByUserId()
        console.log(saveUserLicenceRes, 'save')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось сохранить настройку разрешения перевода между детьми!',
          timeout: 3000
        })
      }
    },
    async saveUserLicenceAccount () {
      try {
        const saveUserLicenceReqObj = {
          userId: this.currentUser.id,
          typeId: 1
        }
        const saveUserLicenceRes = await userLicensesSave(saveUserLicenceReqObj, this.isTelegram)
        await this.getUserLicencesByUserId()
        console.log(saveUserLicenceRes, 'save')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось сохранить настройку разрешения перевода между счетами!',
          timeout: 3000
        })
      }
    },
    async transferMoneyBetweenAccounts () {
      // todo check customerId ??
      try {
        if (this.selectedUserFrom.value !== this.selectedUserTo.value) {
          const transferMoneyBetweenAccountsReqObj = {
            userId: this.selectedUserFrom.value,
            toUserId: this.selectedUserTo.value,
            sum: this.transferSum,
            fromAccountTypeId: this.selectedFrom,
            toAccountTypeId: this.selectedTo
          }
          console.log('asd', transferMoneyBetweenAccountsReqObj)
          const transferMoneyBetweenAccountsRes = await transferBetweenChildren(transferMoneyBetweenAccountsReqObj, this.isTelegram)
          console.log(transferMoneyBetweenAccountsRes, 'transfer')
        } else {
          if (this.selectedFrom !== this.selectedTo) {
            const transferMoneyBetweenAccountsReqObj = {
              userId: this.currentUser.id,
              sum: this.transferSum,
              fromAccountTypeId: this.selectedFrom,
              toAccountTypeId: this.selectedTo
            }
            const transferMoneyBetweenAccountsRes = await transferBetweenAccounts(transferMoneyBetweenAccountsReqObj, this.isTelegram)
            console.log(transferMoneyBetweenAccountsRes, 'transfer')
          }
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось выполнить перевод между счетами!',
          timeout: 3000
        })
      }
    },
    async getAuthUsersTransfer () {
      console.log('getAuthUsers')
      try {
        const chatId = localStorage.getItem('chatId')
        const tgToken = localStorage.getItem('tgToken')
        if (chatId !== null && tgToken !== null) {
          const telegramAuthUsersReqObj: TelegramAuthUsersApiRequest = {
            chatId: chatId,
            token: tgToken
          }

          const res = await telegramAuthUsers(telegramAuthUsersReqObj)
          if (res.data?.length > 0) {
            this.authUsers = res.data
          }
          if (this.authUsers !== null) {
            this.authUsers.forEach(user => {
              const userOption = {
                title: user.user.firstName + ' ' + user.user.lastName.charAt(0) + '.',
                value: user.user.id
              }
              this.userOptions.push(userOption)
            })
          }
          console.log('userOptions', this.userOptions)
          console.log('telegramAuthUsers', res)
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список авторизованных пользователей!',
          timeout: 3000
        })
      } finally {
      }
    },
    goToMoneyAccounting () {
      this.$router.push('./moneyAccounting')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 700px;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
