<template>
  <div class="whole_page">
    <div class="page_container">
      <div class="control_panel">
        <v-card style="width: 100%">
          <v-tabs
            v-model="tabs"
          >
            <v-tab
              value="0"
              class="secondary-white-button quote-text-roboto-medium"
              style="padding: 12px 9px; width: 50%; text-transform: none; letter-spacing: normal"
            >
              Запрет групп продуктов
            </v-tab>
            <v-tab
              value="1"
              class="secondary-white-button quote-text-roboto-medium"
              style="padding: 12px 9px; width: 50%; text-transform: none; letter-spacing: normal"
            >
              Запрет Аллергенов
            </v-tab>
          </v-tabs>
          <v-window v-model="tabs">
            <v-window-item :key="1">
              <template v-if="showProductsTab">
                <div class="content_container">
                  <h4 class="body-text-roboto-medium">
                    Установленные запреты продуктов
                  </h4>
                  <button
                    class="primary-green-button"
                    style="max-width: 330px"
                    @click="showProductsSettings"
                  >
                    Редактировать
                  </button>
                  <table
                    class="small-text-roboto-medium custom-table"
                    style="border-radius: 8px; border: 1px solid #C0BEC5"
                  >
                    <thead>
                      <tr style="background: #ECF2F6">
                        <td>Тип счета</td>
                        <td>Группа</td>
                      </tr>
                    </thead>
                    <tr>
                      <td>Буфет</td>
                      <td>Группа 1</td>
                    </tr>
                    <tr>
                      <td>Горячее питание</td>
                      <td>Группа 4</td>
                    </tr>
                  </table>
                </div>
              </template>
              <template v-if="showProductSettingsCard">
                <div style="justify-content: center">
                  <div class="change_content_container">
                    <div style="justify-content: space-between; display: flex; flex-direction: row; gap: 30px; ">
                      <!--        <v-select solo dense label="Горячее питание" hide-details=false ></v-select>-->
                      <!--        <button @click="closeProductsSettings" class="primary-green-button">Сохранить</button>-->
                      <button
                        class="secondary-white-button"
                        style="width: 145px"
                        @click="closeProductsSettings"
                      >
                        Отмена
                      </button>
                      <button
                        class="primary-green-button"
                        style="width: 145px"
                        @click="closeProductsSettings"
                      >
                        Сохранить
                      </button>
                    </div>
                    <v-row class="content_row">
                      <h4 class="button-text-roboto-medium">
                        Выберите продукты для запрета
                      </h4>
                    </v-row>
                    <v-row class="content_row">
                      <h4 class="body-text-roboto-medium">
                        Группа 1
                      </h4>
                      <div style="align-self: center">
                        <label class="toggle-switch">
                          <input type="checkbox">
                          <div class="slider round" />
                        </label>
                      </div>
                    </v-row>
                    <v-row class="content_row">
                      <h4 class="body-text-roboto-medium">
                        Группа 2
                      </h4>
                      <div style="align-self: center">
                        <label class="toggle-switch">
                          <input type="checkbox">
                          <div class="slider round" />
                        </label>
                      </div>
                    </v-row>
                    <v-row class="content_row">
                      <h4 class="body-text-roboto-medium">
                        Группа 3
                      </h4>
                      <div style="align-self: center">
                        <label class="toggle-switch">
                          <input type="checkbox">
                          <div class="slider round" />
                        </label>
                      </div>
                    </v-row>
                    <v-row class="content_row">
                      <h4 class="body-text-roboto-medium">
                        Группа 4
                      </h4>
                      <div style="align-self: center">
                        <label class="toggle-switch">
                          <input type="checkbox">
                          <div class="slider round" />
                        </label>
                      </div>
                    </v-row>
                  </div>
                </div>
              </template>
            </v-window-item>
            <v-window-item :key="2">
              <template v-if="showAllergensTab">
                <div style="justify-content: center; ">
                  <div class="page_container">
                    <div class="content_container">
                      <h4 class="body-text-roboto-medium">
                        Установленные запреты Аллергенов
                      </h4>
                      <button
                        class="primary-green-button"
                        style="max-width: 330px"
                        @click="showAllergensSettings"
                      >
                        Редактировать
                      </button>
                      <table
                        class="small-text-roboto-medium custom-table"
                        style="border-radius: 8px; border: 1px solid #C0BEC5"
                      >
                        <thead>
                          <tr style="background: #ECF2F6">
                            <td>Аллерген</td>
                            <td>Группа</td>
                          </tr>
                        </thead>
                        <tr
                          v-for="allergen in userAllergens"
                          :key="allergen.id"
                        >
                          <td>{{ allergen.allergen.description }}</td>
                          <td v-if="allergen.allergen.allergenGroups[0].allergenGroupTypeId !== 6">
                            {{ allergenGroupsOptions[allergen.allergen.allergenGroups[0].allergenGroupTypeId].title }}
                          </td>
                          <td v-else>
                            {{ allergenGroupsOptions[5].title }}
                          </td>
                          <!--              todo ?зачем пустой allergenGroupTypeId = 5-->
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="showAllergensSettingsCard">
                <div style="display: grid; justify-content: center">
                  <div class="change_content_container">
                    <div style="justify-content: space-between; display: flex; flex-direction: row; gap: 12px; height: 46px; width: 326px">
                      <v-select
                        v-model="selectedAllergenGroup"
                        :items="allergenGroupsOptions"
                        variant="solo"
                        density="compact"
                        label="Цитрусовые"
                        hide-details="true"
                      />
                    </div>
                    <div style="justify-content: space-between; display: flex; flex-direction: row">
                      <button
                        class="secondary-white-button"
                        style="width: 145px"
                        @click="closeAllergensSettings"
                      >
                        Отмена
                      </button>
                      <button
                        class="primary-green-button"
                        style="width: 145px"
                        @click="userAllergensSaveMany"
                      >
                        Сохранить
                      </button>
                    </div>
                    <v-row class="content_row">
                      <h4 class="button-text-roboto-medium">
                        Выберите продукты для запрета
                      </h4>
                    </v-row>
                    <div>
                      <div
                        v-for="allergen in allAllergensList"
                        :key="allergen.allergenId"
                      >
                        <v-row
                          v-if="allergen.allergen.allergenGroups[0].allergenGroupTypeId === selectedAllergenGroup"
                          class="content_row"
                        >
                          <h4 class="body-text-roboto-medium">
                            {{ allergen.allergen.description }}
                          </h4>
                          <div style="align-self: center">
                            <label class="toggle-switch">
                              <input
                                v-model="allergensCheckboxes[allergen.allergenId]"
                                type="checkbox"
                                @change="addAllergenToSaveManyRequest(allergen)"
                              >
                              <div class="slider round" />
                            </label>
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-window-item>
          </v-window>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Person } from '@/store/user/types'
import { mapActions, mapGetters } from 'vuex'
import {
  allergenGroupsGetTypes,
  userAllergensGetByUserId,
  userAllergensSaveMany
} from '@/shared/api/parents/notAllowed/api-requests'
import {
  AllergenGroupsGetTypesItem,
  UserAllergensGetByUserIdItem,
  UserAllergensSaveRequest
} from '@/shared/api/parents/notAllowed/api-types'
import { defineComponent } from 'vue'

export type AllergenGroupsOptionItem = {
  text: string,
  value: number
}
export default defineComponent({
  name: 'ParentsNotAllowed',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      showProductSettingsCard: false,
      showAllergensSettingsCard: false,
      tabs: 0,
      showProductsTab: true,
      showAllergensTab: true,
      allergenGroupsOptions: [{ title: 'Выберите группу аллергенов', value: 0 }],
      allAllergensList: [] as UserAllergensGetByUserIdItem[],
      userAllergens: [] as UserAllergensGetByUserIdItem[],
      selectedAllergenGroup: 1,
      isTelegram: false,
      allergensCheckboxes: [] as boolean[],
      changedAllergens: [] as UserAllergensSaveRequest[]
    }
  },
  async beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.getAllergenGroupsTypes()
    await this.getUserAllergensByUserId()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getAllergenGroupsTypes () {
      try {
        const getAllergenGroupsTypesRes = await allergenGroupsGetTypes(this.isTelegram)
        console.log(getAllergenGroupsTypesRes.data, 'sad')
        const resultArray = Object.values(getAllergenGroupsTypesRes.data)
        resultArray.forEach((allergenGroup: AllergenGroupsGetTypesItem) => {
          console.log(allergenGroup.description)
          if (allergenGroup.typeId === 1) {
            const allergenGroupsOptionItem = {
              title: allergenGroup.description,
              value: allergenGroup.id
            }
            this.allergenGroupsOptions.push(allergenGroupsOptionItem)
          }
        })
        console.log(this.allergenGroupsOptions, 'options')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить группы аллергенов!',
          timeout: 3000
        })
      }
    },
    async getUserAllergensByUserId () {
      this.userAllergens = []
      try {
        const getUserAllergensByUserIdReqObj = {
          userId: this.currentUser.id
        }
        const getUserAllergensByUserIdRes = await userAllergensGetByUserId(getUserAllergensByUserIdReqObj, this.isTelegram)
        this.allAllergensList = getUserAllergensByUserIdRes.data
        getUserAllergensByUserIdRes.data.forEach((allergen: UserAllergensGetByUserIdItem) => {
          this.allergensCheckboxes[allergen.allergenId] = false
          if (allergen.removed === null) {
            this.userAllergens.push(allergen)
            this.allergensCheckboxes[allergen.allergenId] = true
          }
          console.log(this.allergensCheckboxes[allergen.allergenId], allergen.removed,
            allergen.allergen.description, allergen.allergenId, 'checkboxes')
        })
      } catch (e) {
        console.log(e)
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить группы аллергенов!',
          timeout: 3000
        })
      }
    },
    async userAllergensSaveMany () {
      try {
        const userAllergensSaveManyReqObj = {
          userId: this.currentUser.id,
          userAllergens: this.changedAllergens
        }
        console.log(userAllergensSaveManyReqObj, 'savetry')
        await userAllergensSaveMany(userAllergensSaveManyReqObj, this.isTelegram)
        await this.getUserAllergensByUserId()
        this.closeAllergensSettings()
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось сохранить аллергены пользователя!',
          timeout: 3000
        })
      }
    },
    addAllergenToSaveManyRequest (allergen: UserAllergensGetByUserIdItem) {
      console.log(this.allergensCheckboxes[allergen.allergenId], allergen.id, 'check')
      const changedAllergenItem = {
        id: allergen.id,
        userId: this.currentUser.id,
        allergenId: allergen.allergenId,
        active: this.allergensCheckboxes[allergen.allergenId] ? 1 : 0
      }
      this.changedAllergens.push(changedAllergenItem)
    },
    goToHome () {
      this.$router.push('./home')
    },
    showProductsSettings () {
      this.showProductSettingsCard = true
      this.showProductsTab = false
    },
    closeProductsSettings () {
      this.showProductSettingsCard = false
      this.showProductsTab = true
    },
    showAllergensSettings () {
      this.showAllergensSettingsCard = true
      this.showAllergensTab = false
    },
    closeAllergensSettings () {
      this.showAllergensSettingsCard = false
      this.showAllergensTab = true
    },
    switchToAllergens () {
      this.showAllergensTab = true
      this.showProductsTab = false
    },
    switchToProducts () {
      this.showAllergensTab = false
      this.showProductsTab = true
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";

.control_panel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 700px
}
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 20px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 12px 12px;
}
.change_content_container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 40px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  //width: 700px
}
.content_row {
  padding: 12px 0px;
  margin: 0px 12px;
  display: flex;
  border-bottom: 2px solid $color-dark-50;
  justify-content: space-between
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 5px 5px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
@media (max-aspect-ratio: 6/5) {
  .control_panel {
    width: 100%;
  }
}
</style>
