<template>
<div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="content_container">
          <div style="display: flex; flex-direction: column; gap: 10px">
            <h3 class="title-h3-roboto-medium"> Настройка подписки телеграм-бота</h3>
          </div>
          <div class="info_panel">
            <div>
              <h4 class="title-h4-roboto-medium">
                Подписка действительна до<br> 31 декабря, 2024, 12:00
              </h4>
            </div>
            <button
              class="primary-green-button"
              style="width: 110px"
            >
              Сохранить
            </button>
          </div>
          <v-radio-group class="text-center" hide-details="true">
            <v-radio
              class="text-center"
              label="Автоматическая"
              :value="2"
            />
            <v-radio
              class="text-center"
              label="По предупреждению"
              :value="1"
            />
            <v-radio
              class="text-center"
              label="Отключено"
              :value="3"
            />
          </v-radio-group>
          <v-checkbox

            class="text-center"
            label="Отключить в летний период"
            :true-value="0"
            :false-value="1"
            hide-details="true"
          />
          <h4 class="body-text-roboto-medium">
            Сохранённые варианты платежей СБП
          </h4>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
</div>
</template>

<script  lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'ParentsSubscriptionSettings',
    computed: {
      ...mapGetters({ user: 'user/user' })
    },
    data () {
      return {
        isLoading: false,
        isTelegram: false,
      }
    },
    methods: {

    }
  }
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";

.content_container {
  width: 700px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.info_panel {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
<script setup lang="ts">
</script>
