<!--todo datepicker 1-->
<!--todo fix rationCard-->
<template>
  <div class="whole_page">
    <div style="display: grid; justify-content: center;">
      <div class="control_panel">
        <div style="display: flex; gap: 12px;">
          <!--      <simple-datepicker-->
          <!--        label="С"-->
          <!--        v-on:date-select='changeDateFrom'>-->
          <!--      </simple-datepicker>-->
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :offset="40"
            transition="scale-transition"
            offset-y
            max-width="390px"
          >
            <template #activator="{props}">
              <v-text-field
                :model-value="formatDate(dateFrom)"
                variant="solo"
                density="compact"
                label="C"
                append-icon="mdi-calendar"
                readonly
                style="width: 155px"
                v-bind="props"
              />
            </template>
            <v-date-picker
              hide-weekdays
              v-model="dateFrom"
              title="C"
              color="green-accent-2"
              :max="dateToMaxFrom"
              @input="updateDatepicker"
              @date-select="changeDateFrom"
            />
          </v-menu>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :offset="40"
            transition="scale-transition"
            offset-y
            max-width="390px"
          >
            <template #activator="{ props }">
              <v-text-field
                :model-value="formatDate(dateTo)"
                variant="solo"
                density="compact"
                label="По"
                append-icon="mdi-calendar"
                readonly
                style="width: 155px"
                v-bind="props"
              />
            </template>
            <v-date-picker
              hide-weekdays
              v-model="dateTo"
              title="По"
              :min="dateFrom"
              :max="dateToMaxTo"
              color="green-accent-2"
              @input="updateDatepicker"
              @date-select="changeDateTo"
            />
          </v-menu>
        </div>
        <button
          class="secondary-white-button"
          @click="getChosenPreorderList"
        >
          Показать
        </button>
      </div>
      <template v-if="!isLoading">
        <div class="wrapper">
          <div
            v-if="preorderList.length > 0"
            :style="{display: 'grid' }"
          >
            <div
              v-for="(preorder, index) in preorderList"
              :key="index"
              class="orderElem "
              @click="showPreorderItem(index)"
            >
              <h4 class="body-text-roboto-medium">
                {{ preorder.name }}
              </h4>
              <div style="text-align: end; display: flex; flex-direction: column; gap: 8px">
                <h4 class="quote-text-roboto-regular">
                  {{ preorder.date }}
                </h4>
                <h4 class="quote-text-roboto-regular">
                  {{ preorder.id }}
                </h4>
              </div>
              <v-dialog
                v-model="showOrderCard"
                width="330px"
                light:true
                :retain-focus="false"
              >
                <v-card
                  min-height="280"
                  min-width="300"
                >
                  <v-icon
                    :style="{top: '7%', left: '91%' }"
                    @click="closeRationCard"
                  >
                    mdi-close
                  </v-icon>
                  <!--                <v-card-title>-->

                  <!--                  <span class="text-h5 align-center" style="margin-right: 105px">Состав</span>-->
                  <!--                  <v-btn style="{right: 5%; top: 5%; width: 20px; margin-top: 5px; position: absolute}" @click="closeRationCard">Х</v-btn>-->
                  <!--                </v-card-title>-->
                  <div style="display: grid; justify-content: center">
                    <ration-card-new
                      :accepted-ration="currentOrder"
                      class="body-text-roboto-medium"
                    />
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div
            v-if="preorderList.length === 0"
            style="text-align: center"
          >
            В выбранный период времени нету совершенных предзаказов
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {
  rationOrdersFindByUserAndDates,
  rationPlaceSettingsFindByUser,
  userRationSettingFindByUser
} from '@/shared/api/preorders/api-requests'
import { RationOrdersFindByUserAndDatesApiRequest } from '@/shared/api/preorders/api-types'
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import moment from 'moment'
import RationCardNew from '@/components/parents/preorders/RationCardNew.vue'
import { defineComponent } from 'vue'

export type preorderListInterfaceArr = preorderListInterfaceItem[]

export type preorderListInterfaceItem = {
  id: string | null;
  week: number;
  day: number;
  rationId: number;
  productId: number;
  productIdId: number;
  date: '';
  name: '';
}
export type removedProductIdArr = removedProductIdItem[]
export type removedProductIdItem = {
  id: string | null;
  day: number;
  week: number;
  productId: number;
  productIdId: number;
  rationId: number;
}
export type RationsAndProductDataTree = RationsAndProductDataTreeItem[]
export type RationsAndProductDataTreeItem = {
  week: number;
  days: {
    day: number;
    rations: {
      id: number | null;
      rationId: number;
      accountTypeId: number | null;
      rationAspectId: number | null;
      staticPrice: number | null;
      productPrice: number | null;
      name: string;
      products: {
        name: string;
        productId: number;
        meelTypeId: number;
        price: number | null;
        id: number;
      }[];
    }[];
  }[];
}
export type RationDataType = RationDataTypeItem[]
export type RationDataTypeItem = {
  id: number | null;
  rationId: number;
  accountTypeId: number | null;
  rationAspectId: number | null;
  staticPrice: number | null;
  productPrice: number | null;
  name: string;
  productId: number | null;
  products: ProductDataType;
}
export type ProductDataType = ProductDataTypeItem[]
export type ProductDataTypeItem = {
  name: string;
  productId: number;
  meelTypeId: number;
  price: number | null;
  id: number;
}
export default defineComponent({
  name: 'PreordersListNew',
  components: { RationCardNew },
  computed: {
    ...mapGetters({ user: 'user/user' }),
    dateToMaxTo () {
      return moment(this.dateFrom).add(7, 'd').format('YYYY-MM-DD')
    },
    dateToMaxFrom () {
      let checkDate = moment()
      checkDate = checkDate.add(14, 'd').format('YYYY-MM-DD')
      return checkDate
    }
  },
  data () {
    return {
      currentUser: {} as Person,
      preorderList: [{}] as preorderListInterfaceArr,
      todayFullDate: '', // сегодняшняя дата
      defaultToDate: '', // "дата по" по умолчанию равна сегодня + 7 дней
      dateFrom: '', // ввод даты "С"
      dateTo: '', // ввод даты "По"
      todayWeek: null as null | number, // номер недели сегодняшнего дня
      todayDay: null as null | number, // день недели сегодняшнего дня
      currentWeek: null as null | number, // номер недели выбранного дня
      currentDay: null as null | number, // день недели
      startDate: '',
      startDateDay: 0,
      startDateWeek: 0,
      isLoading: false,
      dateToMax: '', // ограничение доступных для выбора дат "ПО"
      menu: false,
      menu1: false,
      dateToDay: 0, // день недели даты "ПО"
      dateFromForConvert: '',
      rationsAndProductsDataTree: [{}] as RationsAndProductDataTree, // для использования dateFrom в convertRawApi()
      currentOrder: {} as any,
      showOrderCard: false,
      removedProductIdArr: [] as removedProductIdArr
    }
  },
  async beforeMount () {
    this.isLoading = true
    this.currentUser = this.user.person
    await this.loadCurrentDateSettings()
    const currDate = moment()
    this.changeDateFrom(currDate)
    this.dateFromForConvert = this.dateFrom
    this.defaultToDate = currDate.add(7, 'd').format('YYYY-MM-DD')
    this.changeDateTo(this.defaultToDate)
    // console.log(this.todayFullDate, this.defaultToDate)
    await this.getPreordersList()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    goBacktoPreoderForTommorowPage () {
      this.$router.push('./5')
    },
    updateDatepicker (value: any) {
      this.menu = false
      this.menu1 = false
      this.$emit('date-select', value)
    },
    closeRationCard () {
      this.showOrderCard = false
    },
    /**
     * Ограничение дат ввода в выборе даты "С"
     */
    allowedDates: (val: any) => moment(val).diff('2024-02-28', 'days') <= 7 && moment('2024-02-28').diff(moment(val), 'days') <= 7,
    /**
     * Отображение карточки рациона
     * @param index
     */
    showPreorderItem (index: number) {
      this.showOrderCard = true
      const chosenOrder: preorderListInterfaceItem = this.preorderList[index]
      // console.log(chosenOrder?.week, chosenOrder?.day, chosenOrder?.rationId)
      const currentOrderWeek = this.rationsAndProductsDataTree.find((userPreorder: any) => userPreorder.week === chosenOrder?.week)
      // console.log(currentOrderWeek)
      // let currentOrder
      if (currentOrderWeek) {
        const currentOrderDay = currentOrderWeek.days.find((userPreorder: any) => userPreorder.day === chosenOrder?.day)
        if (currentOrderDay) {
          if (chosenOrder?.id !== null) {
            this.currentOrder = currentOrderDay.rations.find((userPreorderWithRationId: any) => userPreorderWithRationId.rationId === chosenOrder?.rationId || userPreorderWithRationId.productId === chosenOrder?.productId)
            // console.log(this.currentOrder)
          } else {
            const currentOrderDopPitanieBefore = currentOrderDay.rations.find((userPreorderWithRationId: any) => userPreorderWithRationId.rationId === chosenOrder?.rationId)
            // console.log(currentOrderDopPitanieBefore, 'checkin')
            const newProducts = [] as any
            // eslint-disable-next-line no-unused-expressions
            currentOrderDopPitanieBefore?.products.forEach((product: any) => {
              if (product.id === chosenOrder.productId) {
                // console.log(product.name, product.productId, 'vne massiva')
                // console.log(product)
                newProducts.push(product)
              }
              for (let i = 0; i < this.removedProductIdArr.length; i++) {
                if (chosenOrder.day === this.removedProductIdArr[i].day && chosenOrder.week === this.removedProductIdArr[i].week && chosenOrder.rationId === this.removedProductIdArr[i].rationId) {
                  if (product.id === this.removedProductIdArr[i]?.productIdId) {
                    // console.log(this.removedProductIdArr[i]?.productIdId, this.removedProductIdArr[i]?.productId)
                    // console.log(product.name, product.id, product.productId, 'vnutri massiva')
                    newProducts.push(product)
                  }
                }
              }
            })
            this.currentOrder = {
              accounntTypeId: currentOrderDopPitanieBefore?.accountTypeId,
              id: currentOrderDopPitanieBefore?.id,
              name: currentOrderDopPitanieBefore?.name,
              productId: chosenOrder?.productId,
              productPrice: currentOrderDopPitanieBefore?.productPrice,
              products: newProducts,
              rationAspectId: currentOrderDopPitanieBefore?.rationAspectId,
              rationId: currentOrderDopPitanieBefore?.rationId,
              staticPrice: currentOrderDopPitanieBefore?.staticPrice
            }
          }
        }
      }
    },
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    /**
     * метод возвращающий план заказов по умолчанию на ближайшую неделю с текущей даты
     */
    async getPreordersList () {
      try {
        const RationOrdersFindByUserAndDatesApiRequestObject: RationOrdersFindByUserAndDatesApiRequest = {
          userId: this.currentUser.id,
          dateFrom: this.todayFullDate,
          dateTo: this.defaultToDate
        }
        const res = await rationOrdersFindByUserAndDates(RationOrdersFindByUserAndDatesApiRequestObject)
        const daysDifference = (moment(this.defaultToDate).diff(moment(this.todayFullDate), 'days'))
        this.dateToDay = (daysDifference + this.todayDay) % 7
        if (this.dateToDay === 0) {
          this.dateToDay = 7
        }
        // console.log(this.dateToDay, 'this.dateToDay')
        this.getRationsAndProductsForEveryDay(res)
        this.convertRawApi(res)
      } catch {
      }
    },
    getRationsAndProductsForEveryDay (res: any) {
      try {
        const rationsAndProductsDataTree = [
          {
            week: 1,
            days: [
              {
                day: 1,
                rations: []
              },
              {
                day: 2,
                rations: []
              },
              {
                day: 3,
                rations: []
              },
              {
                day: 4,
                rations: []
              },
              {
                day: 5,
                rations: []
              },
              {
                day: 6,
                rations: []
              }
            ]
          },
          {
            week: 2,
            days: [
              {
                day: 1,
                rations: []
              },
              {
                day: 2,
                rations: []
              },
              {
                day: 3,
                rations: []
              },
              {
                day: 4,
                rations: []
              },
              {
                day: 5,
                rations: []
              },
              {
                day: 6,
                rations: []
              }
            ]
          }
        ] as RationsAndProductDataTree
        res.data.rationProducts.forEach((product: any) => {
          const existWeekIdx = rationsAndProductsDataTree.findIndex(weekitem => weekitem.week === product.weekNumber)
          if (existWeekIdx !== -1) {
            const existDayIdx = rationsAndProductsDataTree[existWeekIdx].days.findIndex(dayItem => dayItem.day === product.weekDay)
            if (existDayIdx !== -1) {
              const existRationIdx = rationsAndProductsDataTree[existWeekIdx].days[existDayIdx].rations.findIndex(ration => ration.rationId === product.rationId)
              if (existRationIdx === -1) {
                const rationItem: RationDataTypeItem = {
                  id: null,
                  rationId: product.rationId,
                  accountTypeId: null,
                  rationAspectId: null,
                  staticPrice: null,
                  productPrice: null,
                  productId: null,
                  name: '',
                  products: [
                    {
                      name: product?.product?.name,
                      productId: product?.productId,
                      meelTypeId: product?.meelTypeId,
                      price: product?.price || null,
                      id: product?.product?.id
                    }
                  ]
                }
                const rationInfo = res.data?.rationUserPreorders.find((ration: any) => ration.rationId === product.rationId)
                if (rationInfo) {
                  rationItem.id = rationInfo?.id
                  rationItem.accountTypeId = rationInfo.product?.accountTypeId
                  rationItem.rationAspectId = rationInfo.product?.rationAspectId
                  rationItem.staticPrice = rationInfo.product?.staticPrice
                  rationItem.productPrice = rationInfo?.price
                  rationItem.productId = rationInfo?.productId
                  if (rationInfo?.meelTypeId === 2) {
                    rationItem.name = 'Дополнительное питание'
                  } else { rationItem.name = rationInfo?.product?.name }
                }
                rationsAndProductsDataTree[existWeekIdx].days[existDayIdx].rations.push(rationItem)
              } else {
                const productExistIdx = rationsAndProductsDataTree[existWeekIdx].days[existDayIdx].rations[existRationIdx].products.findIndex(existProduct => existProduct.productId === product.productId)
                if (productExistIdx === -1) {
                  rationsAndProductsDataTree[existWeekIdx].days[existDayIdx].rations[existRationIdx].products.push(
                    {
                      name: product?.product?.name,
                      productId: product?.productId,
                      meelTypeId: product?.meelTypeId,
                      price: product?.price || null,
                      id: product?.product?.id
                    }
                  )
                }
              }
            }
          }
        })
        // console.log(rationsAndProductsDataTree, 'norm?')
        this.rationsAndProductsDataTree = rationsAndProductsDataTree
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список рационов!',
          timeout: 3000
        })
      }
    },
    /**
     * метод возвращающий план заказов по заданной дате "С" и "ПО"
     * @param res
     */
    convertRawApi (res: any) {
      // todo change naming
      const rawPreorderList = [] as any
      // console.log('currDate in Func', this.dateFromForConvert)
      // console.log(this.dateToDay, 'dateToDay')
      res.data.rationUserPreorders.forEach((res: any) => {
        if (this.currentDay && this.currentWeek && this.todayWeek && this.todayDay) {
          // дата начала интервала dateFrom или todayFullDate если сегодняшняя дата находится внутри интервала
          const currDate = moment(this.dateFromForConvert)
          const firstDayOfCurrentWeek = moment(this.dateFromForConvert).add(-this.currentDay + 1, 'd').format('YYYY-MM-DD')
          // проверка если выбранные даты интервала "С" и "ПО" находятся в одной календарной неделе
          if (moment(this.dateTo).diff(moment(firstDayOfCurrentWeek), 'days') <= 6) {
            // проверка если предзаказ находится в интервале С-ПО по дням и неделе
            if (res.day >= this.currentDay && res.day <= this.dateToDay && res.week === this.currentWeek) {
              const daysDifference = res.day - this.currentDay
              // для дополнительного питания
              if (res.type === 2) {
                res.product.name = 'Дополнительное питание'
                const preorderListInterfaceItem: preorderListInterfaceItem = {
                  id: null,
                  week: res.week,
                  day: res.day,
                  rationId: res.rationId,
                  productId: res.productId,
                  productIdId: res.product.id,
                  date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                  name: res.product.name
                }
                // console.log('InterfaceItem', preorderListInterfaceItem)
                rawPreorderList.push(preorderListInterfaceItem)
              } else {
                // для обычных рационов
                const preorderListInterfaceItem: preorderListInterfaceItem = {
                  id: '№' + res.id,
                  week: res.week,
                  day: res.day,
                  rationId: res.rationId,
                  productId: res.productId,
                  productIdId: res.product.id,
                  date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                  name: res.product.name
                }
                // console.log('InterfaceItem', preorderListInterfaceItem)
                rawPreorderList.push(preorderListInterfaceItem)
              }
            }
          } else {
            // для случаев когда дата "С" и "ПО" находятся в разных календарных неделях
            if (this.currentWeek === 1) {
              // рационы находящиеся на той же неделе что дата "С" и позднее по дням недели
              if (res.week === this.currentWeek && res.day >= this.currentDay) {
                // console.log('if1')
                const daysDifference = res.day - this.currentDay
                // дополнительное питание
                if (res.type === 2) {
                  res.product.name = 'Дополнительное питание'
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: null,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                } else {
                  // для обычных рационов
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: '№' + res.id,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                }
              } else if (res.week > this.currentWeek && res.day <= this.dateToDay) {
                // рационы находящиеся на следующей недели от даты "С"/ на той же недели что дата "ПО" и раньше дня недели даты "ПО"
                // console.log('if2')
                const daysDifference = 7 - this.currentDay + res.day
                if (res.type === 2) {
                  // дополнительное питание
                  res.product.name = 'Дополнительное питание'
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: null,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                } else {
                  // для обычных рационов
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: '№' + res.id,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                }
              }
            } else if (this.currentWeek === 2) {
              // рационы находящиеся на той же неделе что дата "С" и позднее по дням недели
              if (res.week === this.currentWeek && res.day >= this.currentDay) {
                // console.log('if3')
                const daysDifference = res.day - this.currentDay
                if (res.type === 2) {
                  // дополнительное питание
                  res.product.name = 'Дополнительное питание'
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: null,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                } else {
                  // для обычных рационов
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: '№' + res.id,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                }
              } else if (res.week < this.currentWeek && res.day <= this.dateToDay) {
                // рационы находящиеся на следующей недели от даты "С"/ на той же недели что дата "ПО" и раньше дня недели даты "ПО"
                // console.log('if4')
                const daysDifference = 7 - this.currentDay + res.day
                const currDate = moment(this.dateFrom)
                if (res.type === 2) {
                  // дополнительное питание
                  res.product.name = 'Дополнительное питание'
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: null,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                } else {
                  // для обычных рационов
                  const preorderListInterfaceItem: preorderListInterfaceItem = {
                    id: '№' + res.id,
                    week: res.week,
                    day: res.day,
                    rationId: res.rationId,
                    productId: res.productId,
                    productIdId: res.product.id,
                    date: currDate.add(daysDifference, 'd').format('DD.MM.YYYY'),
                    name: res.product.name
                  }
                  // console.log('InterfaceItem', preorderListInterfaceItem)
                  rawPreorderList.push(preorderListInterfaceItem)
                }
              }
            }
          }
        }
      })
      // console.log(rawPreorderList, 'rawlist')
      this.preorderList = rawPreorderList
      // сортировка массива плана заказов по дате
      this.preorderList.sort((a: any, b: any) => a.date.split('.')[0] < b.date.split('.')[0] ? 1 : -1)
        .sort((a: any, b: any) => a.date.split('.')[1] > b.date.split('.')[1] ? 1 : -1)
      const testArr = rawPreorderList
      // console.log(testArr.length, testArr)
      const removedProductArr = []
      for (let i = 0; i < testArr.length; i++) {
        for (let j = 1; j < testArr.length; j++) {
          if (testArr[j].week === testArr[i].week && testArr[j].day === testArr[i].day && testArr[i].name === testArr[j].name && i !== j) {
            const removedProductIdItem = {
              id: testArr[j].id,
              day: testArr[j].day,
              week: testArr[j].week,
              productId: testArr[j].productId,
              productIdId: testArr[j].productIdId,
              rationId: testArr[j].rationId
            }
            testArr.splice(j, 1)
            this.removedProductIdArr.push(removedProductIdItem)
            const removedItem = testArr.splice(j, 1)
            removedProductArr.push(removedItem)
          }
          if (testArr[j].week === testArr[i].week && testArr[j].day === testArr[i].day && testArr[i].name === testArr[j].name && i !== j) {
            const removedProductIdItem = {
              id: testArr[j].id,
              day: testArr[j].day,
              week: testArr[j].week,
              productId: testArr[j].productId,
              productIdId: testArr[j].productIdId,
              rationId: testArr[j].rationId
            }
            testArr.splice(j, 1)
            this.removedProductIdArr.push(removedProductIdItem)
            const removedItem = testArr.splice(j, 1)
            removedProductArr.push(removedItem)
          }
        }
      }
      // console.log(testArr, 'someARR')
      for (const subArray of removedProductArr) {
        for (const element of subArray) {
          this.removedProductIdArr.push(element)
        }
      }
      // console.log(removedProductArr, 'detect')
      // console.log(this.removedProductIdArr, 'suda')
    },
    // слушатель поля выбора даты "ПО"
    changeDateTo (value: any) {
      this.dateTo = moment(value).format('YYYY-MM-DD')
    },
    // слушатель поля выбора даты "С"
    changeDateFrom (value: any) {
      this.dateFrom = moment(value).format('YYYY-MM-DD')
      // let checkDate = moment()
      // checkDate = checkDate.add(14, 'd').format('YYYY-MM-DD')
      // if (moment(value).isSameOrBefore(checkDate)) {
      //   this.dateFrom = value
      //   const dateToMax = moment(this.dateFrom)
      //   this.dateToMax = dateToMax.add(7, 'd').format('YYYY-MM-DD')
      // } else {
      //   this.setSnackbar({
      //     showing: true,
      //     text: 'Предзаказы сществуют только на 2 недели вперед, выберите корректную дату',
      //     timeout: 3000
      //   })
      // }
    },
    /**
     * метод получения плана заказов по выбранным датам "С" и "ПО"
     */
    async getChosenPreorderList () {
      const RationOrdersFindByUserAndDatesApiRequestObject: RationOrdersFindByUserAndDatesApiRequest = {
        userId: this.currentUser.id,
        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
        dateTo: moment(this.dateTo).format('YYYY-MM-DD')
      }
      this.isLoading = true
      const res = await rationOrdersFindByUserAndDates(RationOrdersFindByUserAndDatesApiRequestObject)
      // получение дня недели для dateTo
      const daysDifferenceToDateTo = (moment(this.dateTo).diff(moment(this.todayFullDate), 'days'))
      this.dateToDay = (daysDifferenceToDateTo + this.todayDay) % 7
      if (this.dateToDay === 0) {
        this.dateToDay = 7
      }
      // если выбранный временной интервал до текущей даты, берем значения из rationOrders
      if (moment(this.dateFrom).isBefore(moment(this.todayFullDate)) && moment(this.dateTo).isBefore(moment(this.todayFullDate))) {
        const rawPreorderList = [] as any
        // console.log(rawPreorderList, 'before')
        let offsetStartDate: number
        if (this.startDateWeek === 1) {
          offsetStartDate = this.startDateDay
        } else if (this.startDateWeek >= 2 && this.startDateWeek <= 4) {
          offsetStartDate = (this.startDateWeek - 1) * 7 + this.startDateDay
        } else {
          offsetStartDate = 0
        }
        const currDate = moment(this.dateFrom)
        // this.todayFullDate = moment(currDate).format('YYYY-MM-DD')
        const startDate = moment(this.startDate)
        let days = currDate.diff(startDate, 'days')
        days = (days + offsetStartDate) % 14
        let weekNumber: number
        if (days >= 1 && days <= 7) {
          weekNumber = 1
        } else {
          weekNumber = 2
        }
        // else if (days >= 5 && days <= 21) {
        //   weekNumber = 3
        // } else {
        //   weekNumber = 4
        // }
        // console.log(days, 'days')
        const weekDay = [0, 6].includes(days % 7) ? 5 : days % 7
        res.data.rationOrders.forEach((res: any) => {
          const preorderListInterfaceItem = {
            id: res.id,
            date: moment(res.targetDate).format('DD.MM.YYYY'),
            name: res.rationOrderProducts[0].product.name,
            productId: res.rationOrderProducts[0].productId,
            day: weekDay,
            week: weekNumber
          }
          // console.log('InterfaceItem', preorderListInterfaceItem)
          rawPreorderList.push(preorderListInterfaceItem)
        })
        this.preorderList = rawPreorderList
        this.preorderList.sort((a: any, b: any) => a.date.split('.')[0] < b.date.split('.')[0] ? 1 : -1)
          .sort((a: any, b: any) => a.date.split('.')[1] > b.date.split('.')[1] ? 1 : -1)
      }
      // если выбранный временной интервал начинается до текущей даты и заканчивается текущей датой, берем значения из rationOrders и значение из rationUserPreorders
      // todo возможно косяк если на сегодня несколько рационов
      if (moment(this.dateFrom).isBefore(moment(this.todayFullDate)) && moment(this.dateTo).isSame(moment(this.todayFullDate))) {
        const rawPreorderList = [] as any
        let offsetStartDate: number
        if (this.startDateWeek === 1) {
          offsetStartDate = this.startDateDay
        } else if (this.startDateWeek >= 2 && this.startDateWeek <= 4) {
          offsetStartDate = (this.startDateWeek - 1) * 7 + this.startDateDay
        } else {
          offsetStartDate = 0
        }
        const currDate = moment(this.dateFrom)
        // this.todayFullDate = moment(currDate).format('YYYY-MM-DD')
        const startDate = moment(this.startDate)
        let days = currDate.diff(startDate, 'days')
        days = (days + offsetStartDate) % 14
        let weekNumber: number
        if (days >= 1 && days <= 7) {
          weekNumber = 1
        } else {
          weekNumber = 2
        }
        // else if (days >= 5 && days <= 21) {
        //   weekNumber = 3
        // } else {
        //   weekNumber = 4
        // }
        // console.log(days, 'days')
        const weekDay = [0, 6].includes(days % 7) ? 5 : days % 7
        res.data.rationOrders.forEach((res: any) => {
          const preorderListInterfaceItem = {
            id: res.id,
            date: moment(res.targetDate).format('DD.MM.YYYY'),
            name: res.rationOrderProducts[0].product.name,
            productId: res.rationOrderProducts[0].productId,
            day: weekDay,
            week: weekNumber
          }
          // console.log('InterfaceItem', preorderListInterfaceItem)
          rawPreorderList.push(preorderListInterfaceItem)
        })
        const existedInResIdx = res.data.rationUserPreorders.findIndex((rationItem: any) => rationItem.day === this.todayDay && rationItem.week === this.todayWeek)
        if (existedInResIdx !== -1) {
          const preorderListInterfaceItem: preorderListInterfaceItem = {
            id: res.data.rationUserPreorders[existedInResIdx].id,
            week: res.data.rationUserPreorders[existedInResIdx].week,
            day: res.data.rationUserPreorders[existedInResIdx].day,
            rationId: res.data.rationUserPreorders[existedInResIdx].rationId,
            productId: res.data.rationUserPreorders[existedInResIdx].productId,
            productIdId: res.data.rationUserPreorders[existedInResIdx].product.id,
            date: moment(this.todayFullDate).format('DD.MM.YYYY'),
            name: res.data.rationUserPreorders[existedInResIdx].product.name
          }
          // console.log('InterfaceItem', preorderListInterfaceItem)
          rawPreorderList.push(preorderListInterfaceItem)
        }
        this.preorderList = rawPreorderList
        this.preorderList.sort((a: any, b: any) => a.date.split('.')[0] < b.date.split('.')[0] ? 1 : -1)
          .sort((a: any, b: any) => a.date.split('.')[1] > b.date.split('.')[1] ? 1 : -1)
      }
      // если выбранный временной интервал от текущей даты, (то же самое что и по умолчанию) поэтому устанавливаем значения текущего дня и недели от сегодняшнего дня
      if (moment(this.dateFrom).isSame(moment(this.todayFullDate)) && moment(this.dateTo).isAfter(moment(this.todayFullDate))) {
        this.currentDay = this.todayDay
        this.dateFromForConvert = this.dateFrom
        this.currentWeek = this.todayWeek
        this.convertRawApi(res)
      }
      // если сегодняшняя дата находится внутри выбранного интервала, берем значения из rationOrders по dateFrom и значения из rationUserPreorders от todayFullDate по dateTo
      if (moment(this.dateFrom).isBefore(moment(this.todayFullDate)) && moment(this.dateTo).isAfter(moment(this.todayFullDate))) {
        const rawPreorderList = [] as any
        res.data.rationOrders.forEach((res: any) => {
          const preorderListInterfaceItem = {
            id: res.id,
            date: moment(res.targetDate).format('DD.MM.YYYY'),
            name: res.rationOrderProducts[0].product.name
          }
          // console.log('InterfaceItem', preorderListInterfaceItem)
          rawPreorderList.push(preorderListInterfaceItem)
        })
        console.log(rawPreorderList, 'ordersBefore')
        this.dateFromForConvert = this.todayFullDate
        this.currentDay = this.todayDay
        this.currentWeek = this.todayWeek
        this.convertRawApi(res)
        const rawPreorderListANDpreorderListArr = this.preorderList.concat(rawPreorderList)
        // console.log('1', rawPreorderListANDpreorderListArr)
        this.preorderList = rawPreorderListANDpreorderListArr
        this.preorderList.sort((a: any, b: any) => a.date.split('.')[0] < b.date.split('.')[0] ? 1 : -1)
          .sort((a: any, b: any) => a.date.split('.')[1] > b.date.split('.')[1] ? 1 : -1)
        // console.log('2', this.preorderList)
      }
      // если дата начала и конца интервала позже todayFullDate, изменяем значения текущей неддели и дня
      if (moment(this.dateFrom).isAfter(moment(this.todayFullDate)) && moment(this.dateTo).isAfter(moment(this.todayFullDate))) {
        // const rawPreorderList = [] as any
        const daysDifference = (moment(this.dateFrom).diff(moment(this.todayFullDate), 'days'))
        console.log('daysDifference', daysDifference)
        if (daysDifference !== 14) {
          if ((this.todayDay + daysDifference) > 7) {
            if (this.todayWeek === 1) {
              this.currentWeek = 2
              this.currentDay = (this.todayDay + daysDifference) % 7
            }
            if (this.todayWeek === 2) {
              this.currentWeek = 1
              this.currentDay = (this.todayDay + daysDifference) % 7
            }
          } else {
            this.currentDay = this.todayDay + daysDifference
          }
        }
        // console.log(this.currentWeek, this.currentDay, 'checking')
        this.dateFromForConvert = this.dateFrom
        this.convertRawApi(res)
      }
      this.isLoading = false
      // console.log('rationOrdersFindByUserAndDatesApiRes', res.data.rationUserPreorders)
      // console.log(this.dateFrom, this.dateTo)
    },
    async loadCurrentDateSettings () {
      try {
        const rationPlaceSettingsFindByUserReqObj = { userId: this.currentUser?.id }
        const rationPlaceSettingsFindByUserRes = await rationPlaceSettingsFindByUser(rationPlaceSettingsFindByUserReqObj)
        const userRationSettingFindByUserReqObj = {
          userId: this.currentUser?.id,
          rationSettingTypeId: 2
        }
        const userRationSettingFindByUserRes = await userRationSettingFindByUser(userRationSettingFindByUserReqObj)
        // if (userRationSettingFindByUserRes.data?.length > 0 && userRationSettingFindByUserRes.data?.[0]?.status === 1 && userRationSettingFindByUserRes.data?.[0]?.value > 0) {
        //   this.daysDisabledSetting = userRationSettingFindByUserRes.data?.[0]?.value
        // }
        const preorderDateSettings = rationPlaceSettingsFindByUserRes.data
        if (preorderDateSettings) {
          if (preorderDateSettings.date) {
            let offsetStartDate: number
            this.startDate = preorderDateSettings.date
            this.startDateDay = preorderDateSettings.weekDay
            this.startDateWeek = preorderDateSettings.weekValue
            if (preorderDateSettings.weekValue === 1) {
              offsetStartDate = preorderDateSettings.weekDay
            } else if (preorderDateSettings.weekValue >= 2 && preorderDateSettings.weekValue <= 4) {
              offsetStartDate = (preorderDateSettings.weekValue - 1) * 7 + preorderDateSettings.weekDay
            } else {
              offsetStartDate = 0
            }
            const currDate = moment()
            this.todayFullDate = moment(currDate).format('YYYY-MM-DD')
            const startDate = moment(this.startDate)
            let days = currDate.diff(startDate, 'days')
            days = (days + offsetStartDate) % 14
            let weekNumber: number
            if (days >= 1 && days <= 7) {
              weekNumber = 1
            } else {
              weekNumber = 2
            }
            // else if (days >= 5 && days <= 21) {
            //   weekNumber = 3
            // } else {
            //   weekNumber = 4
            // }
            // console.log(days, 'days')
            const weekDay = [0, 6].includes(days % 7) ? 5 : days % 7
            // console.log(weekNumber, weekDay, 'неделя, день')
            this.currentWeek = weekNumber
            this.currentDay = weekDay
            this.todayWeek = weekNumber
            this.todayDay = weekDay
          }
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить настройки пользователя!',
          timeout: 3000
        })
      }
    }
  }
})

</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
  padding: 20px 16px;
}
.orderElem{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  //text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
  //padding: 20px;
  padding-left: 100px;
  padding-right: 100px;

  box-sizing: border-box;
  padding: 10px 16px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid #EBEAEC;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.control_panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 12px;
  gap: 24px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  height: 100%;
  border-radius: 12px;
}
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}

@media (max-aspect-ratio: 6/5) {
  .orderElem {
    padding-left: 17.5px;
    padding-right: 17.5px;
    //padding: 10px;
  }
  .wrapper {
    margin: 0px;
    margin-top: 23px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  }
  //.goBackButton {
  //  font-size: xx-small;
  //  margin: 0px;
  //  width: 120px;
  //}
}
</style>
