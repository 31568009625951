<template>
  <div class="whole_page">
    <div class="page_container">
      <div class="content-container">
        <template v-if="!isLoading">
          <div class="control_panel">
            <h1 class="title-h1-roboto-medium">
              Настройка автоперевода
            </h1>
            <div>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
                <h4
                  class="body-text-roboto-medium"
                  style="width: 253px"
                >
                  Перенос с баланса "Горячее питание" на баланс "Буфет"
                </h4>
                <div style="align-self: center">
                  <label class="toggle-switch">
                    <input
                      v-model="autoTransferSetting"
                      type="checkbox"
                    >
                    <div class="slider round" />
                  </label>
                </div>
              </v-row>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec;  justify-content: space-between">
                <h4
                  class="body-text-roboto-medium"
                  style="width: 255px"
                >
                  Пополнять баланс "Буфет" до суммы
                </h4>
                <v-text-field
                  v-model="recipientSum"
                  :rules="autoTransferRules"
                  variant="solo"
                  density="compact"
                  style="max-width: 66px; max-height: 43px; border-radius: 7px;"
                />
              </v-row>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec;  justify-content: space-between">
                <h4
                  class="body-text-roboto-medium"
                  style="width: 255px"
                >
                  Пороговый обязательный баланс "Горячее питание"
                </h4>
                <v-text-field
                  v-model="emitterSum"
                  :rules="autoTransferRules"
                  variant="solo"
                  density="compact"
                  style="max-width: 66px; max-height: 43px; border-radius: 7px;"
                />
              </v-row>
              <v-row style="justify-content: space-between; padding: 16px 12px; text-align: center">
                <h4
                  class="small-text-roboto-medium"
                  style="text-decoration: underline"
                >
                  Вы согласны с условиями перевода
                </h4>
                <div style="align-self: center">
                  <label class="toggle-switch">
                    <input
                      v-model="conditionsAgreement"
                      type="checkbox"
                      :disabled="conditionsAgreement"
                    >
                    <div class="slider round" />
                  </label>
                </div>
              </v-row>
            </div>
            <div style="display: grid">
              <button
                class="primary-green-button"
                style="align-self: end; margin-top: 12px"
                @click="saveAutoTransferSetting"
              >
                Сохранить
              </button>
            </div>
          </div>
          <div class="notification_container">
            <div
              class="attention_notification"
              style=" border-radius: 12px"
            >
              <div class="attention-notification-text-box">
                <h4 class="body-text-roboto-medium">
                  Услуга "автоперевод" предоставляется бесплатно.
                </h4>
              </div>
            </div>
            <div
              class="attention_notification"
              style=" border-radius: 12px"
            >
              <div class="attention-notification-text-box">
                <h4 class="body-text-roboto-medium">
                  Услуга "автоперевод" доступна один раз в сутки
                </h4>
              </div>
            </div>
          </div>
          <div v-if="!showExamplesCard">
            <h4
              class="body-text-roboto-medium pointed"
              style="text-decoration: underline; color: dimgrey;  margin-left: 43%"
              @click="showExamples"
            >
              Примеры
            </h4>
          </div>
          <div
            v-if="showExamplesCard"
            class="examples_panel"
          >
            <button
              style="align-self: end; width: 30px; position: relative; padding: 4px"
              @click="showExamplesCard = false"
            >
              <img
                src="@/assets/images/close.png"
                height="22"
                width="22"
              >
            </button>
            <div
              class="examples_card"
            >
              <h4
                class="body-text-roboto-medium"
                style="align-self: center; margin-bottom: 10px"
              >
                Пример 1
              </h4>
              <table class="small-text-roboto-medium custom-table">
                <thead>
                  <tr style="background: #ECF2F6">
                    <td>Время</td>
                    <td>Действие</td>
                    <td>Сумма</td>
                    <td>Остаток "Горячее питание"</td>
                    <td>Остаток "Буфет"</td>
                  </tr>
                </thead>
                <tr>
                  <td>13:00</td>
                  <td>Остаток</td>
                  <td />
                  <td>500</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>13:30</td>
                  <td>
                    Пополнять баланс "Буфет" до суммы: 100
                    Пороговый обязательный баланс "Горячее питание": 100
                  </td>
                  <td />
                  <td>500</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>14:00</td>
                  <td>Нажатие кнопки "Выполнить автоперевод вручную".</td>
                  <td>100</td>
                  <td>400</td>
                  <td>100</td>
                </tr>
              </table>
            </div>
            <div
              class="examples_card"
              style="margin-top: 24px"
            >
              <h4
                class="body-text-roboto-medium"
                style="align-self: center; margin-bottom: 10px"
              >
                Пример 2
              </h4>
              <table class="small-text-roboto-medium custom-table">
                <thead>
                  <tr style="background: #ECF2F6">
                    <td>Время</td>
                    <td style="width: 152px">
                      Действие
                    </td>
                    <td>Сумма</td>
                    <td>Остаток "Горячее питание"</td>
                    <td>Остаток "Буфет"</td>
                  </tr>
                </thead>
                <tr>
                  <td>13:00</td>
                  <td style="width: 152px">
                    Остаток
                  </td>
                  <td />
                  <td>500</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>13:30</td>
                  <td style="width: 152px">
                    Пополнять баланс "Буфет" до суммы: 100
                    Пороговый обязательный баланс "Горячее питание": 100
                  </td>
                  <td />
                  <td>500</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>14:00</td>
                  <td style="width: 152px">
                    Нажатие кнопки "Выполнить автоперевод вручную".
                  </td>
                  <td>100</td>
                  <td>400</td>
                  <td>100</td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { accountSettingsFind, accountSettingsSave } from '@/shared/api/parents/moneyAccounting/api-requests'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsAutoTransfer',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      showExamplesCard: false,
      autoTransferSetting: false as boolean,
      emitterSum: 0,
      recipientSum: 0,
      isTelegram: false,
      isLoading: false,
      conditionsAgreement: false,
      autoTransferRules: [
        (value: any) => {
          if (value || value === undefined) return true
          return 'Пустое поле.'
        },
        // (value: any) => {
        //   if (value?.length <= 4 || value === undefined) return true
        //   return 'Максимум 4 цифры.'
        // },
        (value: any) => {
          if (!isNaN(value) || value === undefined) return true
          return 'Должно быть число.'
        },
        // (value: any) => {
        //   if (value <= this.maxInputValue || value === undefined) return true
        //   return 'Максимальная сумма оплаты 2000 руб.'
        // },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) || value === undefined) return true
          return 'Введите целое число'
        },
        (value: any) => {
          if (parseFloat(value) >= 0 || value === undefined) return true
          return 'Введите натуральное число'
        }
      ],
    }
  },
  async beforeMount () {
    this.isLoading = true
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.getAutoTransferSetting()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getAutoTransferSetting () {
      try {
        const getAutoTransferSettingReqObj = {
          userId: this.currentUser.id
        }
        const getAutoTransferSettingRes = await accountSettingsFind(getAutoTransferSettingReqObj, this.isTelegram)
        if (getAutoTransferSettingRes.data[0].status === 1) {
          this.autoTransferSetting = true
          this.recipientSum = getAutoTransferSettingRes.data[0].minRecipientAccountSum
          this.emitterSum = getAutoTransferSettingRes.data[0].minEmmiterAccountSum
        } else {
          this.autoTransferSetting = false
        }
        console.log(getAutoTransferSettingRes.data, 'get')
        console.log(this.autoTransferSetting, 'em')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить настройку разрешения автоперевода между счетами!',
          timeout: 3000
        })
      }
    },
    async saveAutoTransferSetting () {
      try {
        this.isLoading = true
        const saveAutoTransferSettingReqObj = {
          userId: this.currentUser.id,
          minEmmiterAccountSum: this.emitterSum,
          minRecipientAccountSum: this.recipientSum,
          status: this.autoTransferSetting ? 1 : 2
        }
        const saveAutoTransferSettingRes = await accountSettingsSave(saveAutoTransferSettingReqObj, this.isTelegram)
        await this.getAutoTransferSetting()
        this.isLoading = false
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось сохранить настройку разрешения автоперевода между счетами!',
          timeout: 3000
        })
      }
    },
    goToMoneyAccounting () {
      this.$router.push('./moneyAccounting')
    },
    showExamples () {
      this.showExamplesCard = true
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";

.control_panel {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  //margin: 0px 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.examples_panel {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.examples_card {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 7px 7px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
    vertical-align: top;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
.notification_container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.content-container {
  width: 732px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-aspect-ratio: 6/5) {
 .notification_container {
   display: flex;
   flex-direction: column;
 }
  .content-container {
    width: 100%
  }
}
</style>
