<template>
  <div>
    <v-toolbar style="background: linear-gradient(180deg, rgba(231, 219, 231, 0.5) 0%, rgba(210, 224, 240, 0.5) 100%); height: 50px;">
      <v-toolbar-title>
        <v-menu
          v-model="menuNavbar"
          offset-y
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              v-bind="props"
              @click="menuNavbar = true"
            >
              <div style="display: flex; flex-direction: row">
                <h3
                  class="title-h4-roboto-medium"
                  style="align-self: center; padding-bottom: 6px"
                >
                  Меню
                </h3>
                <div style="vertical-align: center; justify-self: center; align-self: center; padding: 2px">
                  <img
                    src="@/assets/images/menu.png"
                    height="20"
                    width="20"
                    style="margin-left: 5px;"
                  >
                </div>
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title @click="goToNews">
                Новости
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-menu
          v-model="accountMenu"
          offset-y
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              v-bind="props"
              @click="getAuthUsersNav"
            >
              <h4>{{ user.person.firstName }} {{ user.person.lastName.charAt(0) }}.</h4>
              <div style="background: #FFFFFF; border-radius: 50%; justify-content: center; margin-left: 6px; height: 26px; width: 26px; padding: 4px">
                <img
                  src="@/assets/images/group.png"
                  height="18"
                  width="18"
                  style="align-self: center "
                >
              </div>
              <img
                src="@/assets/images/arrow_dropdown.png"
                height="20"
                width="20"
                style="margin-left: 3px;"
              >
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(account, index) in authUsers"
              :key="index"
              @click="changeAccount(account)"
            >
              <v-list-item-title>
                {{ account.user.firstName }} {{ account.user.lastName.charAt(0) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-toolbar style="background: linear-gradient(180deg, rgba(231, 219, 231, 0.5) 10%, rgba(210, 224, 240, 0.6) 100%); height: 50px; padding: 0px 9px 0px 0px; justify-content: space-between">
      <v-toolbar-title style="width: fit-content">
        <h4
          v-if="$route.fullPath === '/parents/home'"
          class="button-text-roboto-medium"
          style="padding: 4px"
        >
          Личный кабинет родителя
        </h4>
        <button
          v-if="$route.fullPath === '/parents/myChildren'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Мои дети
        </button>
        <button
          v-if="$route.fullPath === '/parents/moneyAccounting'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Учет денег
        </button>
        <button
          v-if="$route.fullPath === '/parents/settingLimits'"
          class="goBackButton"
          @click="goToMoneyAccounting"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Установка ограничений
        </button>
        <button
          v-if="$route.fullPath === '/parents/autoTransfer'"
          class="goBackButton"
          @click="goToMoneyAccounting"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Автоперевод
        </button>
        <button
          v-if="$route.fullPath === '/parents/transferBetweenChildren'"
          class="goBackButton"
          @click="goToMoneyAccounting"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Перевод между детьми
        </button>
        <button
          v-if="$route.fullPath === '/parents/transferBetweenAccounts'"
          class="goBackButton"
          @click="goToMoneyAccounting"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Перевод между счетами
        </button>
        <button
          v-if="$route.fullPath === '/parents/schoolAttendance'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Проход в школу
        </button>
        <button
          v-if="$route.fullPath === '/parents/notAllowed'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Ребенку нельзя
        </button>
        <button
          v-if="$route.fullPath === '/parents/attendanceReport'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Табель занятий
        </button>
        <button
          v-if="$route.fullPath === '/parents/digitalReceipts'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Электронные квитанции
        </button>
        <button
          v-if="$route.fullPath === '/parents/info'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Информация
        </button>
        <button
          v-if="$route.fullPath === '/parents/settings'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Настройки
        </button>
        <button
          v-if="$route.fullPath === '/parents/singlePayment'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Единый платеж
        </button>
        <button
          v-if="$route.fullPath === '/parents/singlePayments'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Единый платеж
        </button>
        <button
          v-if="$route.fullPath === '/parents/news'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Новости
        </button>
        <button
          v-if="$route.fullPath === '/parents/preordersNew/5'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Предзаказ на завтра
        </button>
        <button
          v-if="$route.fullPath === '/parents/preordersNew/6'"
          class="goBackButton"
          @click="goToPreorders"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / План заказов
        </button>
        <button
          v-if="$route.fullPath === '/parents/preordersNew/7'"
          class="goBackButton"
          @click="goToPreorders"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Настройка рационов
        </button>
        <button
          v-if="$route.path.startsWith('/parents/transaction/')"
          class="goBackButton"
          @click="goToMoneyAccounting"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Выписка
        </button>
        <button
          v-if="$route.fullPath === '/parents/subscription'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Подписка
        </button>
        <button
          v-if="$route.fullPath === '/parents/subscription/singlepayment'"
          class="goBackButton"
          @click="goToHome"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Единый платеж
        </button>
        <button
          v-if="$route.fullPath === '/parents/subscription/autopayment'"
          class="goBackButton"
          @click="goToSubscription"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Настройка платежа
        </button>
        <button
          v-if="$route.fullPath === '/parents/subscription/settings'"
          class="goBackButton"
          @click="goToSubscription"
        >
          <img
            src="~@/assets/images/undo.png"
            height="20"
            width="20"
          >Назад / Настройка подписки
        </button>
      </v-toolbar-title>
      <v-toolbar-items>
        <div style=" vertical-align: center; align-self: center; padding: 0px 16px">
          <h6 style="vertical-align: center">
            v.{{ version }}
          </h6>
        </div>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TelegramAuthUsersApiRequest, TelegramAuthUsersItem } from '@/shared/api/preorders/api-types'
import { telegramAuthUsers } from '@/shared/api/preorders/api-requests'
import { CLEAR_USER, SET_USER } from '@/store/user/mutations'
import { UserShort } from '@/store/user/types'
import { store } from '@/app/providers'

export default defineComponent({
  name: 'NavbarParents',
  data () {
    return {
      appTitle: 'Глолайм',
      sidebar: false,
      menuItems: [
        { title: 'Личный кабиет родителя', path: '/', icon: 'home' },
        { title: 'Возврат', path: '/oldsite/', icon: 'gavel' }
      ],
      authUsers: null as null | TelegramAuthUsersItem[],
      chatId: '',
      tgToken: '',
      version: process.env.VUE_APP_VERSION,
      accountMenu: false,
      menuNavbar: false as boolean
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapMutations('user', { setUser: SET_USER }),
    ...mapMutations('user', { clearUser: CLEAR_USER }),
    async getAuthUsersNav () {
      console.log('getAuthUsers')
      try {
        const chatId = localStorage.getItem('chatId')
        const tgToken = localStorage.getItem('tgToken')
        if (chatId !== null && tgToken !== null) {
          this.chatId = chatId
          this.tgToken = tgToken
        }
        const telegramAuthUsersReqObj: TelegramAuthUsersApiRequest = {
          chatId: this.chatId,
          token: this.tgToken
        }
        const res = await telegramAuthUsers(telegramAuthUsersReqObj)
        if (res.data?.length > 0) {
          this.authUsers = res.data
        }
        console.log('telegramAuthUsers', res)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список авторизованных пользователей!',
          timeout: 3000
        })
      } finally {
      }
    },
    async changeAccount (account: TelegramAuthUsersItem) {
      try {
        console.log('authUser')
        console.log(account.placeSettings?.findIndex(value => value.placeSettingTypeId === 32 && value.value === 'on'))
        if (account.placeSettings !== null) {
          const existParentIdx = account.placeSettings.findIndex(value => value.placeSettingTypeId === 32 && value.value === 'on')
          if (existParentIdx !== -1) {
            console.log('zahodi')
            const userStoreObj: UserShort = {
              id: account.user.id,
              // eslint-disable-next-line
              tokens: { access_token: account.accessToken, refresh_token: '' },
              username: account.user.firstName
            }
            this.setUser(userStoreObj)
            localStorage.setItem('accessToken', account.accessToken)
            localStorage.setItem('chatId', this.chatId)
            localStorage.setItem('tgToken', this.tgToken)
            await store.dispatch('user/getPerson')
            const currentRoute = this.$route.fullPath
            await this.$router.push(currentRoute).then(() => {
              this.$router.go(0)
            }).catch((err) => {
              if (err.name !== 'NavigationDuplicated') {
                throw err
              }
            })
          } else {
            this.setSnackbar({
              showing: true,
              text: 'Доступ временно ограничен',
              timeout: 3000
            })
          }
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось преключиться на другую учетную запись!',
          timeout: 3000
        })
      }
    },
    goToHome () {
      this.$router.push({ name: 'ParentsHome' })
    },
    goToNews () {
      this.$router.push({ name: 'ParentsNews' })
    },
    goToMoneyAccounting () {
      this.$router.push({ name: 'ParentsMoneyAccounting' })
    },
    goToSubscription () {
      this.$router.push({name: 'ParentsSubscription'})
    },
    goToPreorders () {
      this.$router.push('./5')
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    routePartialType (): string {
      return (this.$route.meta as any).partialType
    }
  },
  components: {
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.goBackButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  //width: 87px;
  height: 28px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #332C45;
}
</style>
