<template>
  <div class="nfc-page">
    <h1>Чтение NFC метки</h1>
    <img src="@/views/nfcTest/demo-link.png" width="200" height="200" style="align-self: center"/>
    <button class="secondary-white-button" style="margin: 20px 0px" @click="readNFC" :disabled="!isNFCSupported">Считать метку NFC</button>
    <p v-if="nfcData">Данные с метки: {{ nfcData }}</p>
    <p v-else-if="errorMessage !== ''" class="error">{{ errorMessage }}</p>
  </div>
</template>
<script  lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TelegramNFCTry',

  data () {
    return {
      isNFCSupported: false,
      nfcData: '',
      errorMessage: ''
    }
  },
    beforeCreate () {
      const script = document.createElement('script')
      script.src = 'https://telegram.org/js/telegram-web-app.js'
      script.defer = true
      document.head.appendChild(script)
    },
  beforeMount () {
    if (window.Telegram !== undefined) {
      const WebApp = window.Telegram.WebApp
      console.log(WebApp)
      if (WebApp.initDataUnsafe !== undefined) {
        console.log(WebApp.initDataUnsafe)
        }
      }
  },
    mounted() {
      // Проверка поддержки Web NFC
      this.isNFCSupported = 'NDEFReader' in window;
      if (!this.isNFCSupported) {
        this.errorMessage = 'Ваше устройство не поддерживает Web NFC';
      }
    },
  methods: {
    async readNFC() {
      try {
        if ('NDEFReader' in window) {
          const ndef = new NDEFReader()
          await ndef.scan()

          ndef.onreading = (event: any) => {
            const { message } = event
            for (const record of message.records) {
              console.log(record, 'record')
              if (record.recordType === 'text') {
                const decoder = new TextDecoder(record.encoding)
                this.nfcData = decoder.decode(record.data)
              } else if (record.recordType === 'url' || record.recordType === 'absolute-url') {
                const decoder = new TextDecoder()
                this.nfcData = decoder.decode((record.data))
              } else {
                this.nfcData = 'Неподдерживаемый тип данных'
              }
            }
          };

          ndef.onreadingerror = () => {
            this.errorMessage = 'Ошибка при чтении метки NFC'
          }
        }
      } catch (error) {
        this.errorMessage = 'Ошибка:' + `${error}`
      }
    }
  }
}
)
</script>


<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.nfc-page {
  text-align: center;
  margin-top: 50px;
  display: grid;
  justify-content: center;
}

.error {
  color: red;
  font-weight: bold;
}
</style>
