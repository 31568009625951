<template>
  <div class="whole_page">
    <div class="page_container">
      <div style="display: flex; flex-direction: column; gap: 20px">
        <div class="content_container">
          <h2 class="title-h2-roboto-medium">
            Единый платеж
          </h2>
          <v-row>
            <v-col
              cols="8"
              style="padding-bottom: 0px"
            >
              <h4
                class="body-text-roboto-medium"
                style="vertical-align: center"
              >
                Счет
              </h4>
            </v-col>
            <v-col style="padding-bottom: 0px; text-align: center">
              <h4 class="body-text-roboto-medium">
                Остаток на счете
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              style="display: flex; flex-direction: row; gap: 5px; padding: 0px 12px"
              cols="8"
            >
              <h4
                class="body-text-roboto-medium"
                style="width: 90px; padding: 12px 0px"
              >
                Горячее питание
              </h4>
              <v-text-field
                v-model="mainFood"
                variant="solo"
                clearable
                :rules="paymentRules"
                class="telegram-input"
              />
            </v-col>
            <v-col style="padding:  12px; text-align: center; vertical-align: center">
              <h4 class="body-text-roboto-medium">
                {{ accountsSum[0] }}
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              style="display: flex; flex-direction: row; gap: 5px; padding: 0px 12px"
              cols="8"
            >
              <h4
                class="body-text-roboto-medium"
                style="width: 90px; padding: 12px 0px"
              >
                Буфет
              </h4>
              <v-text-field
                v-model="buffet"
                variant="solo"
                clearable
                :rules="paymentRules"
                class="telegram-input"
              />
            </v-col>
            <v-col style="padding:  12px; text-align: center; vertical-align: center">
              <h4 class="body-text-roboto-medium">
                {{ accountsSum[1] }}
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              style="display: flex; flex-direction: row; gap: 5px; padding: 0px 12px"
              cols="8"
            >
              <h4
                class="body-text-roboto-medium"
                style="width: 90px; padding: 12px 0px"
              >
                Итог
              </h4>
              <v-text-field
                v-model="totalAmount"
                variant="solo"
                readonly
                hide-details="true"
                class="telegram-input"
              />
            </v-col>
          </v-row>
          <button
            class="primary-green-button"
            style="margin-top: 12px"
          >
            Оплатить
          </button>
        </div>
        <div
          class="attention_notification"
          style=" border-radius: 12px;"
        >
          <div class="attention-notification-text-box">
            <h4 class="body-text-roboto-medium">
              Вы можете пополнить счета всех своих детей единым платежом при приобретении подписки
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { moneyAccountsFind } from '@/shared/api/parents/moneyAccounting/api-requests'
import { AccountsFindItem } from '@/shared/api/parents/moneyAccounting/api-types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentSinglePayment',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      showSettings: false,
      currentUser: {} as Person,
      accountsSum: [] as number[],
      mainFood: null,
      buffet: null,
      totalAmount: 0,
      isTelegram: false,
      paymentRules: [
        (value: any) => {
          if (value || value === undefined) return true
          return 'Пустое поле.'
        },
        // (value: any) => {
        //   if (value?.length <= 4 || value === undefined) return true
        //   return 'Максимум 4 цифры.'
        // },
        (value: any) => {
          if (!isNaN(value) || value === undefined) return true
          return 'Должно быть число.'
        },
        // (value: any) => {
        //   if (value <= this.maxInputValue || value === undefined) return true
        //   return 'Максимальная сумма оплаты 2000 руб.'
        // },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) || value === undefined) return true
          return 'Введите целое число'
        },
        (value: any) => {
          if (parseFloat(value) >= 0 || value === undefined) return true
          return 'Введите натуральное число'
        }
      ],
    }
  },
  async beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.findAccountsBalance()
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async findAccountsBalance () {
      try {
        const findAccountsBalanceReqObj = {
          userId: this.currentUser.id,
          accountTypeId: null,
          accountTypes: [1, 2]
        }
        const findAccountsBalanceRes = await moneyAccountsFind(findAccountsBalanceReqObj, this.isTelegram)
        findAccountsBalanceRes.data.forEach((account: AccountsFindItem) => {
          this.accountsSum.push(account.sum)
        })
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить баланс счетов!',
          timeout: 3000
        })
      }
    },
    // calculateSum () {
    //   this.totalAmount = this.buffet + this.mainFood
    // },
    goToHome () {
      this.$router.push('./home')
    },
    showSetting () {
      this.showSettings = true
    },
    closeSetting () {
      this.showSettings = false
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 700px;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
