<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Электронные талоны</strong>
        </h1>
        <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
            <!--            <place-selector-->
            <!--              v-model='selectedUserAllowedPlace'-->
            <!--              :selectedItemProps="propPlaceId">-->
            <!--            </place-selector>-->
            <v-select
              v-model="selectedPlaceItemId"
              :items="placeItemsFiltered"
              item-title="name"
              item-value="id"
              label="Школа"
              :click="update()"
              :loading="isPlaceLoading"
              :disabled="isPlaceDisabled"
            >
              <template #prepend-item>
                <v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="placeSearchTerm"
                      :placeholder="'Поиск'"
                      @update:model-value="searchItem()"
                    />
                  </v-list-item>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
            </v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="3"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              min-width="290px"
            >
              <template #activator="{ props }">
                <v-text-field
                  :model-value="formatDate(date)"
                  :label="'Дата'"
                  prepend-icon="mdi-calendar"
                  v-bind="props"
                />
              </template>
              <v-date-picker
                v-model="date"
                title="Дата"
                hide-weekdays
                @input="updateDate"
              />
            </v-menu>
            <!--            <simple-datepicker-->
            <!--              label="Дата"-->
            <!--              v-model="date"-->
            <!--            >-->
            <!--            </simple-datepicker>-->
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3"
          >
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Показать"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Подождите..."
              @submit-clicked="handleSubmit"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Сформировать"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Подождите..."
              @submit-clicked="handleSubmitCreate"
            />
          </v-col>
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Учесть"
              :is-loading="isLoading"
              :disabled="false"
              loading-text="Подождите..."
              @submit-clicked="handleSubmitRecord"
            />
          </v-col>
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Итоги"
              :is-loading="isLoading"
              :disabled="false"
              loading-text="Подождите..."
              @submit-clicked="itog"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Проверить"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Подождите..."
              @submit-clicked="handleSubmitValidation"
            />
          </v-col>
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Подписать"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Подождите..."
              @submit-clicked="handleSubmitSign"
            />
          </v-col>
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Настройка"
              :is-loading="isLoading"
              :disabled="!valid"
              loading-text="Подождите..."
              @submit-clicked="handleSubmitOptions"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="forTeacherSign"
              label="Подписывать за классного руководителя"
            />
          </v-col>
        </v-row>

<!--        Модальное окно по настрйокам-->
        <v-dialog
          v-model="isOptionsOpen"
          persistent
          width="700"
        >
          <v-card>
            <v-card-text>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center'}"
              >
                <span class="text-h5 align-center">Настройка отображения</span>
              </div>
            </v-card-text>
            <template v-if="!isOptionsLoading">
              <v-card-text>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
                >
                  <div class="talon-register-options__table text-center">
                    <div class="talon-register-options__table-header">
                      Настройка отображения рационов в таблице
                      талонов
                    </div>
                    <div class="talon-register-options__header-cell">
                      <p :style="{margin: 0}">
                        Значение
                      </p>
                    </div>
                    <div class="talon-register-options__header-cell">
                      <p :style="{margin: 0}">
                        Наименование
                      </p>
                    </div>
                    <template
                      v-for="(option) in options"
                      :key="`value-${option.productId}`"
                    >
                      <div
                        class="talon-register-options__cell"
                      >
                        <p :style="{margin: 0}">
                          <input
                            :checked="option.value === 1"
                            type="checkbox"
                            @change="handleOptionValueChange(option)"
                          >
                        </p>
                      </div>
                      <div
                        class="talon-register-options__cell"
                      >
                        <p :style="{margin: 0}">
                          {{ option.name }}
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
                >
                  <v-btn
                    color="lighten-2"
                    :disabled="isOptionsLoading"
                    @click="closeOptionModal"
                  >
                    Закрыть
                  </v-btn>
                </div>
              </v-card-actions>
            </template>
            <template v-else>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}"
              >
                <v-col class="text-center">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="green"
                    indeterminate
                  />
                </v-col>
              </div>
            </template>
          </v-card>
        </v-dialog>

        <!--Модальное окно по итогам-->
        <v-dialog
          v-model="isTalonErrorsOpen"
          width="900"
        >
          <v-card>
            <v-card-text>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center'}"
              >
                <span class="text-h5 align-center">{{ talonErrorsModalData.title }}</span>
              </div>
            </v-card-text>
            <template v-if="!isTalonErrorsLoading">
              <v-card-text>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
                >
                  <table class="swal-table">
                    <thead>
                      <th />
                      <th>По данным талонов</th>
                      <th>В талонах отсутствует</th>
                      <th>По данным учёта</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="el in productHeaders"
                        :key="el.productName"
                      >
                        <template v-if="showItogRow(el)">
                          <td>{{ el.productName }}</td>
                          <td>{{ talonErrorsModalData.itogArrayItem[el.code] }}</td>
                          <td>{{ talonErrorsModalData.itogArraySale[el.code] }}</td>
                          <td>{{ talonErrorsModalData.itogArrayUchet[el.code] }}</td>
                          <td>
                            <template v-if="talonErrorsModalData.usersSalesErrorList[el.code]">
                              <v-btn @click="openTalonDetail(talonErrorsModalData.usersSalesErrorList[el.code])">
                                Детали
                              </v-btn>
                            </template>
                          </td>
                        </template>
                      </tr>
                      <template v-if="talonErrorsModalData.withErrors">
                        <template v-if="talonErrorsModalData.countErrorSales > 0">
                          <tr>
                            <td>Неверный продукт</td>
                            <td colspan="2" />
                            <td>{{ talonErrorsModalData.countErrorSales }}</td>
                            <td />
                          </tr>
                        </template>
                        <template v-if="talonErrorsModalData.countErrorNotNumber > 0">
                          <tr>
                            <td>Неверное значение продукта</td>
                            <td colspan="2" />
                            <td> {{ talonErrorsModalData.countErrorNotNumber }}</td>
                            <td />
                          </tr>
                          <tr>
                            <td colspan="100%">
                              Возникли ошибки при подсчётах, обязательно сообщите об этом
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </v-card-text>
              <v-card-actions>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
                >
                  <v-btn
                    color="lighten-2"
                    :disabled="isTalonErrorsByClassLoading"
                    @click="() => isTalonErrorsOpen=false"
                  >
                    Закрыть
                  </v-btn>
                </div>
              </v-card-actions>
            </template>
            <template v-else>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}"
              >
                <v-col class="text-center">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="green"
                    indeterminate
                  />
                </v-col>
              </div>
            </template>
          </v-card>
        </v-dialog>

        <!--Модальное окно с детализацией ошибок по классам-->
        <v-dialog
          v-model="isTalonErrorsByClassOpen"
          width="1100"
        >
          <v-card>
            <v-card-text>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center'}"
              >
                <span class="text-h5 align-center">{{ talonErrorsDetailModalData.title }}</span>
              </div>
            </v-card-text>
            <template v-if="!isTalonErrorsByClassLoading">
              <v-card-text>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center'}"
                >
                  <base-simple-table :table-data="talonErrorsDetailModalData.tableData" />
                </div>
              </v-card-text>
              <v-card-actions>
                <div
                  class="wrapper"
                  :style="{display: 'flex', justifyContent: 'end', width: '100%'}"
                >
                  <v-btn
                    color="lighten-2"
                    :disabled="isTalonErrorsByClassLoading"
                    @click="() => isTalonErrorsByClassOpen=false"
                  >
                    Закрыть
                  </v-btn>
                </div>
              </v-card-actions>
            </template>
            <template v-else>
              <div
                class="wrapper"
                :style="{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}"
              >
                <v-col class="text-center">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="green"
                    indeterminate
                  />
                </v-col>
              </div>
            </template>
          </v-card>
        </v-dialog>

        <!--Таблица талонов-->
        <v-data-table
          v-model="selected"
          :headers="filteredHeaders"
          :items="placeElectronTalonListFiltered"
          :single-select="singleSelect"
          item-key="id"
          show-select
          class="elevation-1 mytable"
          :row-props="rowClasses"
          :search="searchString"
          search-text="Поиск"
          :items-per-page="-1"
          :footer-props="footerProps"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
          :return-object="true"
        >
          <template #top>
            <v-switch
              v-model="singleSelect"
              label="Single select"
              class="pa-3"
            />
          </template>
          <template #[`item.talonNumber`]="{item}">
            <span>
              <v-btn
                v-if="item.talonNumber != null"
                :color="talonNumberColor(item.placeElectronTalons)"
              >
                {{ item.talonNumber }}
              </v-btn>
            </span>
          </template>
          <template #[`item.action`]="{item}">
            <span>
              <v-btn
                v-if="item.talonNumber != null"
                :to="{ name: 'TalonEdit', params: { id: item.placeElectronTalons[0].id, date: dateProp, placeId: item.placeElectronTalons[0].parentPlaceId, selectedPlaceItemId: selectedPlaceItemId } }"
                action
              >
                Дети
              </v-btn>
            </span>
            <span>
              <v-btn
                v-if="item.talonNumber != null"
                :to="{ name: 'TalonRationEdit', params: { id: item.placeElectronTalons[0].id, date: dateProp, placeId: item.placeElectronTalons[0].parentPlaceId, selectedPlaceItemId: selectedPlaceItemId } }"
                action
              >
                Рационы
              </v-btn>
            </span>
          </template>
          <template #[`item.placeElectronTalonTeacherHistoryItem`]="{item}">
            <span>
              {{ item.placeElectronTalonTeacherHistoryItem }}
              <br>
              {{ item.placeElectronTalonTeacherHistoryItemTimmestamp }}
            </span>
          </template>
          <template #[`top`]>
            <v-row
              v-show="errorTalons>0 || warningTalons>0"
              align="center"
            >
              <v-col align="center">
                Количество талонов <span v-show="errorTalons>0">с ошибками: {{ errorTalons }}</span><span
                  v-show="errorTalons>0 && warningTalons>0"
                >,</span><span
                  v-show="warningTalons>0"
                > с предупреждениями: {{ warningTalons }}</span>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col
                class="d-flex"
                cols="2"
                sm="3"
              >
                <v-select
                  v-model="selectedParallelFilter"
                  :items="parallelFilter"
                  item-title="place.name"
                  item-value="place.id"
                  label="Параллель"
                  variant="outlined"
                  @update:model-value="applyFilter()"
                />
              </v-col>
              <v-col
                class="d-flex"
                cols="2"
                sm="3"
              >
                <v-select
                  v-model="selectedTalonCreateFilter"
                  :items="talonCreateFilter"
                  item-title="place.name"
                  item-value="place.id"
                  label="Сформированные"
                  variant="outlined"
                  @update:model-value="applyFilter()"
                />
              </v-col>
              <v-col
                class="d-flex"
                cols="2"
                sm="3"
              >
                <v-select
                  v-model="selectedTeacherFilter"
                  :items="signTeacherFilter"
                  label="Подписанные учителем"
                  variant="outlined"
                  @update:model-value="applyFilter()"
                />
              </v-col>
              <v-col
                class="d-flex"
                cols="2"
                sm="3"
              >
                <v-select
                  v-model="selectedLadminFilter"
                  :items="signLadminFilter"
                  item-title="place.name"
                  item-value="place.id"
                  label="Подписанные ответственным"
                  variant="outlined"
                  @update:model-value="applyFilter()"
                />
              </v-col>
            </v-row>
          </template>
          <template #no-data>
            <v-alert
              v-if="loading == false && placeElectronTalonList.length == 0"
              :value="true"
              icon="warning"
            >
              Нет данных
            </v-alert>
            <v-alert
              v-else
              :value="false"
              icon="warning"
            >
              Нет данных
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { parseAxiosError, ServerError } from '@/shared/lib/api'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import {
  PlaceElectronTalonCreatePost,
  PlaceElectronTalonSearchPost,
  PlaceElectronTalonSignPost,
  salesPost
} from '@/shared/api/placeElectronTalonList/api-types'
import {
  create as placeElectronTalonCreate,
  sales,
  sign as placeElectronTalonSign,
  userPlaceInfoBySaleId,
  validation as placeElectronTalonValidation
} from '@/shared/api/placeElectronTalonList/api-requests'
import { PlaceCustomerProductSearchPost } from '@/shared/api/placeCustomerProductList/api-types'
import { ErrorGroups, Errors } from '@/app/types/vueEnums'
import Swal from 'sweetalert2'
import { findPlaceProducts, savePlaceProduct } from '@/shared/api/placeElectronTalon/api-requests'
import BaseSimpleTable, { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import moment from 'moment'

type BaseData = {
  removedFromTalonSalesIds: any;
  placeElectronTalonSearchPost: PlaceElectronTalonSearchPost;
  placeCustomerProductSearchPost: PlaceCustomerProductSearchPost;
  placeElectronTalonCreatePost: PlaceElectronTalonCreatePost;
  placeElectronTalonSignPost: PlaceElectronTalonSignPost;
  sales: any;
  isLoading: boolean;
  result: any;
  productDetails: any;
  productHeaders: any;
  talonErrorsArray: any;
  footerProps: any;
  searchString: string;
  searchTerm: string;
  loading: boolean;
  singleSelect: boolean;
  selected: any;
  userAllowedPlacesCopy: any;
  selectedUserAllowedPlace: any;
  userAllowedPlacesList: any;
  valid: boolean;
  date: string;
  dateProp: string;
  menu: boolean;
  placeElectronTalonList: any;
  placeElectronTalonListFiltered: any;
  parallelFilter: any;
  selectedParallelFilter: any;
  talonCreateFilter: any;
  selectedTalonCreateFilter: any;
  signTeacherFilter: any;
  selectedTeacherFilter: any;
  signLadminFilter: any;
  selectedLadminFilter: any;
  forTeacherSign: boolean;
  errorTalons: number;
  warningTalons: number;
  breakfastCount: number;
  dinnerCount: number;
  dinnerHighCount: number;
  itogArray: any;
  datePicker: string;
  isOptionsOpen: boolean;
  isOptionsLoading: boolean;
  options: any[];
  isPlaceLoading: any;
  isPlaceDisabled: any;
  placeItemsFiltered: any;
  placeItems: any;
  selectedPlaceItemId: any;
  placeSearchTerm: any;
  filteredHeaders: any;
  talonErrorsModalData: any;
  talonErrorsDetailModalData: any;
  isTalonErrorsOpen: boolean;
  isTalonErrorsByClassOpen: boolean;
  isTalonErrorsLoading: boolean;
  isTalonErrorsByClassLoading: boolean;
  back: boolean;
}
export default defineComponent({
  components: {
    BaseSimpleTable,
    SubmitButton
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  data (): BaseData {
    return {
      removedFromTalonSalesIds: {},
      footerProps: {
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Количество на странице',
        itemsPerPageOptions: [10, 20, 30, 100, 1000, -1]
      },
      date: new Date().toISOString().substr(0, 10),
      dateProp: '',
      menu: false,
      searchString: '',
      searchTerm: '',
      itogArray: [],
      loading: false,
      singleSelect: false,
      sales: [],
      selected: [],
      userAllowedPlacesCopy: [],
      userAllowedPlacesList: [],
      selectedUserAllowedPlace: [],
      talonErrorsArray: [],
      forTeacherSign: false,
      errorTalons: 0,
      warningTalons: 0,
      breakfastCount: 0,
      dinnerCount: 0,
      dinnerHighCount: 0,
      valid: true,
      isLoading: false,
      parallelFilter: ['Все классы', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      selectedParallelFilter: 'Все классы',
      talonCreateFilter: ['Не фильтровать', 'Да', 'Нет'],
      selectedTalonCreateFilter: 'Не фильтровать',
      signTeacherFilter: ['Не фильтровать', 'Да', 'Нет'],
      selectedTeacherFilter: 'Не фильтровать',
      signLadminFilter: ['Не фильтровать', 'Да', 'Нет'],
      selectedLadminFilter: 'Не фильтровать',
      placeElectronTalonSearchPost: {
        parentPlaceId: 0
      },
      placeCustomerProductSearchPost: {
        placeId: 0,
        date: new Date().toISOString().substr(0, 10)
      },
      placeElectronTalonCreatePost: {
        placeId: 0,
        classIds: [],
        date: ''
      },
      placeElectronTalonSignPost: {
        talonIds: [],
        forTeacherSign: false
      },
      result: [],
      productDetails: [],
      productHeaders: [],
      placeElectronTalonList: [],
      placeElectronTalonListFiltered: [],
      datePicker: '',
      isOptionsOpen: false,
      isOptionsLoading: false,
      options: [
        {
          name: '',
          productId: 1,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 130,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 2,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 3,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 4,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 461,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 462,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 463,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 464,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 465,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 466,
          value: 1,
          typeId: 2
        },
        {
          name: '',
          productId: 9,
          value: 0,
          typeId: 2
        }
      ],
      back: false,
      isPlaceLoading: false,
      isPlaceDisabled: true,
      placeItemsFiltered: [{ id: 0, name: 'Загружаем' }],
      placeItems: [{ id: 0, name: 'Ошибка загрузки' }],
      selectedPlaceItemId: 0,
      placeSearchTerm: '',
      filteredHeaders: [],
      talonErrorsModalData: {
        title: 'Итоги:',
        itogArrayItem: [],
        itogArraySale: [],
        itogArrayUchet: [],
        usersSalesErrorList: [],
        withErrors: false,
        countErrorSales: 0,
        countErrorNotNumber: 0
      },
      talonErrorsDetailModalData: {
        title: 'Итоги по классам:',
        tableData: {} as TableData,
        withErrors: false,
        countErrorSales: 0,
        countErrorNotNumber: 0
      },
      isTalonErrorsOpen: false,
      isTalonErrorsByClassOpen: false,
      isTalonErrorsLoading: false,
      isTalonErrorsByClassLoading: false
    }
  },
  async beforeMount () {
    try {
      await this.loadUserAllowedPlaces()
      const res = await findPlaceProducts({
        placeId: this.selectedPlaceItemId,
        typeId: 2
      })
      this.options = res
      const back = this.$route.query.back
      const date = this.$route.query.date
      const placeId = this.$route.query.placeId
      const selectedPlaceItemId = this.$route.query.selectedPlaceItemId
      if (back === 'true') {
        this.back = true
        this.date = String(date)
        this.selectedPlaceItemId = selectedPlaceItemId
        await this.handleSubmit()
      }
      this.isLoading = true
      this.isLoading = false
    } catch (e: any) {
      this.setSnackbar({
        showing: true,
        text: `Произошла ошибка, попробуйте перезагрузить страницу! Код ответа ${e.response.status} - ${e.response.statusText}`
      })
    }
  },
  computed: {
    ...mapGetters({ placeElectronTalons: 'placeElectronTalons/placeElectronTalons' })
  },
  methods: {
    ...mapActions('placeElectronTalons', { placeElectronTalonFind: 'find' }),
    ...mapActions('placeCustomerProducts', { placeCustomerProductFind: 'find' }),
    ...mapActions('incrementGroupDevices', { incrementGroupDeviceCreate: 'create' }),
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    updateHeaders (headers: any[]) {
      this.filteredHeaders = []
      this.filteredHeaders.push({ title: 'Класс', align: 'start', value: 'name', isProductColumn: false })
      this.filteredHeaders.push({ title: '№ талона', value: 'talonNumber', isProductColumn: false })
      headers.forEach((element: any) => {
        this.filteredHeaders.push({
          title: element.productName + '\n Всего:' + element.count,
          value: element.code,
          sortable: false,
          filterable: false,
          isProductColumn: true,
          productCode: element.code
        })
      })
      this.filteredHeaders.push({
        title: 'Подпись кл.рук.',
        value: 'placeElectronTalonTeacherHistoryItem',
        sortable: false,
        filterable: false,
        isProductColumn: false
      })
      this.filteredHeaders.push({
        title: 'Подпись отв.пит.',
        value: 'placeElectronTalonLadminHistoryItem',
        sortable: false,
        filterable: false,
        isProductColumn: false
      })
      this.filteredHeaders.push({
        title: 'Действие',
        value: 'action',
        sortable: false,
        filterable: false,
        isProductColumn: false
      })
    },
    async loadUserAllowedPlaces () {
      try {
        this.isPlaceLoading = true
        const userAllowedPlaces = await this.userAllowedPlaceAll()
        this.placeItems = []
        userAllowedPlaces.forEach((obj: any) => {
          if (obj.removed == null) {
            if (this.placeItems.length > 0) {
              this.placeItems.push({ id: obj.place.id, name: obj.place.name })
            } else {
              this.placeItems = [{ id: obj.place.id, name: obj.place.name }]
            }
          }
        })
        console.log(this.placeItems)
        if (this.selectedPlaceItemId === 0) this.selectedPlaceItemId = this.placeItems[0].id
        this.isPlaceDisabled = false
      } catch (error: any) {
        this.placeItems = [{ id: 0, name: 'Ошибка загрузки' }]
      } finally {
        this.isPlaceLoading = false
        this.searchItem()
      }
    },
    searchItem () {
      if (!this.placeSearchTerm) {
        this.placeItemsFiltered = this.placeItems
      }
      if (Array.isArray(this.placeItems)) {
        this.placeItemsFiltered = this.placeItems.filter((obj: any) => {
          return obj.name.toLowerCase().indexOf(this.placeSearchTerm.toLowerCase()) > -1
        })
      }
    },
    update () {
      this.$emit('on-change', this.placeItems.find((obj: any) => obj.id === this.selectedPlaceItemId))
      if (this.selectedPlaceItemId !== 0) this.$emit('place-select')
    },
    handleSubmitOptions () {
      this.isOptionsOpen = true
    },
    async closeOptionModal () {
      try {
        this.isOptionsOpen = false
        this.isLoading = true
        const res = await findPlaceProducts({
          placeId: this.selectedPlaceItemId,
          typeId: 2
        })
        this.options = res
        await this.applyFilter()
        this.isLoading = false
      } catch (e: any) {
        this.setSnackbar({
          showing: true,
          text: `Произошла ошибка, не удалось обновить настройки! Код ответа ${e.response.status} - ${e.response.statusText}`
        })
      } finally {
        this.isOptionsLoading = false
        this.isOptionsOpen = false
        this.isLoading = false
      }
    },
    async handleOptionValueChange (value: any) {
      try {
        value.value = value.value === 1 ? 0 : 1
        this.isOptionsLoading = true
        await savePlaceProduct({
          placeId: this.selectedPlaceItemId,
          productId: value.productId,
          value: value.value ? 1 : 0,
          typeId: 2
        })
        this.setSnackbar({
          showing: true,
          text: 'Настройки успешно обновлены!',
          timeout: 1000
        })
        this.isOptionsLoading = false
      } catch (e: any) {
        this.setSnackbar({
          showing: true,
          text: `Произошла ошибка, не удалось обновить настройку! Код ответа ${e.response.status} - ${e.response.statusText}`
        })
        this.isOptionsLoading = false
        this.isOptionsOpen = false
      }
    },
    async loadPlaceElectronTalons (placeElectronTalonSearchPost: PlaceElectronTalonSearchPost) {
      try {
        return await this.placeElectronTalonFind(placeElectronTalonSearchPost)
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    async loadPlaceCustomerProducts (placeCustomerProductSearchPost: PlaceCustomerProductSearchPost) {
      try {
        return await this.placeCustomerProductFind(placeCustomerProductSearchPost)
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    async loadSales (salesPost: salesPost) {
      try {
        this.sales = await sales(salesPost)
      } catch (error: any) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      }
    },
    talonNumberColor (item: any) {
      if (item[0].guid != null) {
        return 'primary'
      }
      return 'success'
    },
    searchUserAllowedPlaces () {
      if (!this.searchTerm) {
        this.userAllowedPlacesList = this.userAllowedPlacesCopy
      }

      this.userAllowedPlacesList = this.userAllowedPlacesCopy.filter((userPlaceCopy: any) => {
        return userPlaceCopy.place.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
      })
    },
    compareByTimestamp (a: any, b: any) {
      const timestampA = a.timestampFrom
      const timestampB = b.timestampFrom

      let comparison = 0
      if (timestampA > timestampB) {
        comparison = 1
      } else if (timestampA < timestampB) {
        comparison = -1
      }
      return comparison
    },
    compareByName (a: any, b: any) {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      const str1 = nameA.match(/([^0-9]+)|([0-9]+)/g)
      const str2 = nameB.match(/([^0-9]+)|([0-9]+)/g)
      const comparison = 0
      if (str1.length === 0) return 1
      if (str2.length === 0) return -1
      if (Number(str1[0]) > Number(str2[0])) {
        return 1
      } else if (Number(str1[0]) < Number(str2[0])) {
        return -1
      } else {
        if (str1.length === 1) return 1
        if (str2.length === 1) return -1
        if (str1[1] > str2[1]) {
          return 1
        } else if (str1[1] < str2[1]) {
          return -1
        }
      }
      return comparison
    },
    rowClasses (item: any) {
      let className = ''
      let flag = true
      if (item.placeElectronTalons) {
        this.talonErrorsArray.forEach((element: any) => {
          if (element[0].toString() === item.placeElectronTalons[0].id.toString()) {
            className = 'error-row-' + element[1].toString()
            flag = false
          } else {
            if (flag) {
              className = ''
            }
          }
        })
      }
      return className
    },
    applyFilter () {
      this.placeElectronTalonListFiltered = JSON.parse(JSON.stringify(this.placeElectronTalonList))
      this.breakfastCount = 0
      this.dinnerCount = 0
      this.dinnerHighCount = 0
      this.placeElectronTalonListFiltered.filter((placeElectronTalon: any) => {
        let flag = false
        this.options.forEach((option: any) => {
          if (Object.keys(placeElectronTalon).includes(`product_${option.productId}`) && option.value === 0) {
            flag = true
          }
        })
        return !flag
      })
      let filteredHeaders = JSON.parse(JSON.stringify(this.productHeaders))
      filteredHeaders = filteredHeaders.filter((productHeader: any) => {
        let flag = false
        this.options.find((option: any) => {
          if (productHeader.productId.toString() === option.productId.toString() && option.value === 0) {
            flag = true
          }
        })
        return !flag
      })
      this.updateHeaders(filteredHeaders)
      if (this.selectedParallelFilter !== 'Все классы') {
        const regExp = new RegExp(this.selectedParallelFilter)
        this.placeElectronTalonListFiltered = this.placeElectronTalonListFiltered.filter((element: any) => element.name.match(regExp))
      }

      if (this.selectedTalonCreateFilter === 'Да') {
        this.placeElectronTalonListFiltered = this.placeElectronTalonListFiltered.filter((element: any) => element.placeElectronTalons !== null)
      } else if (this.selectedTalonCreateFilter === 'Нет') {
        this.placeElectronTalonListFiltered = this.placeElectronTalonListFiltered.filter((element: any) => element.placeElectronTalons === null)
      }
      if (this.selectedTeacherFilter === 'Да' || (this.selectedTeacherFilter === 'Нет')) {
        this.placeElectronTalonListFiltered = this.placeElectronTalonListFiltered.filter((element: any) => {
          let placeElectronTalonTeacherHistoryItem = []
          if (element.placeElectronTalons) {
            placeElectronTalonTeacherHistoryItem = element.placeElectronTalons.map((placeElectronTalonHistoryItems: any) => {
              const placeElectronTalonTeacherHistory = placeElectronTalonHistoryItems.placeElectronTalonHistoryItems
                .filter((placeElectronTalonHistoryItem: any) => placeElectronTalonHistoryItem.user != null && placeElectronTalonHistoryItem.userTypeId === '3' &&
                  placeElectronTalonHistoryItem.status === 1)
              return placeElectronTalonTeacherHistory
            })[0]
          }
          if (placeElectronTalonTeacherHistoryItem.length > 0) {
            return this.selectedTeacherFilter === 'Да';
          } else {
            return this.selectedTeacherFilter !== 'Да';
          }
        })
      }
      if (this.selectedLadminFilter === 'Да' || (this.selectedLadminFilter === 'Нет')) {
        this.placeElectronTalonListFiltered = this.placeElectronTalonListFiltered.filter((element: any) => {
          let placeElectronTalonTeacherHistoryItem = []
          if (element.placeElectronTalons) {
            placeElectronTalonTeacherHistoryItem = element.placeElectronTalons.map((placeElectronTalonHistoryItems: any) => {
              const placeElectronTalonTeacherHistory = placeElectronTalonHistoryItems.placeElectronTalonHistoryItems
                .filter((placeElectronTalonHistoryItem: any) => placeElectronTalonHistoryItem.user != null && placeElectronTalonHistoryItem.userTypeId === '7' &&
                  placeElectronTalonHistoryItem.status === 1)
              return placeElectronTalonTeacherHistory
            })[0]
          }
          if (placeElectronTalonTeacherHistoryItem.length > 0) {
            if (this.selectedLadminFilter === 'Да') return true
            return false
          } else {
            if (this.selectedLadminFilter === 'Да') return false
            return true
          }
        })
      }
      this.errorTalons = 0
      this.warningTalons = 0
      this.placeElectronTalonListFiltered.forEach((element: any) => {
        if (element.placeElectronTalonSaleZavtraks !== null) {
          this.breakfastCount = this.breakfastCount + element.placeElectronTalonSaleZavtraks
        }
        if (element.placeElectronTalonSaleObeds !== null) {
          this.dinnerCount = this.dinnerCount + element.placeElectronTalonSaleObeds
        }
        if (element.placeElectronTalonSaleObedStarsh !== null) {
          this.dinnerHighCount = this.dinnerHighCount + element.placeElectronTalonSaleObedStarsh
        }
        let errorTalon = false
        let warningTalon = false
        if (element.placeElectronTalons) {
          element.placeElectronTalons.forEach((talon: any) => {
            talon.placeElectronTalonItems.forEach((item: any) => {
              if (item.error) {
                item.error.split(',').forEach((error: any) => {
                  const key: string = Errors[error]
                  for (const groupValue in ErrorGroups) {
                    if (groupValue === key) {
                      if (ErrorGroups[groupValue].toString() === '1' || ErrorGroups[groupValue].toString() === '2') {
                        errorTalon = true
                      } else if (ErrorGroups[groupValue].toString() === '4' || ErrorGroups[groupValue].toString() === '3' || ErrorGroups[groupValue].toString() === '5') {
                        warningTalon = true
                      }
                    }
                  }
                })
              }
            })
          })
        }

        if (errorTalon) this.errorTalons = this.errorTalons + 1
        if (warningTalon) this.warningTalons = this.warningTalons + 1
      })
    },
    async handleSubmitSign () {
      this.loading = true
      if (this.selected.length > 0) {
        this.placeElectronTalonSignPost.forTeacherSign = this.forTeacherSign
        this.placeElectronTalonSignPost.talonIds = []
        this.selected.forEach((element: any) => {
          if (element.placeElectronTalons) {
            this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
          }
        })
        this.selected = []
        try {
          const result = await placeElectronTalonSign(this.placeElectronTalonSignPost)
          let showErrors = false
          let textError = ''
          let textWarnings = ''
          result.forEach((element: any) => {
            if (!element.signed) {
              showErrors = true
              if (element.error === 0) {
                textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
              } else {
                const key: string = Errors[element.error]
                const errorType: number = ErrorGroups[key as keyof typeof ErrorGroups]
                if (errorType === 1 || errorType === 2) {
                  textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                } else {
                  textWarnings = textWarnings + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                }
              }
            }
          })
          if (showErrors) {
            Swal.fire({
              title: '',
              html: '<h2>Ошибки в талонах:</h2><br>' + textError + '<br><h2>Предупреждения в талонах:</h2><br>' + textWarnings
            })
          }
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Не удалось подписать талоны, был выбран не сформированный талон.',
            timeout: 3000
          })
          return Promise.reject(parseAxiosError(error))
        } finally {
          this.loading = false
          this.handleSubmit()
        }
      } else {
        Swal.fire({
          title: 'Вы уверены что хотите подписать все талоны?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Да',
          denyButtonText: 'Нет'
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (this.forTeacherSign) {
              this.placeElectronTalonSignPost.forTeacherSign = this.forTeacherSign
              this.placeElectronTalonSignPost.talonIds = []
              this.placeElectronTalonListFiltered.forEach((element: any) => {
                this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
              })
              this.selected = []
            } else {
              this.placeElectronTalonSignPost.forTeacherSign = this.forTeacherSign
              this.placeElectronTalonSignPost.talonIds = []
              this.placeElectronTalonListFiltered.forEach((element: any) => {
                if (element.placeElectronTalonTeacherHistoryItem != null) {
                  this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
                }
              })
              this.selected = []
            }
            try {
              const result = await placeElectronTalonSign(this.placeElectronTalonSignPost)
              let showErrors = false
              let textError = ''
              let textWarnings = ''
              result.forEach((element: any) => {
                if (!element.signed) {
                  showErrors = true
                  if (element.error === 0) {
                    textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                  } else {
                    const key: string = Errors[element.error]
                    const errorType: number = ErrorGroups[key as keyof typeof ErrorGroups]
                    if (errorType === 1 || errorType === 2) {
                      textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                    } else {
                      textWarnings = textWarnings + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                    }
                  }
                }
              })
              if (showErrors) {
                Swal.fire({
                  title: '',
                  html: '<h2>Ошибки в талонах:</h2><br>' + textError + '<br><h2>Предупреждения в талонах:</h2><br>' + textWarnings
                })
              } else {
                Swal.fire('Подписано!', '', 'success')
              }
            } catch (error: any) {
              this.setSnackbar({
                showing: true,
                text: 'Не удалось подписать талоны, был выбран не сформированный талон.',
                timeout: 3000
              })
              return Promise.reject(parseAxiosError(error))
            } finally {
              this.loading = false
              this.handleSubmit()
            }
          } else if (result.isDenied) {
            Swal.fire('Изменения не подтверждены', '', 'info')
            this.loading = false
          }
        })
        this.loading = false
      }
    },
    async handleSubmitRecord () {
      if (this.selected.length > 0) {
        this.loading = true
        this.placeElectronTalonSignPost.forTeacherSign = this.forTeacherSign
        this.placeElectronTalonSignPost.talonIds = []
        this.placeElectronTalonSignPost.accounting = true
        this.selected.forEach((element: any) => {
          if (element.placeElectronTalons) {
            this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
          }
        })
        this.selected = []
        try {
          const result = await placeElectronTalonValidation(this.placeElectronTalonSignPost)
          let showErrors = false
          let textError = ''
          let textWarnings = ''
          result.forEach((element: any) => {
            if (!element.signed) {
              showErrors = true
              if (element.error === 0) {
                textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
              } else {
                const key: string = Errors[element.error]
                const errorType: number = ErrorGroups[key as keyof typeof ErrorGroups]
                if (errorType === 1 || errorType === 2) {
                  textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                } else {
                  textWarnings = textWarnings + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                }
              }
            }
          })
          if (showErrors) {
            Swal.fire({
              title: '',
              html: '<h2>Ошибки в талонах:</h2><br>' + textError + '<br><h2>Предупреждения в талонах:</h2><br>' + textWarnings
            })
          }
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Не удалось подписать талоны, был выбран не сформированный талон.',
            timeout: 3000
          })
          return Promise.reject(parseAxiosError(error))
        } finally {
          this.loading = false
          this.handleSubmit()
        }
      } else {
        alert('Нет ни одного отмеченного талона')
        /*
        this.placeElectronTalonSignPost.forTeacherSign = this.forTeacherSign
        this.placeElectronTalonSignPost.talonIds = []
        this.placeElectronTalonSignPost.accounting = true
        this.placeElectronTalonListFiltered.forEach((element: any) => {
          this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
        })
        this.selected = []
        */
      }
    },
    updateDate (value: any) {
      this.date = moment(value).format('YYYY-MM-DD')
    },
    formatDate (date: string) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    async handleSubmitValidation () {
      if (this.selected.length > 0) {
        this.loading = true
        this.placeElectronTalonSignPost.forTeacherSign = false
        this.placeElectronTalonSignPost.talonIds = []
        this.placeElectronTalonSignPost.mode = 1
        this.placeElectronTalonSignPost.accounting = false
        this.selected.forEach((element: any) => {
          if (element.placeElectronTalons) {
            this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
          }
        })
        this.selected = []
        try {
          const result = await placeElectronTalonValidation(this.placeElectronTalonSignPost)
          let showErrors = false
          let textError = ''
          let textWarnings = ''
          result.forEach((element: any) => {
            if (!element.signed) {
              showErrors = true
              if (element.error === 0) {
                textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
              } else {
                const key: string = Errors[element.error]
                const errorType: number = ErrorGroups[key as keyof typeof ErrorGroups]
                if (errorType === 1 || errorType === 2) {
                  textError = textError + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                } else {
                  textWarnings = textWarnings + 'В талоне: ' + element.talonNumber + ' ' + element.message + ' <br> '
                }
              }
            }
          })
          if (showErrors) {
            Swal.fire({
              title: '',
              html: '<h2>Ошибки в талонах:</h2><br>' + textError + '<br><h2>Предупреждения в талонах:</h2><br>' + textWarnings
            })
          }
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Не удалось провалидировать талоны, ошибка сервера.',
            timeout: 3000
          })
          return Promise.reject(parseAxiosError(error))
        } finally {
          this.loading = false
          this.handleSubmit()
        }
      } else {
        alert('Нет ни одного отмеченного талона')
        /*
        this.placeElectronTalonSignPost.forTeacherSign = false
        this.placeElectronTalonSignPost.talonIds = []
        this.placeElectronTalonSignPost.mode = 1
        this.placeElectronTalonSignPost.accounting = false
        this.placeElectronTalonListFiltered.forEach((element: any) => {
          this.placeElectronTalonSignPost.talonIds.push(element.placeElectronTalons[0].id)
        })
        this.selected = []
        */
      }
    },
    async handleSubmitCreate () {
      if (this.selected.length > 0) {
        this.placeElectronTalonCreatePost.classIds = []
        this.loading = true
        this.selected.forEach((element: any) => {
          this.placeElectronTalonCreatePost.classIds.push(element.id)
        })
        this.selected = []
        try {
          await placeElectronTalonCreate(this.placeElectronTalonCreatePost)
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка создания талона',
            timeout: 3000
          })
          return Promise.reject(parseAxiosError(error))
        } finally {
          this.loading = false
          this.handleSubmit()
        }
      } else {
        Swal.fire('Не выбрано ни одного талона')
      }
    },
    async handleSubmit () {
      this.talonErrorsArray = []
      this.dateProp = moment(this.date).format('YYYY-MM-DD')
      const d = new Date(this.date)
      d.setDate(d.getDate() + 1)
      const salesPost: salesPost = {
        placeId: this.selectedPlaceItemId,
        timestampFrom: moment(this.date).format('YYYY-MM-DD'),
        timestampTo: moment(this.date).add(1,'d').format('YYYY-MM-DD'),
        withoutCash: 1
      }
      this.loadSales(salesPost)
      this.placeElectronTalonList = []
      this.selected = []
      this.isLoading = true
      this.loading = true
      try {
        this.placeElectronTalonSearchPost = {
          parentPlaceId: this.selectedPlaceItemId,
          dateFrom: moment(this.date).format('YYYY-MM-DD'),
          dateTo: moment(this.date).add(1,'d').format('YYYY-MM-DD'),
          salesInclude: true,
          historyInclude: true,
          itemsInclude: true,
          discountSalesInclude: true
        }
        this.placeCustomerProductSearchPost = {
          placeId: this.selectedPlaceItemId,
          date: moment(this.date).format('YYYY-MM-DD')
        }
        this.placeElectronTalonCreatePost.placeId = this.selectedPlaceItemId
        this.placeElectronTalonCreatePost.date = moment(this.date).format('YYYY-MM-DD')
        this.result = await this.loadPlaceElectronTalons(this.placeElectronTalonSearchPost)
        this.productDetails = await this.loadPlaceCustomerProducts(this.placeCustomerProductSearchPost)
        this.placeElectronTalonSearchPost = {
          parentPlaceId: 0
        }
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        const removedFromTalonSalesIds: any = {}
        if (this.productDetails) {
          this.productHeaders = []
          this.productDetails.forEach((element: any) => {
            this.productHeaders.push({
              productId: element.productId,
              productName: this.nameConvert(element.product.name),
              code: 'product_' + element.productId,
              count: 0
            })
          })
        }
        if (this.result) {
          this.itogArray = []
          this.placeElectronTalonList = JSON.parse(JSON.stringify(this.result))
          this.placeElectronTalonList = this.placeElectronTalonList.map((placeElectronTalon: any) => {
            if (placeElectronTalon.placeTempAttributes != null) {
              const placeElectronTalonClassName = placeElectronTalon.placeTempAttributes
                .filter((placeElectronTalonAttributes: any) => placeElectronTalonAttributes.timestampFrom < moment(this.date).format('YYYY-MM-DD')).sort(this.compareByTimestamp).reverse()[0]
              if (placeElectronTalonClassName != null) {
                placeElectronTalon.name = placeElectronTalonClassName.name
              }
            }
            return placeElectronTalon
          })
          this.placeElectronTalonList = this.placeElectronTalonList.filter((placeElectronTalonAttributes: any) => placeElectronTalonAttributes.name.match(/^[0-9].*$/))
            .sort(this.compareByName)
          this.placeElectronTalonList = this.placeElectronTalonList.map((placeElectronTalon: any) => {
            this.productHeaders.forEach((element: any) => {
              placeElectronTalon[element.code] = 0
            })
            if (placeElectronTalon.placeElectronTalons != null) {
              const placeElectronTalonNumber = placeElectronTalon.placeElectronTalons
                .filter((placeElectronTalonNumbers: any) => new Date(placeElectronTalonNumbers.date).toISOString().substr(0, 10) === moment(this.date).format('YYYY-MM-DD'))[0]
              placeElectronTalon.talonNumber = null
              if (placeElectronTalonNumber != null) {
                placeElectronTalon.talonNumber = placeElectronTalonNumber.talonNumber
              }
              placeElectronTalon.placeElectronTalons.forEach((talon: any) => {
                let levelError = 100
                talon.placeElectronTalonItems.forEach((item: any) => {
                  if (item.error) {
                    item.error.split(',').forEach((error: any) => {
                      const key: string = Errors[error]
                      for (const groupValue in ErrorGroups) {
                        if (groupValue === key) {
                          if (levelError > parseInt(ErrorGroups[groupValue])) {
                            levelError = parseInt(ErrorGroups[groupValue])
                          }
                        }
                      }
                    })
                  }
                  item.placeElectronTalonItemSales.forEach((sale: any) => {
                    if (sale != null) {
                      if (sale.removed == null) {
                        placeElectronTalon['product_' + sale.productId]++
                      } else {
                        if (!removedFromTalonSalesIds[sale.saleId]) {
                          removedFromTalonSalesIds[sale.saleId] = talon.talonNumber
                        }
                      }
                    } else {
                      console.log('sale is null')
                    }
                  })
                })
                if (levelError < 100) {
                  this.talonErrorsArray.push([talon.id, levelError])
                }
              })
              placeElectronTalon.itogType = 1

              const placeElectronTalonTeacherHistoryItem = placeElectronTalon.placeElectronTalons.map((placeElectronTalonHistoryItems: any) => {
                const placeElectronTalonTeacherHistory = placeElectronTalonHistoryItems.placeElectronTalonHistoryItems
                  .filter((placeElectronTalonHistoryItem: any) => placeElectronTalonHistoryItem.user != null && placeElectronTalonHistoryItem.userTypeId === '3' &&
                    placeElectronTalonHistoryItem.status === 1)
                return placeElectronTalonTeacherHistory
              })[0]
              placeElectronTalon.placeElectronTalonTeacherHistoryItem = null
              if (placeElectronTalonTeacherHistoryItem.length > 0) {
                placeElectronTalon.placeElectronTalonTeacherHistoryItem = placeElectronTalonTeacherHistoryItem[0].user.login
                placeElectronTalon.placeElectronTalonTeacherHistoryItemTimmestamp = placeElectronTalonTeacherHistoryItem[0].timestamp
              }

              const placeElectronTalonLadminHistoryItem = placeElectronTalon.placeElectronTalons.map((placeElectronTalonHistoryItems: any) => {
                const placeElectronTalonLadminHistory = placeElectronTalonHistoryItems.placeElectronTalonHistoryItems
                  .filter((placeElectronTalonHistoryItem: any) => placeElectronTalonHistoryItem.user != null && placeElectronTalonHistoryItem.userTypeId === '7' &&
                    placeElectronTalonHistoryItem.status === 1)
                return placeElectronTalonLadminHistory
              })[0]
              placeElectronTalon.placeElectronTalonLadminHistoryItem = null
              if (placeElectronTalonLadminHistoryItem.length > 0) {
                placeElectronTalon.placeElectronTalonLadminHistoryItem = placeElectronTalonLadminHistoryItem[0].user.login +
                  ' ' + placeElectronTalonLadminHistoryItem[0].timestamp
              }
            } else {
              // console.log(JSON.stringify(placeElectronTalon))
              placeElectronTalon.discountSales.forEach((userSales: any) => {
                userSales.sales.forEach((sale: any) => {
                  sale.product.placeCustomerCustomerProducts.forEach((sale: any) => {
                    if (sale == null) {
                      console.log('sale id null')
                    }
                    if (sale != null) {
                      placeElectronTalon['product_' + sale.productId]++
                    }
                  })
                })
              })
              placeElectronTalon.itogType = 0
            }
            return placeElectronTalon
          })
          this.removedFromTalonSalesIds = removedFromTalonSalesIds
          this.productHeaders.forEach((element: any) => {
            this.placeElectronTalonList.forEach((el: any) => {
              element.count = element.count + el['product_' + element.productId]
            })
          })
          this.applyFilter()
        }
        this.isLoading = false
        this.loading = false
      }
    },
    nameConvert (name: string): string {
      name = name.substring(0, 1).toUpperCase() + name.substring(1, name.length).toLowerCase()
      return name
    },
    showItogRow (productHeader: any): boolean {
      const isOptionActive = !!this.options.find((option: any) => {
        return option.name.toUpperCase() === productHeader.productName.toUpperCase()
      })?.value
      const hasUserSalesError = !!this.talonErrorsModalData.usersSalesErrorList[productHeader.code]
      return isOptionActive || hasUserSalesError
    },
    itog () {
      this.isTalonErrorsOpen = true
      this.isTalonErrorsLoading = true
      const itogArraySale: any[] = []
      const itogArrayItem: any[] = []
      const itogArrayUchet: any[] = []
      const usersSalesErrorList: any = {}
      this.productHeaders.forEach((element: any) => {
        itogArraySale[element.code] = 0
        itogArrayItem[element.code] = 0
        itogArrayUchet[element.code] = 0
      })
      let countErrorSales = 0
      let countErrorNotNumber = 0
      let withErrors = false
      this.placeElectronTalonList.forEach((element: any) => {
        if (element.itogType === 0) {
          this.productHeaders.forEach((el: any) => {
            itogArraySale[el.code] = itogArraySale[el.code] + element[el.code]
          })
        } else {
          this.productHeaders.forEach((el: any) => {
            itogArrayItem[el.code] = itogArrayItem[el.code] + element[el.code]
          })
        }
        // console.log(itogArrayItem, 'f')
        this.sales.some((saleElement: any) => {
          element.discountSales.forEach((discountSale: any) => {
            if (discountSale.id === saleElement.id) {
              discountSale.saleExists = true
              saleElement.classExists = true
              return true
            }
          })
        })
      })
      this.sales.forEach((saleElement: any) => {
        if (saleElement.classExists !== true) {
          saleElement.sales.forEach((sale: any) => {
            if (+sale.discount > 0) { // Отметаем продажи, сделанные без скидки (т.е. не льготные)
              const productCode = `product_${sale.product.placeCustomerCustomerProducts[0].productId}`
              if (usersSalesErrorList[productCode]) {
                usersSalesErrorList[productCode].push({
                  userFullName: `${saleElement.lastName} ${saleElement.firstName} ${saleElement.middleName}`,
                  saleId: sale.id,
                  productName: `${sale.product.name}`,
                  productCode: `${sale.product.code}`,
                  text: 'Есть в учете, нет в талонах'
                })
              } else {
                usersSalesErrorList[productCode] = [{
                  userFullName: `${saleElement.lastName} ${saleElement.firstName} ${saleElement.middleName}`,
                  saleId: sale.id,
                  productName: `${sale.product.name}`,
                  productCode: `${sale.product.code}`,
                  text: 'Есть в учете, нет в талонах'
                }]
              }
            }
          })
        }
      })
      this.placeElectronTalonList.forEach((element: any) => {
        element.discountSales.forEach((discountSale: any) => {
          if (discountSale.saleExists !== true) {
            discountSale.sales.forEach((sale: any) => {
              const productCode = `product_${sale.product.placeCustomerCustomerProducts[0].productId}`
              const detailedProduct = this.productDetails.find((productDetail: any) => {
                return productDetail.productId === sale.product.placeCustomerCustomerProducts[0].productId
              })
              const productName = detailedProduct.product.name[0].toUpperCase() + detailedProduct.product.name.slice(1).toLowerCase()
              const productCodeName = detailedProduct.product.code
              if (usersSalesErrorList[productCode]) {
                usersSalesErrorList[productCode].push({
                  userFullName: `${discountSale.lastName} ${discountSale.firstName} ${discountSale.middleName}`,
                  saleId: sale.id,
                  productName: `${productName}`,
                  productCode: `${productCodeName}`,
                  text: 'Есть в талонах, нет в учете'
                })
              } else {
                usersSalesErrorList[productCode] = [{
                  userFullName: `${discountSale.lastName} ${discountSale.firstName} ${discountSale.middleName}`,
                  saleId: sale.id,
                  productName: `${productName}`,
                  productCode: `${productCodeName}`,
                  text: 'Есть в талонах, нет в учете'
                }]
              }
            })
          }
        })
      })
      this.sales.forEach((saleElement: any) => {
        if (Array.isArray(saleElement.sales)) {
          saleElement.sales.forEach((sale: any) => {
            if (sale == null) {
              console.log('sale is null')
            }
            if (+sale.discount > 0) {
              let first = true
              // if (element)
              this.productHeaders.forEach((el: any) => {
                // console.log('counter')
                sale.product.placeCustomerCustomerProducts.forEach((el2: any) => {
                  if (el2.productId === el.productId) {
                    first = false
                    if (!isNaN(Number(sale.quantity))) {
                      // console.log(el.code === 'product_3' ?'counter2': 0)
                      if (this.removedFromTalonSalesIds[sale.id]) {
                        // console.log('counter3')
                        itogArraySale[el.code] += 1
                        if (usersSalesErrorList[el.code]) {
                          usersSalesErrorList[el.code].push({
                            userFullName: `${saleElement.lastName} ${saleElement.firstName} ${saleElement.middleName}`,
                            saleId: sale.id,
                            productName: `${sale.product.name}`,
                            productCode: `${sale.product.code}`,
                            text: 'Есть в учете, нет в талонах'
                          })
                        } else {
                          usersSalesErrorList[el.code] = [{
                            userFullName: `${saleElement.lastName} ${saleElement.firstName} ${saleElement.middleName}`,
                            saleId: sale.id,
                            productName: `${sale.product.name}`,
                            productCode: `${sale.product.code}`,
                            text: 'Есть в учете, нет в талонах'
                          }]
                        }
                      }
                      itogArrayUchet[el.code] = itogArrayUchet[el.code] + Number(sale.quantity)
                    } else {
                      withErrors = true
                      countErrorNotNumber++
                    }
                  }
                })
              })
              if (first) {
                withErrors = true
                countErrorSales++
              }
            }
          })
        }
      })
      // console.log(itogArrayUchet, itogArrayItem, 'xd')
      this.talonErrorsModalData.usersSalesErrorList = usersSalesErrorList
      this.talonErrorsModalData.itogArrayItem = itogArrayItem
      this.talonErrorsModalData.itogArraySale = itogArraySale
      this.talonErrorsModalData.itogArrayUchet = itogArrayUchet
      if (withErrors) {
        this.talonErrorsModalData.withErrors = true
        if (countErrorSales > 0) {
          this.talonErrorsModalData.countErrorSales = countErrorSales
        }
        if (countErrorNotNumber > 0) {
          this.talonErrorsModalData.countErrorNotNumber = countErrorNotNumber
        }
      }
      this.isTalonErrorsLoading = false
    },
    async openTalonDetail (errors: any) {
      this.isTalonErrorsByClassOpen = true
      this.isTalonErrorsByClassLoading = true
      this.talonErrorsDetailModalData.title = 'Расхождения между данными талонов и учета'

      // Конвертация и формирование таблицы
      const res: any = {
        columnsCount: 4,
        rowsCount: 0,
        items: []
      }

      res.items.push(
        {
          text: 'ФИО',
          background: 'none',
          fontWeight: 'bold',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 1,
          gridColumnEnd: 2,
          padding: '0 5px',
          border: 'none',
          fontFamily: '"Roboto", sans-serif',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px'
        },
        {
          text: 'Класс',
          background: 'none',
          fontWeight: 'bold',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 2,
          gridColumnEnd: 3,
          padding: '0 5px',
          border: 'none',
          fontFamily: '"Roboto", sans-serif',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px'
        },
        {
          text: 'Рацион',
          background: 'none',
          fontWeight: 'bold',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 3,
          gridColumnEnd: 4,
          padding: '0 5px',
          border: 'none',
          fontFamily: '"Roboto", sans-serif',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px'
        },
        {
          text: 'Причина',
          background: 'none',
          fontWeight: 'bold',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 4,
          gridColumnEnd: 5,
          padding: '0 5px',
          border: 'none',
          fontFamily: '"Roboto", sans-serif',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '14px'
        }
      )

      let currentRow = 2
      for (const error of errors) {
        let placeInfo = ''
        try {
          const res = await userPlaceInfoBySaleId({ id: error.saleId })
          placeInfo = `${res.className} (${res.schoolName})`
        } catch (e) {
          placeInfo = 'Ошибка...'
        }

        res.items.push(
          {
            text: `${error.userFullName}`,
            background: 'none',
            fontWeight: 'normal',
            gridRowStart: currentRow,
            gridRowEnd: currentRow + 1,
            gridColumnStart: 1,
            gridColumnEnd: 2,
            padding: '0 10px',
            border: 'none',
            fontFamily: '"Roboto", sans-serif',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '14px'
          },
          {
            text: `${placeInfo}`,
            background: 'none',
            fontWeight: 'normal',
            gridRowStart: currentRow,
            gridRowEnd: currentRow + 1,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            padding: '0 10px',
            border: 'none',
            fontFamily: '"Roboto", sans-serif',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '14px'
          },
          {
            text: `${error.productName}`,
            background: 'none',
            fontWeight: 'normal',
            gridRowStart: currentRow,
            gridRowEnd: currentRow + 1,
            gridColumnStart: 3,
            gridColumnEnd: 4,
            padding: '0 10px',
            border: 'none',
            fontFamily: '"Roboto", sans-serif',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '14px'
          },
          {
            text: `${error.text}`,
            background: 'none',
            fontWeight: 'normal',
            gridRowStart: currentRow,
            gridRowEnd: currentRow + 1,
            gridColumnStart: 4,
            gridColumnEnd: 5,
            padding: '0 10px',
            border: 'none',
            fontFamily: '"Roboto", sans-serif',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '14px'
          }
        )
        currentRow += 1
      }
      res.rowsCount = currentRow + 1
      this.talonErrorsDetailModalData.tableData = res
      this.isTalonErrorsByClassLoading = false
    }
  },
  mounted () {
    this.loading = false
  }
})
</script>

<style lang='scss'>
.talon-register-options__table {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  //grid-template-rows: repeat(5, max-content);
  grid-gap: 1px;

  .talon-register-options__table-header {
    background: #1b5e20;
    color: white;
    font-size: 16px;
    grid-column-start: 1 !important;
    grid-column-end: 3 !important;
    box-shadow: 0 0 0 1px #808080;
    padding: 5px;
  }

  .talon-register-options__header-cell {
    box-shadow: 0 0 0 1px #808080;
    padding: 5px;
    background: #faebd7;
    font-weight: bold;
    font-size: 16px;
  }

  .talon-register-options__cell {
    box-shadow: 0 0 0 1px black;
    padding: 5px;
    font-weight: normal;
  }
}
</style>
