<template>
  <div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="control_panel">
          <div class="info_panel">
            <a
              href="https://school.glolime.ru/new_site/telegram/instructions/glolime-food/"
              target="_blank" rel="noopener noreferrer"
              style="display: flex; flex-direction: row; gap: 5px; padding: 8px"
            >
              <h4
                class="button-text-roboto-medium"
                style="text-align: center; align-self: center"
              >
                О подписке
              </h4>
              <img
                src="@/assets/images/question.png"
                height="26"
                width="26"
                class="white-png"
              >
            </a>
            <button
              class="primary-green-button subscription-btn"
            >
              <h5 class="button-text-roboto-medium">
                Оформить подписку
              </h5>
            </button>
          </div>
        </div>
        <div
          class="control_panel"
          style="margin-top: 20px"
        >
          <div class="info_panel">
            <div>
              <h4 class="title-h4-roboto-medium">
                Подписка действительна до<br> 31 декабря, 2024, 12:00
              </h4>
            </div>
            <button
              class="secondary-white-button subscription-btn"
              style="width: 40px; height: 40px; border: 1px solid #ebeaec;"
              @click="goToSettings"
            >
              <img
                src="@/assets/images/setting.png"
                height="24"
                width="24"
              >
            </button>
          </div>
          <div style="display: flex; flex-direction: row; gap: 18px;">
            <button
              class="secondary-white-button subscription-btn"
              @click="openTimersCard"
            >
              <h5 class="button-text-roboto-medium">
                Таймеры контроля питания
              </h5>
            </button>
            <v-dialog
              v-model="showTimersCard"
              width="350px"
              light:true
              :retain-focus="false"
            >
              <v-card class="timer_card">
                <v-icon
                  :style="{top: '9%', left: '95%' }"
                  @click="openTimersCard"
                >
                  mdi-close
                </v-icon>
                <h3 class="title-h3-roboto-medium">
                  Настройка таймеров контроля питания
                </h3>
                <h4 class="body-text-roboto-medium">
                  Вы можете настроить 3 таймера мониторинга по своему усмотрению.
                </h4>
                <v-row class="card_row">
                  <div style="display: flex; flex-direction: column; gap: 15px">
                    <v-select
                      v-model="selectedNutritionTimers[0]"
                      variant="solo"
                      density="compact"
                      hide-details="true"
                      label="Выберите тип питания"
                      :style="{width: '170px'}"
                      :items="nutritionItems"
                    />
                    <v-select
                      v-model="selectedCheckTimers[0]"
                      variant="solo"
                      density="compact"
                      hide-details="true"
                      label="Выберите тип проверки"
                      :style="{width: '170px'}"
                      :items="checkItems"
                    />
                  </div>
                  <v-text-field
                    v-model="time"
                    class="timer_input"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    variant="solo"
                    hide-details="true"
                    density="compact"
                  />
                </v-row>
                <v-row class="card_row">
                  <div style="display: flex; flex-direction: column; gap: 10px">
                    <v-select
                      v-model="selectedNutritionTimers[1]"
                      variant="solo"
                      density="compact"
                      hide-details="true"
                      label="Выберите тип питания"
                      :style="{width: '170px'}"
                      :items="nutritionItems"
                    />
                    <v-select
                      v-model="selectedCheckTimers[1]"
                      variant="solo"
                      density="compact"
                      hide-details="true"
                      label="Выберите тип проверки"
                      :style="{width: '170px'}"
                      :items="checkItems"
                    />
                  </div>
                  <v-text-field
                    v-model="time"
                    class="timer_input"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    variant="solo"
                    hide-details="true"
                    density="compact"
                  />
                </v-row>
                <v-row class="card_row">
                  <div style="display: flex; flex-direction: column; gap: 10px">
                    <v-select
                      v-model="selectedNutritionTimers[2]"
                      variant="solo"
                      density="compact"
                      hide-details="true"
                      label="Выберите тип питания"
                      :style="{width: '170px'}"
                      :items="nutritionItems"
                    />
                    <v-select
                      v-model="selectedCheckTimers[2]"
                      variant="solo"
                      density="compact"
                      hide-details="true"
                      label="Выберите тип проверки"
                      :style="{width: '170px'}"
                      :items="checkItems"
                    />
                  </div>
                  <v-text-field
                    v-model="time"
                    class="timer_input"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    variant="solo"
                    hide-details="true"
                    density="compact"
                  />
                </v-row>
                <v-row style="display: grid; justify-content: center; padding: 12px">
                  <button class="primary-green-button" style="align-self: center">
                    Сохранить
                  </button>
                </v-row>
              </v-card>
            </v-dialog>
            <button
              class="secondary-white-button subscription-btn"
              @click="openNotificationsCard"
            >
              <h5 class="button-text-roboto-medium">
                Уведомления
              </h5>
            </button>
            <v-dialog
              v-model="showNotificationsCard"
              width="350px"
              light:true
              :retain-focus="false"
            >
              <v-card class="timer_card">
                <v-icon
                  :style="{top: '9%', left: '95%' }"
                  @click="openNotificationsCard"
                >
                  mdi-close
                </v-icon>
                <h3 class="title-h3-roboto-medium">
                  Настройка уведомлений
                </h3>
                <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 14px">
                  <h4
                    class="body-text-roboto-medium"
                    style="align-self: center"
                  >
                    Установка уведомления о пороге баланса
                  </h4>
                  <div style="display: flex; flex-direction: row; gap: 12px">
                    <h4
                      class="body-text-roboto-medium"
                      style="align-self: center; margin-bottom: 5px"
                    >
                      Сумма
                    </h4>
                    <v-text-field
                      density="compact"
                      variant="solo"
                      hide-details="true"
                      :style="{width: '80px'}"
                    />
                  </div>
                </div>
                <div style="display: grid; justify-content: center; padding: 12px">
                  <button class="primary-green-button" style="align-self: center">
                    Сохранить
                  </button>
                </div>
              </v-card>
            </v-dialog>
          </div>
          <div style="display: flex; flex-direction: row; gap: 18px;">
            <button
              class="secondary-white-button subscription-btn"
              @click="goToAutoPayment"
            >
              <h5 class="button-text-roboto-medium">
                Настройка платежей
              </h5>
            </button>
            <button
              class="secondary-white-button subscription-btn"
            >
              <h5 class="button-text-roboto-medium">
                История платежей
              </h5>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Person } from '@/store/user/types'

export default defineComponent({
  name: 'ParentsSubscription',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      nutritionItems: [{title: 'Абонемент', value: 1}, {title: 'Горячее', value: 2}, {title: 'Горячее и буфет', value: 3}],
      selectedNutritionTimers: [ {title: '', value: 0}, {title: '', value: 0}, {title: '', value: 0}],
      checkItems: [{title: 'По времени', value: 1}, {title: 'По диапазону', value: 2}],
      selectedCheckTimers: [{title: '', value: 0}, {title: '', value: 0}, {title: '', value: 0}],
      isTelegram: false,
      isLoading: false,
      showTimersCard: false,
      showNotificationsCard: false,
      time: null,
      timerMenu: false
    }
  },
  methods: {
    openTimersCard () {
      this.showTimersCard = !this.showTimersCard
    },
    openNotificationsCard () {
      this.showNotificationsCard = !this.showNotificationsCard
    },
    goToSinglePayment () {
      this.$router.push({name: 'ParentsSubscriptionSinglePayment'})
    },
    goToAutoPayment () {
      this.$router.push({name: 'ParentsSubscriptionAutoPaymentSettings'})
    },
    goToSettings () {
      this.$router.push({name: 'ParentsSubscriptionSettings'})
    }
  }
})
</script>
<style scoped lang="scss">
@import "@/app/styles/uikit-variables";

.control_panel {
  width: 700px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.info_panel {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.subscription-btn {
  text-align: center;
  width: 320px;
  padding: 13.5px
}
.timer_card {
  padding: 10px 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.card_row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 15px;
  padding: 12px;
  border-bottom: 1px solid #ebeaec;
}
.timer_input  {
  align-self: center;
  max-width: 130px;
  min-height: 60px;
  border-radius: 5px;
}
.white-png {
  filter: grayscale(100%);
  align-self: center
}
@media (max-aspect-ratio: 6/5) {
  .control_panel {
    width: 100%;
  }
  .subscription-btn {
    //text-align: left;
    padding: 8px;
    width: 160px;
  }
}
</style>
