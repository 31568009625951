import { createRouter, createWebHistory, RouteMeta, RouteRecordRaw } from 'vue-router'
import jwtDecode from 'jwt-decode'
import { store } from '@/app/providers'
import Home from '@/views/base/Home.vue'
// Ladmin Routes
import Table from '@/views/tables/Table.vue'
import TalonRegister from '@/views/talons/TalonRegister.vue'
import TalonEdit from '@/views/talons/TalonEdit.vue'
import TalonRationEdit from '@/views/talons/TalonRationEdit.vue'
import Prices from '@/views/prices/Register.vue'
import RatePlanPrices from '@/views/rateplanprices/Register.vue'
import Users from '@/views/users/UserList.vue'
import UserEdit from '@/views/users/User.vue'
import UserTransactions from '@/views/users/TransactionList.vue'
import AddChilds from '@/views/talons/AddChilds.vue'
import Nutrition from '@/views/nutrition/Nutrition.vue'
import NutritionClassHeads from '@/views/nutrition/NutritionClassHeads.vue'
import NutritionAltClassHeads from '@/views/nutrition/NutritionAltClassHeads.vue'
// Telegramm Bot
import TelegrammReport from '@/views/telegrambot/TelegrammReport.vue'
import TelegramAutoPaymentServiceSettings from '@/views/telegrambot/TelegramAutoPaymentServiceSettings.vue'
import TelegramPaymentServiceSettings from '@/views/telegrambot/TelegramPaymentServiceSettings.vue'
import TelegramPaymentService from '@/views/telegrambot/TelegramPaymentService.vue'
import TelegramSupportService from '@/views/telegrambot/TelegramSupportService.vue'
import TelegramSupportDetails from '@/views/telegrambot/TelegramSupportDetails.vue'
import TelegramSupportTicketHistory from '@/views/telegrambot/TelegramSupportTicketHistory.vue'
import TelegramSinglePayment from '@/views/telegrambot/TelegramSinglePayment.vue'
import TelegramCheck from '@/views/telegrambot/TelegramCheck.vue'
import TestSbp from '@/views/telegrambot/TestSbp.vue'
// Reports
import ReportSettings from '@/views/reports/settings/ByDay.vue'
import FoodWarehousesSettings from '@/views/reports/settings/FoodWarehouses.vue'
import ReportRatePlanDay from '@/views/reports/rateplan/Day.vue'
import ReportTalonByRatePlan from '@/views/reports/rateplan/TalonByRatePlan.vue'
import ReportRatePlanDayRatePlan from '@/views/reports/rateplan/DayRatePlan.vue'
import ReportRatePlanDayRatePlanByAge from '@/views/reports/rateplan/DayRatePlanByAge.vue'
import ReportRatePlanDayRatePlanByAgeV2 from '@/views/reports/rateplan/DayRatePlanByAgeV2.vue'
import ReportSalesByProducts from '@/views/reports/sales/ByProduct.vue'
import ReportSalesByProductsPrint from '@/views/reports/sales/ByProductPrint.vue'
import ReportSalesByProductsDetail from '@/views/reports/sales/ByProductDetail.vue'
import ReportTalonsByDistricts from '@/views/reports/talons/ByDistrict.vue'
import ReportSalesByCustomerProducts from '@/views/reports/sales/ByCustomerProduct.vue'
import ReportSalesByPlaces from '@/views/reports/sales/ByPlace.vue'
import ReportActsDay from '@/views/reports/acts/Day.vue'
import ReportActsMonth from '@/views/reports/acts/Month.vue'
import ReportPlaceCounters from '@/views/reports/placecounters/Report.vue'
import ReportByFoodWarehouses from '@/views/reports/foodWarehouses/ByMonth.vue'
import ReportByTalonErrors from '@/views/reports/talons/Errors.vue'
// Tables
import MinorTable from '@/views/tables/Minor.vue'
import ByProductPerMonthTable from '@/views/tables/ByProductPerMonthTable.vue'
import ByStudentAndCategoryPerMonthTable from '@/views/tables/ByStudentAndCategoryPerMonthTable.vue'
import ConsolidatedPerMonthTable from '@/views/tables/ConsolidatedPerMonthTable.vue'
// Preorders
import PreorderReestr from '@/views/preorders/PreorderReestr.vue'
import PreorderDetail from '@/views/preorders/PreorderDetail.vue'
import PreorderAdd from '@/views/preorders/PreorderAdd.vue'
import PreorderAddDetail from '@/views/preorders/PreorderAddDetail.vue'
import PreorderProduct from '@/views/preorders/PreorderProduct.vue'

// Device Routes
import DeviceList from '@/views/administration/DeviceList.vue'
import Devices from '@/views/administration/Devices.vue'
import IncrementGroupDashboard from '@/views/administration/IncrementGroupDashboard.vue'
import CreateIncrementGroup from '@/views/administration/CreateIncrementGroup.vue'
import EditIncrementGroup from '@/views/administration/EditIncrementGroup.vue'
import CardErrorLogs from '@/views/administration/CardErrorLogs.vue'
// User Routes
import Login from '@/views/base/Login.vue'
// Other
import NotFound from '@/views/errors/NotFound.vue'
import { JwtDecodeData } from '@/store/user/types'
import SchoolNutritionSettings from '@/views/settings/SchoolNutritionSettings.vue'
import StudentNutritionSettings from '@/views/settings/StudentNutritionSettings.vue'
import PlaceRatePlans from '@/views/settings/PlaceRatePlans.vue'
import BalanceOfServices from '@/views/reports/BalanceOfServices.vue'
import BidSettings from '@/views/settings/BidSettings.vue'
import BankTransactionsSummary from '@/views/reports/BankTransactionsSummary.vue'
import BankTransactionsDetail from '@/views/reports/BankTransactionsDetail.vue'
import PreorderForTommorow from '@/views/parents/preorders/PreorderForTommorow.vue'
import PreordersList from '@/views/parents/preorders/PreordersList.vue'
import PreordersChooseRation from '@/views/parents/preorders/PreordersChooseRation.vue'
import TelegramLogin from '@/views/parents/telegramLogin/TelegramLogin.vue'

// New Design
import TelegramLoginNew from '@/views/newDesign/parents/telegramLogin/TelegramLogin.vue'
import PreorderForTomorrowNew from '@/views/newDesign/parents/preorders/PreorderForTommorow.vue'
import PreordersListNew from '@/views/newDesign/parents/preorders/PreordersList.vue'
import PreordersChooseRationNew from '@/views/newDesign/parents/preorders/PreordersChooseRation.vue'
import ParentsInfo from '@/views/newDesign/parents/info/Info.vue'
import ParentsMoneyAccounting from '@/views/newDesign/parents/moneyAccounting/MoneyAccounting.vue'
import ParentsMyChildren from '@/views/newDesign/parents/myChildren/MyChildren.vue'
import ParentsAutoTransfer from '@/views/newDesign/parents/automaticTransfer/AutoTransfer.vue'
import ParentsHome from '@/views/newDesign/parents/home/Home.vue'
import ParentsSchoolAttendance from '@/views/newDesign/parents/schoolAttendance/SchoolAttendance.vue'
import ParentsSettingLimits from '@/views/newDesign/parents/moneyAccounting/SettingLimits.vue'
import ParentsTransferBetweenAccounts from '@/views/newDesign/parents/moneyAccounting/TransferBetweenAccounts.vue'
import ParentsTransferBetweenChildren from '@/views/newDesign/parents/moneyAccounting/TransferBetweenChildren.vue'
import ParentsSettings from '@/views/newDesign/parents/settings/Settings.vue'
import ParentsNotAllowed from '@/views/newDesign/parents/notAllowed/NotAllowed.vue'
import ParentsAttendanceReport from '@/views/newDesign/parents/attendanceReport/AttendanceReport.vue'
import ParentsSinglePayments from '@/views/newDesign/parents/singlePayment/SinglePaymentBad.vue'
import ParentSinglePayment from '@/views/newDesign/parents/singlePayment/SinglePayment.vue'
import ParentsDigitalReceipts from '@/views/newDesign/parents/digitalReceipts/DigitalReceipts.vue'
import ParentsNews from '@/views/newDesign/parents/news/News.vue'
import ParentsTransaction from '@/views/newDesign/parents/moneyAccounting/Transaction.vue'
import ParentsSubscription from '@/views/newDesign/parents/moneyAccounting/subscription/Subscription.vue'
import ParentsSubscriptionSinglePayment from '@/views/newDesign/parents/moneyAccounting/subscription/SubscriptionSinglePayment.vue'
import ParentsSubscriptionAutoPaymentSettings from '@/views/newDesign/parents/moneyAccounting/subscription/SubscriptionAutoPaymentSettings.vue'
import ParentsSubscriptionSettings from '@/views/newDesign/parents/moneyAccounting/subscription/SubscriptionSettings.vue'
import UIKit from '@/app/styles/UITest.vue'
import TelegramNFCTry from '@/views/nfcTest/TelegramNFCTry.vue'

// Vue.use(VueRouter)

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Страница не найдена', partialType: 'meta' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Главная', partialType: 'full' }
  },
  {
    path: '/devices/cardlogs/',
    name: 'CardErrorLogs',
    component: CardErrorLogs,
    meta: { title: 'Логи по картам', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/devices/incrementgroups/',
    name: 'DeviceList',
    component: DeviceList,
    meta: { title: 'Настройка инкрементального обновления', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/devices/',
    name: 'Devices',
    component: Devices,
    meta: { title: 'Список устройств', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/devices/incrementgroups/dashboard/',
    name: 'IncrementGroupDashboard',
    component: IncrementGroupDashboard,
    meta: { title: 'Настройка групп инкрементальных обновлений', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/devices/incrementgroups/create-increment-group/',
    name: 'CreateIncrementGroup',
    component: CreateIncrementGroup,
    meta: { title: 'Создание группы', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/devices/incrementgroups/edit-increment-group/:id',
    name: 'EditIncrementGroup',
    component: EditIncrementGroup,
    meta: { title: 'Редактирование группы', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/reports/balanceofservices',
    name: 'BalanceOfServisec',
    component: BalanceOfServices,
    meta: { title: 'Редактирование группы', requiresAuth: true, partialType: 'full', betaComponent: true },
    props: true
  },

  {
    path: '/table/',
    name: 'Table',
    component: Table,
    meta: { title: 'Табель', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/talons/',
    name: 'TalonRegister',
    component: TalonRegister,
    meta: { title: 'Электронные талоны', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/talons/edit/:id:date:placeId:selectedPlaceItemId',
    name: 'TalonEdit',
    component: TalonEdit,
    meta: { title: 'Электронные талоны редактирование', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/talons/edit/addchilds/:id:date:placeId:selectedPlaceItemId',
    name: 'AddChilds',
    component: AddChilds,
    meta: { title: 'Электронные талоны добавление детей ', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/talons/ration/edit/:id:date:placeId:selectedPlaceItemId',
    name: 'TalonRationEdit',
    component: TalonRationEdit,
    meta: { title: 'Рационы редактирование', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/prices/',
    name: 'Prices',
    component: Prices,
    meta: { title: 'Цены', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/rateplanprices/',
    name: 'RatePlanPrices',
    component: RatePlanPrices,
    meta: { title: 'Льготные цены', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/users/',
    name: 'Users',
    component: Users,
    meta: { title: 'Список пользователей', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/users/edit/:id',
    name: 'UserEdit',
    component: UserEdit,
    meta: { title: 'Пользователь', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/users/transactions/:userId',
    name: 'UserTransactions',
    component: UserTransactions,
    meta: { title: 'Выписка', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/settings/',
    name: 'ReportSettings',
    component: ReportSettings,
    meta: { title: 'Настройка отчета', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/foodwarehousessettings/',
    name: 'FoodWarehousesSettings',
    component: FoodWarehousesSettings,
    meta: { title: 'Настройка отчета', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/ratePlan/day/',
    name: 'ReportRatePlanDay',
    component: ReportRatePlanDay,
    meta: { title: 'Питание льготников по датам', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/ratePlan/dayrateplan/',
    name: 'ReportRatePlanDayRatePlan',
    component: ReportRatePlanDayRatePlan,
    meta: { title: 'Питание льготников по датам и льготным категориям', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/ratePlan/dayrateplanbyage/',
    name: 'ReportRatePlanDayRatePlanByAge',
    component: ReportRatePlanDayRatePlanByAge,
    meta: { title: 'Питание льготников по возрастным категориям', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/ratePlan/dayrateplanbyagev2/',
    name: 'ReportRatePlanDayRatePlanByAgeV2',
    component: ReportRatePlanDayRatePlanByAgeV2,
    meta: { title: 'Питание льготников по возрастным категориям', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/ratePlan/talonbyrateplan/',
    name: 'ReportTalonByRatePlan',
    component: ReportTalonByRatePlan,
    meta: { title: 'Питание льготников по датам и льготным категориям', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/acts/day/',
    name: 'ReportActsDay',
    component: ReportActsDay,
    meta: { title: 'Акт реализации талонов на дату', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/acts/month/',
    name: 'ReportActsMonth',
    component: ReportActsMonth,
    meta: { title: 'Акт реализации талонов за период', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/talons/bydistricts/',
    name: 'ReportTalonsByDistricts',
    component: ReportTalonsByDistricts,
    meta: { title: 'Отчет по талонам', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/sales/byproducts/',
    name: 'ReportSalesByProducts',
    component: ReportSalesByProducts,
    meta: { title: 'Продажи по блюдам', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/sales/byproductsprint/',
    name: 'ReportSalesByProductsPrint',
    component: ReportSalesByProductsPrint,
    meta: { title: 'Продажи по блюдам (печать)', requiresAuth: true, partialType: 'meta', betaComponent: true },
    props: true
  },
  {
    path: '/report/sales/byproducts/detail/',
    name: 'ReportSalesByProductsDetail',
    component: ReportSalesByProductsDetail,
    meta: { title: 'Продажи по блюду', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/sales/byplaces/',
    name: 'ReportSalesByPlaces',
    component: ReportSalesByPlaces,
    meta: { title: 'Свернутый', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/sales/bycustomerproducts/',
    name: 'ReportSalesByCustomerProducts',
    component: ReportSalesByCustomerProducts,
    meta: { title: 'Продажи по реестру блюд', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/salesturnover/',
    name: 'ReportSalesTurnover',
    component: () => import('@/views/reports/SalesTurnoverReport.vue'),
    meta: { title: 'Расчет оборота', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/salesregister/',
    name: 'ReportSalesRegister',
    component: () => import('@/views/reports/SalesRegisterReport.vue'),
    meta: { title: 'Реестр платежей', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/sbpincomingregister/',
    name: 'SbpIncomingRegister',
    component: () => import('@/views/reports/SbpIncomingRegisterReport.vue'),
    meta: { title: 'Входящие реестры СБП', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/sbporders/',
    name: 'SbpOrders',
    component: () => import('@/views/reports/SbpOrders.vue'),
    meta: { title: 'Платежи СБП', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/table/byproductpermonth/',
    name: 'ByProductPerMonthTable',
    component: ByProductPerMonthTable,
    meta: { title: 'Табель по продуктам за месяц', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/table/bystudentandcategorypermonth/',
    name: 'ByStudentAndCategoryPerMonthTable',
    component: ByStudentAndCategoryPerMonthTable,
    meta: { title: 'Табель по ученикам и категориям за месяц', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/table/consolidatedpermonth/',
    name: 'ConsolidatedPerMonthTable',
    component: ConsolidatedPerMonthTable,
    meta: { title: 'Табель сводный за месяц', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/table/okud/minor/',
    name: 'MinorTable',
    component: MinorTable,
    meta: { title: 'Табель по ОКУД начальные', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/foodWarehouses/',
    name: 'ReportByFoodWarehouses',
    component: ReportByFoodWarehouses,
    meta: { title: 'Охват питания УСП', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/talonerrors/',
    name: 'ReportByTalonErrors',
    component: ReportByTalonErrors,
    meta: { title: 'Проверка талонов', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/preorders/bydate/',
    name: 'ReportPreordersByDate',
    component: ReportSalesByCustomerProducts,
    meta: { title: 'Продажи по реестру блюд', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/placeCounters/',
    name: 'ReportPlaceCounters',
    component: ReportPlaceCounters,
    meta: { title: 'По ЕКШ', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/consolidatedbycompensationtype/',
    name: 'СonsolidatedByCompensationType',
    component: () => import('@/views/reports/ConsolidatedByCompensationType.vue'),
    meta: { title: 'Сводный по видам компенсации', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/report/preorders/bydays/',
    name: 'ReportPreordersByDays',
    component: () => import('@/views/reports/preorders/ByDays.vue'),
    meta: { title: 'Предзаказы по дням', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/preorder/reestr/',
    name: 'PreorderReestr',
    component: PreorderReestr,
    meta: { title: 'Реестр предварительных заявок', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/preorder/detail/',
    name: 'PreorderDetail',
    component: PreorderDetail,
    meta: { title: 'Реестр предварительных заявок подробно', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/preorder/add/',
    name: 'PreorderAdd',
    component: PreorderAdd,
    meta: { title: 'Предварительная заявка', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/preorder/add/detail/',
    name: 'PreorderAddDetail',
    component: PreorderAddDetail,
    meta: { title: 'Заявка корректировка', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/preorder/products/',
    name: 'PreorderProduct',
    component: PreorderProduct,
    meta: { title: 'Рационы', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/schoolnutritionsettings/',
    name: 'SchoolNutritionSettings',
    component: SchoolNutritionSettings,
    meta: { title: 'Схема питания', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/placerateplans/',
    name: 'PlaceRatePlans',
    component: PlaceRatePlans,
    meta: { title: 'Льготные рационы', requiresAuth: true, partialType: 'full' },
    props: true
  },
  {
    path: '/studentnutritionsettings/',
    name: 'StudentNutritionSettings',
    component: StudentNutritionSettings,
    meta: { title: 'Настройки питания по ученику', requiresAuth: true, partialType: 'full', betaComponent: true },
    props: true
  },
  {
    path: '/bidsettings/',
    name: 'BidSettings',
    component: BidSettings,
    meta: { title: 'Настройка заявки', requiresAuth: true, partialType: 'full', betaComponent: true },
    props: true
  },
  {
    path: '/banktransactionssummary/',
    name: 'BankTransactionsSummary',
    component: BankTransactionsSummary,
    meta: { title: 'Реестр банковских транзакций', requiresAuth: true, partialType: 'full', betaComponent: true },
    props: true
  },
  {
    path: '/banktransactionsdetail/:id/',
    name: 'BankTransactionDetail',
    component: BankTransactionsDetail,
    meta: {
      title: 'Детальный отчет по транзакциям банка',
      requiresAuth: true,
      partialType: 'full',
      betaComponent: true
    },
    props: true
  },
  {
    path: '/nutrition/',
    name: 'Nutrition',
    component: Nutrition,
    meta: { title: 'Питание', requiresAuth: false, partialType: 'full' },
    props: true,
    children: [
      {
        path: 'classheads',
        name: 'NutritionClassHeads',
        component: NutritionClassHeads,
        meta: {
          title: 'Питание - Назначение остветственных - Классные руководители',
          requiresAuth: false,
          partialType: 'full'
        },
        props: true
      },
      {
        path: 'altclassheads',
        name: 'NutritionAltClassHeads',
        component: NutritionAltClassHeads,
        meta: { title: 'Питание - Назначение заместителей без права подписи', requiresAuth: true, partialType: 'full' },
        props: true
      }
    ]
  },
  // User
  {
    path: '/user/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Вход', partialType: 'full' }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../../views/base/About.vue')
  },
  {
    path: '/user/loginbytoken',
    name: 'LoginByToken',
    meta: { title: 'Вход по токену', partialType: 'full' },
    component: null,
    children: []
  },
  {
    path: '/telegrammreport/',
    name: 'TelegrammReport',
    component: TelegrammReport,
    meta: { title: 'Горячая линия', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/tg/apset/:tokenProp',
    name: 'TelegramAutoPaymentServiceSettings',
    component: TelegramAutoPaymentServiceSettings,
    meta: { title: 'Настройка автоплатежа телеграмм-бота', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/supstat/:tokenProp',
    name: 'TelegramSupportService',
    component: TelegramSupportService,
    meta: { title: 'Статистика по операторам', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/supdet/:tokenProp',
    name: 'TelegramSupportDetails',
    component: TelegramSupportDetails,
    meta: { title: 'Детализация по заявке', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/suptickethistory/:tokenProp',
    name: 'TelegramSupportTicketHistory',
    component: TelegramSupportTicketHistory,
    meta: { title: 'История по заявке', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/pset/:tokenProp',
    name: 'TelegramPaymentServiceSettings',
    component: TelegramPaymentServiceSettings,
    meta: { title: 'Подписка', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/pser/:tokenProp',
    name: 'TelegramPaymentService',
    component: TelegramPaymentService,
    meta: { title: 'Оплата', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/check/:typeProp&:idProp',
    name: 'TelegramCheck',
    component: TelegramCheck,
    meta: { title: 'Чек', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/testspb/:t',
    name: 'TestSbp',
    component: TestSbp,
    meta: { title: 'TestSbp', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/tg/singlepay/:tokenProp',
    name: 'TelegramSinglePayment',
    component: TelegramSinglePayment,
    meta: { title: 'Оплата', requiresAuth: false, partialType: 'telegram' },
    props: true
  },
  {
    path: '/oldsite/',
    name: 'OldSite',
    meta: { title: 'Старый сайт', partialType: 'full' },
    component: null,
    children: []
  },
  {
    path: '/parents/telegramLogin/:tgParams',
    name: 'TelegramLogin',
    component: TelegramLogin,
    meta: { title: 'TelegramLogin', requiresAuth: false, partialType: 'telegram', betaComponent: true }
  },
  {
    path: '/parents/telegramLoginNew/:tgParams',
    name: 'TelegramLoginNew',
    component: TelegramLoginNew,
    meta: { title: 'TelegramLogin', requiresAuth: false, partialType: 'telegram', betaComponent: true }
  },
  {
    path: '/parents/preorders/desktop/5',
    name: 'PreorderForTommorowDesktop',
    component: PreorderForTommorow,
    meta: { title: 'PreorderForTommorowDesktop', requiresAuth: true, partialType: 'full' }
  },
  {
    path: '/parents/preordersNew/5',
    name: 'PreorderForTommorowNew',
    component: PreorderForTomorrowNew,
    meta: { title: 'PreorderForTommorow', requiresAuth: true, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/preorders/5',
    name: 'PreorderForTommorow',
    component: PreorderForTommorow,
    meta: { title: 'PreorderForTommorow', requiresAuth: true, partialType: 'telegram', betaComponent: true }
  },
  {
    path: '/parents/preorders/6',
    name: 'PreordersList',
    component: PreordersList,
    meta: { title: 'PreordersList', requiresAuth: false, partialType: 'telegram', betaComponent: true }
  },
  {
    path: '/parents/preordersNew/6',
    name: 'PreordersListNew',
    component: PreordersListNew,
    meta: { title: 'PreordersList', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/preorders/desktop/6',
    name: 'PreordersListDesktop',
    component: PreordersList,
    meta: { title: 'PreordersList', requiresAuth: false, partialType: 'full' }
  },
  {
    path: '/parents/preordersNew/7',
    name: 'PreordersChooseRationNew',
    component: PreordersChooseRationNew,
    meta: { title: 'PreordersChooseRation', requiresAuth: true, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/preorders/7',
    name: 'PreordersChooseRation',
    component: PreordersChooseRation,
    meta: { title: 'PreordersChooseRation', requiresAuth: true, partialType: 'telegram', betaComponent: true }
  },
  {
    path: '/parents/preorders/desktop/7',
    name: 'PreordersChooseRationDesktop',
    component: PreordersChooseRation,
    meta: { title: 'PreordersChooseRation', requiresAuth: true, partialType: 'full' }
  },
  // {
  //   path: '/parents/preorders/9',
  //   name: 'PreordersPriceList',
  //   component: PreordersPriceList,
  //   meta: { title: 'PreordersPriceList', requiresAuth: false, partialType: 'telegram' }
  // },
  // {
  //   path: '/parents/preorders/desktop/9',
  //   name: 'PreordersPriceList',
  //   component: PreordersPriceList,
  //   meta: { title: 'PreordersPriceList', requiresAuth: false, partialType: 'full' }
  // },
  {
    path: '/parents/info',
    name: 'ParentsInfo',
    component: ParentsInfo,
    meta: { title: 'Лк родителя - Информация', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/moneyAccounting',
    name: 'ParentsMoneyAccounting',
    component: ParentsMoneyAccounting,
    meta: { title: 'Лк родителя - Учет денег', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/myChildren',
    name: 'ParentsMyChildren',
    component: ParentsMyChildren,
    meta: { title: 'Лк родителя - Мои дети', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/autoTransfer',
    name: 'ParentsAutoTransfer',
    component: ParentsAutoTransfer,
    meta: { title: 'Лк родителя - Автоперевод', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/home',
    name: 'ParentsHome',
    component: ParentsHome,
    meta: { title: 'Лк родителя', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/schoolAttendance',
    name: 'ParentsSchoolAttendance',
    component: ParentsSchoolAttendance,
    meta: { title: 'Лк родителя - Проход в школу', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/settingLimits',
    name: 'ParentsSettingLimits',
    component: ParentsSettingLimits,
    meta: {
      title: 'Лк родителя - Установка ограничений',
      requiresAuth: false,
      partialType: 'parents',
      betaComponent: true
    }
  },
  {
    path: '/parents/transferBetweenAccounts',
    name: 'ParentsTransferBetweenAccounts',
    component: ParentsTransferBetweenAccounts,
    meta: {
      title: 'Лк родителя - перевод между счетами',
      requiresAuth: false,
      partialType: 'parents',
      betaComponent: true
    }
  },
  {
    path: '/parents/transferBetweenChildren',
    name: 'ParentsTransferBetweenChildren',
    component: ParentsTransferBetweenChildren,
    meta: {
      title: 'Лк родителя - перевод между детьми',
      requiresAuth: false,
      partialType: 'parents',
      betaComponent: true
    }
  },
  {
    path: '/parents/settings',
    name: 'ParentsSettings',
    component: ParentsSettings,
    meta: { title: 'Лк родителя - настройки', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/notAllowed',
    name: 'ParentsNotAllowed',
    component: ParentsNotAllowed,
    meta: { title: 'Лк родителя - Ребенку нельщя', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/attendanceReport',
    name: 'ParentsAttendanceReport',
    component: ParentsAttendanceReport,
    meta: { title: 'ParentsAttendanceReport', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/singlePayments',
    name: 'ParentsSinglePayments',
    component: ParentsSinglePayments,
    meta: { title: 'Лк родителя - Единый платеж', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/singlePayment',
    name: 'ParentsSinglePayment',
    component: ParentSinglePayment,
    meta: { title: 'ParentsSinglePayment', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/digitalReceipts',
    name: 'ParentsDigitalReceipts',
    component: ParentsDigitalReceipts,
    meta: {
      title: 'Лк родителя - Электронные квитанции',
      requiresAuth: false,
      partialType: 'parents',
      betaComponent: true
    }
  },
  {
    path: '/parents/news',
    name: 'ParentsNews',
    component: ParentsNews,
    meta: { title: 'Лк родителя - новости', requiresAuth: false, partialType: 'parents', betaComponent: true }
  },
  {
    path: '/parents/transaction/:saleBatchId',
    name: 'ParentsTransaction',
    component: ParentsTransaction,
    meta: { title: 'Лк родителя - выписка', requiresAuth: false, partialType: 'parents', betaComponent: true },
    props: true
  },
  {
    path: '/parents/subscription',
    name: 'ParentsSubscription',
    component: ParentsSubscription,
    meta: { title: 'Лк родителя - подписка', requiresAuth: false, partialType: 'parents', betaComponent: true },
    // props: true
  },
  {
    path: '/parents/subscription/singlepayment',
    name: 'ParentsSubscriptionSinglePayment',
    component: ParentsSubscriptionSinglePayment,
    meta: { title: 'Лк родителя - подписка - единый платеж', requiresAuth: false, partialType: 'parents', betaComponent: true },
    // props: true
  },
  {
    path: '/parents/subscription/autopayment',
    name: 'ParentsSubscriptionAutoPaymentSettings',
    component: ParentsSubscriptionAutoPaymentSettings,
    meta: { title: 'Лк родителя - подписка - настройка платежа', requiresAuth: false, partialType: 'parents', betaComponent: true },
    // props: true
  },
  {
    path: '/parents/subscription/settings',
    name: 'ParentsSubscriptionSettings',
    component: ParentsSubscriptionSettings,
    meta: { title: 'Лк родителя - подписка - настройка подписки', requiresAuth: false, partialType: 'parents', betaComponent: true },
    // props: true
  },
  {
    path: '/nfcTest',
    name: 'NFCTest',
    component: TelegramNFCTry,
    meta: { title: 'NFCTest', requiresAuth: false, partialType: 'full', betaComponent: true }
  },
  {
    path: '/ui',
    name: 'UI',
    component: UIKit,
    meta: { title: 'UI', requiresAuth: false, partialType: 'full', betaComponent: true }
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior (to, _, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})

router.beforeEach(async (to, from) => {
  if (to.name === 'TelegramPaymentServiceSettings' ||
    to.name === 'TelegramPaymentService' ||
    to.name === 'TelegramAutoPaymentServiceSettings' ||
    to.name === 'TelegramSupportService' ||
    to.name === 'TelegramSupportDetails' ||
    to.name === 'TelegramSupportTicketHistory' ||
    to.name === 'TelegramSinglePayment' ||
    to.name === 'TestSbp' ||
    to.name === 'TelegramLogin'
  ) {
    // const params = to.query?.urlParams ? to.query?.urlParams : ''
    return true
  }
  // Start our vue-progressbar
  // app.$Progress.start()
  // To set the title of each route
  document.title = (to.meta as any).title
  let accessToken = localStorage.getItem('accessToken')
  let refreshToken = localStorage.getItem('refreshToken')
  let toUrl = '/'
  let urlParams: any = null
  let oldSite: any = localStorage.getItem('oldSite')
  if (oldSite === undefined || oldSite === null) {
    oldSite = process.env.VUE_APP_OLD_SITE_URL || 'https://school.glolime.ru'
    localStorage.setItem('oldSite', oldSite)
  }

  if (to.name === 'LoginByToken') {
    console.log('LoginByToken', to)
    if (to.query.accessToken !== undefined && to.query.refreshToken !== undefined) {
      accessToken = typeof to.query.accessToken === 'string' ? to.query.accessToken.toString() : ''
      refreshToken = typeof to.query.refreshToken === 'string' ? to.query.refreshToken.toString() : ''
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      if (to.query.redirectTo !== undefined) {
        toUrl = String(to.query.redirectTo)
        urlParams = to.query.urlParams
      }
      if (to.query.redirectFrom !== undefined) {
        oldSite = String(to.query.redirectFrom)
        localStorage.setItem('oldSite', oldSite)
      }
    }
  }

  if (to.path === '/oldsite/') {
    window.location.href = oldSite
  }

  if (accessToken != null && refreshToken != null) {
    const decoded: JwtDecodeData = jwtDecode(accessToken)
    store.commit('user/SET_USER', decoded)
    if (to.name === 'Login') {
      return { path: '/' }
      // app.$Progress.finish()
    }
    if (to.name === 'LoginByToken') {
      await store.dispatch('user/getPerson').then(() => {
      })
      // console.log({ name: toUrl, params: JSON.parse(urlParams) })
      let nextObj
      if (urlParams !== undefined && urlParams !== null) {
        nextObj = { name: toUrl, params: JSON.parse(urlParams) }
      } else {
        nextObj = { name: toUrl }
      }
      return nextObj
    }
    if (to.name === 'Home') {
      await store.dispatch('user/getPerson')
    }
  }
  const notProtectedPages = ['LoginByToken', 'TelegramLogin'] // Страницы, на которые можно входить незалогиненным
  // If the user's not logged in do not allow into protected pages.
  if (!(to.meta as RouteMeta).betaComponent) {
    if (to.name !== toUrl && !notProtectedPages.includes(String(to.name)) && (to.meta as RouteMeta).requiresAuth && !store.getters['user/user'].person) {
      return { name: 'Home' }
    }
  } else {
    if (store.getters['user/user'].person || localStorage.getItem('accessToken')) {
      return true
    }
  }
  return true
})

router.afterEach(() => {
  // End our vue-progressbar
  // app.$Progress.finish()
})

export default router
