<template>
  <div class="whole_page">
    <div class="page_container">
      <div class="content_container">
        <h2 class="title-h2-roboto-medium">
          Электронные квитанции
        </h2>
        <div style="justify-content: space-between; display: flex; flex-direction: row; gap: 10px">
          <button
            class="secondary-white-button"
            style="padding: 10px 24px"
            @click="showUnpaidTab"
          >
            Неоплаченные
          </button>
          <button
            class="secondary-white-button"
            style="padding: 10px 24px"
            @click="showPaidTab"
          >
            Оплаченные
          </button>
        </div>
        <template v-if="!isLoading">
          <template v-if="showPaid">
            <table class="custom-table small-text-roboto-medium">
              <thead>
                <tr style="background: #ECF2F6">
                  <td>№</td>
                  <td>Услуга</td>
                  <td>Дата</td>
                  <td>Сумма</td>
                </tr>
              </thead>
              <tr>
                <td>1</td>
                <td>Продленка</td>
                <td>30.05.2024</td>
                <td>100</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Уборка</td>
                <td> 29.05.2024</td>
                <td>342</td>
              </tr>
            </table>
          </template>
          <template v-if="showUnpaid">
            <v-row
              v-for="(receipt, index) in unpaidReceipts"
              :key="receipt.id"
              style="border-bottom: 2px solid #ebeaec"
            >
              <v-col style="display: flex; flex-direction: row">
                <h4 class="body-text-roboto-medium">
                  {{ index + 1 }})
                </h4>
                <div style="position: relative;  padding: 0; margin: 0; width: 120px; height: 120px; align-items: center;">
                  <vue-qrcode
                    v-if="unpaidReceipts[index].qrContent !== null"
                    :value="unpaidReceipts[index].qrContent"
                    :level="'L'"
                    :size="85"
                    :margin="5"
                  />
                </div>
              </v-col>
              <v-col style="padding: 0px">
                <v-row style="margin: 12px 0px ">
                  <v-col style="width: 20px; padding: 0px">
                    <h4 class="small-text-roboto-medium">
                      Услуга:
                    </h4>
                  </v-col>
                  <v-col style="padding: 0px 12px">
                    <h4 class="small-text-roboto-medium">
                      {{ receipt.purpose }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row style="margin: 12px 0px ">
                  <v-col style="width: 20px; padding: 0px">
                    <h4 class="small-text-roboto-medium">
                      Дата:
                    </h4>
                  </v-col>
                  <v-col style="padding: 0px 12px">
                    <h4 class="small-text-roboto-medium">
                      {{ formatDate(receipt.date) }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row style="margin: 12px 0px ">
                  <v-col style="width: 20px; padding: 0px">
                    <h4 class="small-text-roboto-medium">
                      Сумма:
                    </h4>
                  </v-col>
                  <v-col style="padding: 0px 12px">
                    <h4 class="small-text-roboto-medium">
                      {{ receipt.sum }}
                    </h4>
                  </v-col>
                </v-row>
              </v-col>
              <!--              <v-col>-->
              <!--                <v-row style="margin: 12px 0px">-->
              <!--                  <h4 class="small-text-roboto-medium">-->
              <!--                    {{ receipt.purpose }}-->
              <!--                  </h4>-->
              <!--                </v-row>-->
              <!--                <v-row style="margin: 12px 0px">-->
              <!--                  <h4 class="small-text-roboto-medium">-->
              <!--                    {{ receipt.date }}-->
              <!--                  </h4>-->
              <!--                </v-row>-->
              <!--                <v-row style="margin: 12px 0px">-->
              <!--                  <h4 class="small-text-roboto-medium">-->
              <!--                    {{ receipt.sum }}-->
              <!--                  </h4>-->
              <!--                </v-row>-->
              <!--              </v-col>-->
            </v-row>
          </template>
          <div
            v-if="paidReceipts.length < 1 && unpaidReceipts.length < 1"
            class="attention_notification"
          >
            <div class="attention-notification-text-box">
              <h4 class="body-text-roboto-medium">
                Электронных квитанций нет
              </h4>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { getByUserId } from '@/shared/api/parents/digitalReceipts/api-requests'
import { ReceiptGetByUserIdItem } from '@/shared/api/parents/digitalReceipts/api-types'
import VueQrcode from 'vue-qrious'
import { defineComponent } from 'vue'
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'

export type receiptTableElem = {
  purpose: string | null,
  date: string,
  sum: string | null,
  id: number,
  qrContent: string
}

export default defineComponent({
  name: 'ParentsDigitalReceipts',
  components: { QrcodeVue, VueQrcode },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      isTelegram: false,
      isLoading: false,
      paidReceipts: [] as receiptTableElem[],
      unpaidReceipts: [] as receiptTableElem[],
      unpaidReceiptsRaw: [] as ReceiptGetByUserIdItem[],
      showPaid: false,
      showUnpaid: true
    }
  },
  async beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.isLoading = true
    this.currentUser = this.user.person
    await this.receiptsGetByUserId()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async receiptsGetByUserId () {
      try {
        const paidReceiptsGetByUserIdReqObj = {
          userId: this.currentUser.id,
          id: null,
          status: 0,
          receiptTypeId: null,
          paymentPeriod: null
        }
        const unpaidReceiptsGetByUserIdReqObj = {
          userId: this.currentUser.id,
          id: null,
          status: 1,
          receiptTypeId: null,
          paymentPeriod: null
        }
        const paidReceiptsGetByUserIdRes = await getByUserId(paidReceiptsGetByUserIdReqObj, this.isTelegram)
        paidReceiptsGetByUserIdRes.data.forEach(receipt => {
          const paidReceiptItem: receiptTableElem = {
            purpose: this.getQrFieldValue(receipt.qrContent, 'Purpose'),
            date: receipt.creationDate,
            sum: this.getQrFieldValue(receipt.qrContent, 'Sum'),
            id: receipt.id,
            qrContent: receipt.qrContent
          }
          this.paidReceipts.push(paidReceiptItem)
        })
        const unpaidReceiptsGetByUserIdRes = await getByUserId(unpaidReceiptsGetByUserIdReqObj, this.isTelegram)
        this.unpaidReceiptsRaw = unpaidReceiptsGetByUserIdRes.data
        unpaidReceiptsGetByUserIdRes.data.forEach(receipt => {
          const unpaidReceiptItem: receiptTableElem = {
            purpose: this.getQrFieldValue(receipt.qrContent, 'Purpose'),
            date: receipt.creationDate,
            sum: this.getQrFieldValue(receipt.qrContent, 'Sum'),
            id: receipt.id,
            qrContent: receipt.qrContent
          }
          this.unpaidReceipts.push(unpaidReceiptItem)
        })
        console.log(this.unpaidReceipts, 'sd')
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить данные об электронных квитанциях пользователя!',
          timeout: 3000
        })
      }
    },
    getQrFieldValue (str: string, fieldName: string) {
      const regex = new RegExp(`${fieldName}=([^|]+)`)
      const match = str.match(regex)
      return match ? match[1] : null
    },
    goToHome () {
      this.$router.push('./home')
    },
    showPaidTab () {
      this.showPaid = true
      this.showUnpaid = false
    },
    showUnpaidTab () {
      this.showPaid = false
      this.showUnpaid = true
    },
    formatDate(date: any) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";

.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 700px;
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 10px 10px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
