<template>
  <div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="content_container">
          <v-row class=" control_panel">
            <div>
              <h3 class="title-h3-roboto-medium">
                Итог: {{ totalAmount }}
              </h3>
            </div>
            <div class="buttons_panel">
              <button class="primary-green-button">
                Оплата
              </button>
              <button class="secondary-white-button square-btn"
              @click="showSetting">
                <img
                  src="@/assets/images/setting.png"
                  height="30"
                  width="30"
                >
              </button>
              <v-dialog
                v-model="showSettings"
                width="330px"
                light:true
                :retain-focus="false"
              >
                <v-card>
                  <h4 class="title-h4-roboto-medium" style="padding: 20px">
                    Здесь можно корректировать суммы шаблона пополнения баланса
                  </h4>
                  <div style="padding: 0px 10px; display: flex; justify-content: center">
                    <v-text-field
                      v-model="settingInputs[0]"
                      variant="solo"
                      clearable
                      label="100"
                      :rules="settingRules"
                      style="width: 60px; border-radius: 3px;"
                      @update:model-value="checkSettingsInputValue(0)"
                    />
                    <v-text-field
                      v-model="settingInputs[1]"
                      variant="solo"
                      clearable
                      label="200"
                      :rules="settingRules"
                      style="width: 60px; border-radius: 3px; margin-left: 13px;"
                      @update:model-value="checkSettingsInputValue(1)"
                    />
                    <v-text-field
                      v-model="settingInputs[2]"
                      variant="solo"
                      clearable
                      label="300"
                      :rules="settingRules"
                      style="width: 60px; border-radius: 3px; margin-left: 13px"
                      @update:model-value="checkSettingsInputValue(2)"
                    />
                  </div>
                  <div style="justify-content: center; display: flex; gap: 16px; padding: 8px">
                    <button class="primary-green-button" :disabled="IsInvalidValuesFromSettingInputs">
                      Сохранить
                    </button>
                    <button class="secondary-white-button"
                            :style="{background: '#EF9A9A'}"
                            @click="showSetting">
                      Отмена
                    </button>
                  </div>
                </v-card>
              </v-dialog>
            </div>
            <v-row class="content_row" style="margin-top: 7px">
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <h3
                  class="button-text-roboto-medium"
                  style="text-align: center"
                >
                  Андрей
                </h3>
                <v-select
                  variant="solo"
                  density="compact"
                  hide-details="true"
                  style="max-width: 145px"
                />
              </div>
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
                  <h5 class="quote-text-roboto-medium">
                    Горячее
                  </h5>
                  <h5 class="quote-text-roboto-medium">
                    99999.99р
                  </h5>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div style="display: flex; flex-direction: row; gap: 8px; align-items: center">
                    <button class="add_sum_btn">
                      100
                    </button>
                    <button class="add_sum_btn">
                      250
                    </button>
                    <button class="add_sum_btn">
                      500
                    </button>
                  </div>
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details="true"
                    class="telegram-input"
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
                  <h5 class="quote-text-roboto-medium">
                    Буфет
                  </h5>
                  <h5 class="quote-text-roboto-medium">
                    99999.99р
                  </h5>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div style="display: flex; flex-direction: row; gap: 8px; align-items: center">
                    <button class="add_sum_btn">
                      100
                    </button>
                    <button class="add_sum_btn">
                      250
                    </button>
                    <button class="add_sum_btn">
                      500
                    </button>
                  </div>
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details="true"
                    class="telegram-input"
                  />
                </div>
              </div>
            </v-row>
            <v-row class="content_row">
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <h3
                  class="button-text-roboto-medium"
                  style="text-align: center"
                >
                  Антон
                </h3>
                <v-select
                  variant="solo"
                  density="compact"
                  hide-details="true"
                  style="max-width: 145px"
                />
              </div>
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
                  <h5 class="quote-text-roboto-medium">
                    Горячее
                  </h5>
                  <h5 class="quote-text-roboto-medium">
                    99999.99р
                  </h5>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div style="display: flex; flex-direction: row; gap: 8px; align-items: center">
                    <button class="add_sum_btn">
                      100
                    </button>
                    <button class="add_sum_btn">
                      250
                    </button>
                    <button class="add_sum_btn">
                      500
                    </button>
                  </div>
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details="true"
                    class="telegram-input"
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <div style="width: 64px; gap: 4px; display: flex; flex-direction: column">
                  <h5 class="quote-text-roboto-medium">
                    Буфет
                  </h5>
                  <h5 class="quote-text-roboto-medium">
                    99999.99р
                  </h5>
                </div>
                <div style="display: flex; flex-direction: row">
                  <div style="display: flex; flex-direction: row; gap: 8px; align-items: center">
                    <button class="add_sum_btn">
                      100
                    </button>
                    <button class="add_sum_btn">
                      250
                    </button>
                    <button class="add_sum_btn">
                      500
                    </button>
                  </div>
                  <v-text-field
                    variant="solo"
                    clearable
                    hide-details="true"
                    class="telegram-input"
                  />
                </div>
              </div>
            </v-row>
          </v-row>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { TelegramDoPaymentForSinglePaymentResponse } from '@/shared/api/telegram/api-types'

interface PaymentInputs {
  [key: string]: string;
}
export type selectedItem = {
  name: string,
  paymentType: number,
  details: string
}

export default defineComponent({
  name: 'ParentsSubscriptionSinglePayment',
  computed: {
    ...mapGetters({ user: 'user/user' }),
    IsInvalidValuesFromSettingInputs () {
      for (let i = 0; i < this.$data.settingInputs.length; i++) {
        const value = this.$data.settingInputs[i]
        if (value?.length > 4) {
          return true
        }
        if (!Number.isInteger(parseFloat(value))) {
          return true
        }
        if (+value > this.maxInputValue) {
          return true
        }
        if (isNaN(+value)) {
          return true
        }
        if (+value < 0) {
          return true
        }
      }
      return false
    },
    isInvalidValuesFromPaymentInputs () {
      for (const key in this.$data.paymentInputs) {
        if (Object.prototype.hasOwnProperty.call(this.$data.paymentInputs, key)) {
          const value = this.$data.paymentInputs[key]
          if (value?.length > 4) {
            return true
          }
          if (isNaN(+value)) {
            return true
          }
          if (+value > this.maxInputValue) {
            return true
          }
          if (!Number.isInteger(parseFloat(value))) {
            return true
          }
          if (+value < 0) {
            return true
          }
        }
      }
      return false
    },
    // hasPaymentTools () {
    //   for (let i = 0; i < this.$data.telegramPaymentKeyForSinglePayment.length; i++) {
    //     const item = this.$data.telegramPaymentKeyForSinglePayment[i]
    //     if (item.bankBindings.length > 0 || item.sbpSubscriptions.length > 0) {
    //       return false
    //     }
    //   }
    //   return true
    // }
  },
  data () {
    return {
      isLoading: false,
      isTelegram: false,
      currentUser: {} as Person,
      totalAmount: 0,
      showSettings: false,
      settingInputs: ['100', '200', '500'],
      buttonValueSetting: ['100', '200', '500'],
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Неизвестная',
      menu: [],
      paymentKeyNames: [],
      selectedItem: [],
      paymentInputs: { } as PaymentInputs,
      maxInputValue: 2000,
      paymentResult: {} as TelegramDoPaymentForSinglePaymentResponse,
      showPaymentResults: false,
      paymentRules: [
        (value: any) => {
          if (value || value === undefined) return true
          return 'Пустое поле.'
        },
        (value: any) => {
          if (value?.length <= 4 || value === undefined) return true
          return 'Максимум 4 цифры.'
        },
        (value: any) => {
          if (!isNaN(value) || value === undefined) return true
          return 'Должно быть число.'
        },
        (value: any) => {
          if (value <= this.maxInputValue || value === undefined) return true
          return 'Максимальная сумма оплаты 2000 руб.'
        },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) || value === undefined) return true
          return 'Введите целое число'
        },
        (value: any) => {
          if (parseFloat(value) >= 0 || value === undefined) return true
          return 'Введите натуральное число'
        }
      ],
      settingRules: [
        (value: any) => {
          if (value) return true
          return 'Пустое поле.'
        },
        (value: any) => {
          if (value?.length <= 4) return true
          return 'Максимум 4 цифры.'
        },
        (value: any) => {
          if (!isNaN(value)) return true
          return 'Должно быть число.'
        },
        (value: any) => {
          if (value <= this.maxInputValue) return true
          return 'Максимальная сумма оплаты 2000 руб.'
        },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) && parseFloat(value) > 0) return true
          return 'Введите натуральное число'
        }
      ],
    }
  },
  methods: {
    checkSettingsInputValue (index: number) {
      if (parseFloat(this.settingInputs[index]) > 2000) {
        this.settingInputs[index] = '2000'
      }
    },
    showSetting () {
      this.showSettings = !this.showSettings
    },
  }
}
)
</script>

<script setup lang="ts">
</script>
<style scoped lang="scss">
@import "@/app/styles/uikit-variables";

.content_container {
  width: 700px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.control_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  //width: 100%
}
.buttons_panel {
  display: flex;
  flex-direction: row;
  gap: 10px
}
.content_row {
  padding: 12px 0px;
  margin: 0px 1px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $color-dark-50;
  gap: 16px;
}

.add_sum_btn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #a19ea9;
  background: #FDEBA3;
  font-weight: 500;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);
}
.telegram-input {
  width: 90px;
  margin-left: 15px;
  background: #ffffff;
  border-radius: 3px;
}
::placeholder {
  padding-left: 5px;
}
.list-item {
  border: 1px solid #f5f5f5;
  text-align: center;
  gap: 5px;
}
.first-row {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 15px;
  background: #f5f5f5;
}
.square-btn {
  min-width: unset !important;
  padding: 5px;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
