<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        cols="7"
        sm="7"
      >
        <filters-panel
          :filter-options="filterOptions"
          :filter-state="filterState"
          @filter-changed-state="filterChangedHandler"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="3"
        sm="3"
      >
        <v-btn
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <v-col
        v-if="!isLoading && isPost && bodyDates.length > 0 && showReportUsers === false"
        class="d-flex"
        cols="1"
        sm="1"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <template v-if="isPost">
        <v-row v-if="products.length > 0 && showReportUsers === true">
          <h4>
            Список детей, у которых в талонах только завтрак или только обед
            <v-btn
              style="margin-left: 10px"
              @click="showReportUsers = false"
            >
              Назад
            </v-btn>
          </h4>
        </v-row>
        <v-row v-if="products.length > 0 && showReportUsers === true">
          <v-col>
            <table
              v-if="reportUsers.length > 0"
              class="report-rateplan-day"
            >
              <thead>
                <tr>
                  <th>
                    Дата
                  </th>
                  <th style="padding-left: 4px; padding-right: 4px;">
                    Номер талона
                  </th>
                  <th>
                    Ф.И.О
                  </th>
                  <th
                    v-for="(product, j) in unlimitedProducts"
                    :key="j"
                    style="padding-left: 4px; padding-right: 4px;"
                  >
                    Отсутствует {{ getProductName(product.id) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reportUser, i) in reportUsers"
                  :key="i"
                >
                  <td style="padding-left: 4px; padding-right: 4px;">
                    {{ reportUser.date }}
                  </td>
                  <td style="padding-left: 4px; padding-right: 4px;">
                    {{ getTalonNumber(reportUser) }}
                  </td>
                  <td style="padding-left: 4px;">
                    {{ reportUser.lastName }} {{ reportUser.firstName }} {{ reportUser.middleName }}
                  </td>
                  <td
                    v-for="(product, j) in unlimitedProducts"
                    :key="j"
                    style="text-align: center;"
                  >
                    {{ checkProduct(reportUser, product) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <v-row
          v-if="errorDates.length > 0 && showReportUsers === false"
          class="justify-content-center"
        >
          <span class="text-warning">Ошибки в талонах за: </span>
          <template
            v-for="(errorDate, i) in errorDates"
            :key="i"
          >
            <span class="span-with-space">
              <i> {{ errorDate }} </i>
            </span>
          </template>
          <span class="text-warning span-with-space"> {{ errorDates.length > 1 ? 'числа' : 'число' }}</span>
        </v-row>
        <v-row
          v-if="notAcceptDates.length > 0 && showReportUsers === false"
          class="justify-content-center"
        >
          <span class="text-warning">Неучтены талоны за: </span>
          <template
            v-for="(notAcceptDate, i) in notAcceptDates"
            :key="i"
          >
            <span class="span-with-space">
              <i> {{ notAcceptDate }} </i>
            </span>
          </template>
          <span class="text-warning span-with-space"> {{ notAcceptDates.length > 1 ? 'числа' : 'число' }}</span>
        </v-row>
        <v-row
          v-if="reportUsers.length > 0 && showReportUsers === false"
          class="justify-content-center"
        >
          <span class="text-warning">
            Есть дети учтенные не полностью: <v-btn @click="showReportUsers = true">Детально</v-btn>
          </span>
        </v-row>
      </template>
      <div id="printableArea">
        <template v-if="bodyDates.length > 0 && showReportUsers === false">
          <v-row
            v-if="isPost && products.length > 0 && showReportUsers === false"
            class="justify-content-center"
          >
            <v-col>
              <h4 class="text-center print-header">
                Акт о реализации талонов на предоставление питания в государственных
                образовательных учреждениях с компенсацией стоимости (части стоимости) питания за счет средств
                бюджета<br>
                Санкт-Петербурга<br>
                {{ selectedUserAllowedPlaceName }}<br>
                за {{ selectedMonthName }} {{ selectedYearName }} г. ({{ getDateRange(bodyDates) }} гг.)
              </h4>
            </v-col>
          </v-row>
          <v-row
            v-if="bodyDates.length > 0 && showReportUsers === false"
            class="justify-content-center"
          >
            <v-col cols="12">
              <!--<p>Цена {{ getAveragePrice(unlimitedProducts) }}</p>-->
              <v-table class="report-rateplan-day">
                <template #default>
                  <thead>
                    <tr>
                      <th
                        class="text-center"
                        style="width:20px;"
                        rowspan="3"
                      >
                        Число месяца
                      </th>
                      <th
                        class="text-center"
                        :colspan="unlimitedRatePlans.length * 2 + 2"
                      >
                        Завтраки и обеды, предоставляемые обучающимся {{ parallelsHeadName }} классов (по
                        {{ getAveragePrice(unlimitedProducts) }})
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                        colspan="2"
                      >
                        Завтраки, предоставляемые обучающимся {{ parallelsHeadName }} классов (по
                        {{ getAveragePrice(limitedProducts) }})
                      </th>
                      <th
                        class="text-center"
                        rowspan="2"
                        colspan="2"
                      >
                        Всего питание {{ parallelsHeadName }} классов
                      </th>
                    </tr>
                    <tr>
                      <th
                        v-for="(unlimitedRatePlan, i) in unlimitedRatePlans"
                        :key="i"
                        class="text-center"
                        colspan="2"
                      >
                        {{ unlimitedRatePlan.name }}<br>
                      </th>
                      <th
                        class="text-center"
                        colspan="2"
                      >
                        Итого
                      </th>
                    </tr>
                    <tr>
                      <template
                        v-for="(unlimitedRatePlan, i) in unlimitedRatePlans"
                        :key="i + '_' + '1'"
                      >
                        <th class="text-center rotate">
                          <div>
                            кол-во
                          </div>
                        </th>
                        <th class="text-center rotate">
                          <div>
                            сумма
                          </div>
                        </th>
                      </template>
                      <th class="text-center rotate">
                        <div>
                          кол-во
                        </div>
                      </th>
                      <th class="text-center rotate">
                        <div>
                          сумма
                        </div>
                      </th>
                      <th class="text-center rotate">
                        <div>
                          кол-во
                        </div>
                      </th>
                      <th class="text-center rotate">
                        <div>
                          сумма
                        </div>
                      </th>
                      <th class="text-center rotate">
                        <div>
                          кол-во
                        </div>
                      </th>
                      <th class="text-center rotate">
                        <div>
                          сумма
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(bodyDate, i) in bodyDates"
                      :key="i"
                    >
                      <td class="text-center">
                        {{ getBodyDay(bodyDate) }}
                      </td>
                      <template
                        v-for="(unlimitedRatePlan, j) in unlimitedRatePlans"
                        :key="j + '_' + i + '_' + '1'"
                      >
                        <td
                          class="text-center"
                        >
                          {{ searchProductCount(unlimitedRatePlan, bodyDate) }}
                        </td>
                        <td
                          class="text-center"
                        >
                          {{ searchProductSum(unlimitedRatePlan, bodyDate) }}
                        </td>
                      </template>
                      <td class="text-center">
                        {{ searchProductCountByRatePlansAndDate(unlimitedRatePlans, bodyDate) }}
                      </td>
                      <td class="text-center">
                        {{ searchProductSumByRatePlansAndDate(unlimitedRatePlans, bodyDate) }}
                      </td>
                      <td class="text-center">
                        {{ searchProductCountByRatePlansAndDate(limitedRatePlans, bodyDate) }}
                      </td>
                      <td class="text-center">
                        {{ searchProductSumByRatePlansAndDate(limitedRatePlans, bodyDate) }}
                      </td>
                      <td class="text-center">
                        {{ searchProductCountByRatePlansAndDate(allRatePlans, bodyDate) }}
                      </td>
                      <td class="text-center">
                        {{ searchProductSumByRatePlansAndDate(allRatePlans, bodyDate) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="text-center">
                        Итого:
                      </th>
                      <template
                        v-for="(unlimitedRatePlan, j) in unlimitedRatePlans"
                        :key="j + '_' + '1'"
                      >
                        <th
                          class="text-center"
                        >
                          {{ getProductCount(unlimitedRatePlan) }}
                        </th>
                        <th
                          class="text-center"
                        >
                          {{ getProductSum(unlimitedRatePlan) }}
                        </th>
                      </template>
                      <th class="text-center">
                        {{ getProductCountByRatePlans(unlimitedRatePlans) }}
                      </th>
                      <th class="text-center">
                        {{ getProductSumByRatePlans(unlimitedRatePlans) }}
                      </th>
                      <th class="text-center">
                        {{ getProductCountByRatePlans(limitedRatePlans) }}
                      </th>
                      <th class="text-center">
                        {{ getProductSumByRatePlans(limitedRatePlans) }}
                      </th>
                      <th class="text-center">
                        {{ getProductCountByRatePlans(allRatePlans) }}
                      </th>
                      <th class="text-center">
                        {{ getProductSumByRatePlans(allRatePlans) }}
                      </th>
                    </tr>
                  </tfoot>
                </template>
              </v-table>
            </v-col>
          </v-row>
          <v-row v-if="customerPlaceInfo != null && showReportUsers === false">
            <v-col class="print-sign">
              Директор школы _____________________________/{{ customerPlaceInfo.directorName }}/
            </v-col>
          </v-row>
          <v-row v-if="customerPlaceInfo != null && showReportUsers === false">
            <v-col class="print-sign">
              Организатор школьного питания _____________________________/{{ customerPlaceInfo.ladminName }}/
            </v-col>
          </v-row>
        </template>
        <template v-else-if="isPost && showReportUsers === false">
          <v-row class="justify-content-center">
            <v-col>
              <h3 class="text-center">
                По заданным фильтрам записей не найдено!
              </h3>
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
    <template v-else>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { PlaceElectronTalonFindWithItemsAndRatePlansPost } from '@/shared/api/placeElectronTalon/api-types'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { findWithItemsAndRatePlans } from '@/shared/api/placeElectronTalon/api-requests'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'
import { find as findRatePlans } from '@/shared/api/placeCustomerRatePlans/api-requests'
import { PlaceCustomerRatePlanFindPost } from '@/shared/api/placeCustomerRatePlans/api-types'
import FiltersPanel, { FilterNames, FilterOptions } from '@/shared/uikit/FiltersPanel.vue'
import { printer } from '@/shared/lib/print'

type BaseData = {
  showReportUsers: boolean;
  notAcceptDates: any;
  errorDates: any;
  usersInfo: any;
  userWithProducts: any;
  users: any;
  userCount: any;
  userSum: any;
  selectedUserAllowedPlaceId: number;
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlaceName: string;
  selectedUserAllowedPlace: any;
  selectedMonthName: string;
  selectedYearName: string;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsAndRatePlansPost;
  customerPlacePost: CustomerPlaceFindPost;
  placeElectronTalonsAnswere: any;
  customerPlaceInfo: any;
  ratePlanInfo: any;
  unlimitedRatePlans: any;
  limitedRatePlans: any;
  allRatePlans: any;
  unlimitedProducts: any;
  limitedProducts: any;
  placeElectronTalons: any;
  places: any;
  products: any;
  ratePlans: any;
  bodyDates: any;
  productDatePrices: any;
  datePrices: any;
  parallelsHeadName: any;
  reportUsers: any;
  filterOptions: FilterOptions;
  filterState: any;
}

export default defineComponent({
  components: {
    FiltersPanel
  },
  data (): BaseData {
    return {
      filterState: {
        placeFilter: 0,
        classFilter: 0,
        productTypeFilter: 0,
        ratePlanFilter: 0,
        periodFilter: [],
        monthFilter: moment().month(),
        yearFilter: moment().year(),
        initialState: false
      } as any,
      filterOptions: {
        placeFilter: true,
        classFilter: false,
        productTypeFilter: false,
        ratePlanFilter: false,
        monthFilter: true,
        yearFilter: true,
        classItems: [],
        productTypeItems: [],
        placeItems: [],
        ratePlanItems: [],
        periodFilter: true
      } as FilterOptions,
      showReportUsers: false,
      notAcceptDates: [],
      errorDates: [],
      usersInfo: [],
      userWithProducts: [],
      users: [],
      userCount: [],
      userSum: [],
      selectedUserAllowedPlaceId: 0,
      isLoading: false,
      isPost: false,
      selectedUserAllowedPlace: [],
      selectedUserAllowedPlaceName: '',
      selectedMonthName: 'Неизвестно',
      selectedYearName: 'Неизвестно',
      placeElectronTalonGetByIdPost: {
        parentPlaceId: 0,
        dateFrom: '',
        dateTo: '',
        includeUserInfo: true
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      placeElectronTalonsAnswere: [],
      customerPlaceInfo: null,
      ratePlanInfo: [],
      unlimitedRatePlans: [],
      limitedRatePlans: [],
      allRatePlans: [],
      unlimitedProducts: [],
      limitedProducts: [],
      placeElectronTalons: [],
      places: [],
      products: [],
      ratePlans: [],
      bodyDates: [],
      productDatePrices: [],
      datePrices: [],
      parallelsHeadName: '',
      reportUsers: []
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  async beforeMount () {
    this.isLoading = true
    const userAllowedPlaces = await this.userAllowedPlaceAll()
    this.filterOptions.placeItems = userAllowedPlaces
    this.filterState[FilterNames.PLACE_FILTER] = userAllowedPlaces[0].placeId
    await this.loadCustomerPlaceInfo()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    filterChangedHandler (filterName: FilterNames, oldValue: any, newValue: any) {
      this.filterState[filterName] = newValue
    },
    async loadPlaceElectronTalons () {
      try {
        this.isLoading = true
        if (this.filterOptions.placeItems.length !== 0) {
          this.selectedUserAllowedPlaceName = this.filterOptions.placeItems.find((placeItem: any) => {
            return placeItem.placeId === this.filterState[FilterNames.PLACE_FILTER]
          }).place.name

          this.placeElectronTalonGetByIdPost.parentPlaceId = this.filterState[FilterNames.PLACE_FILTER]

          if (this.filterState[FilterNames.PERIOD_FILTER].length >= 2){
            this.placeElectronTalonGetByIdPost.dateFrom = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).add('days', 1).format('YYYY-MM-DD')
          } else if (this.filterState[FilterNames.PERIOD_FILTER].length === 1) {
            this.placeElectronTalonGetByIdPost.dateFrom = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).add('days', 1).format('YYYY-MM-DD')
          } else {
            this.placeElectronTalonGetByIdPost.dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).startOf('month').format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).endOf('month').format('YYYY-MM-DD')
          }
          const result = await findWithItemsAndRatePlans(this.placeElectronTalonGetByIdPost)
          this.placeElectronTalonsAnswere = JSON.parse(JSON.stringify(result))
          if (Array.isArray(this.placeElectronTalonsAnswere.placeEletronTalons)) {
            this.placeElectronTalons = this.placeElectronTalonsAnswere.placeEletronTalons
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.products)) {
            this.products = this.placeElectronTalonsAnswere.products
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.places)) {
            this.places = this.placeElectronTalonsAnswere.places
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.ratePlans)) {
            this.ratePlans = this.placeElectronTalonsAnswere.ratePlans
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.places)) {
            this.usersInfo = this.placeElectronTalonsAnswere.users
          }
          if (this.places.length > 0 && this.products.length > 0 && this.ratePlans.length > 0 && this.placeElectronTalons.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.filterOptions.placeItems.length !== 0) {
          this.customerPlacePost.placeId = this.filterState[FilterNames.PLACE_FILTER]
          if (this.filterState[FilterNames.PERIOD_FILTER].length >= 2) {
            this.customerPlacePost.date = moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).format('YYYY-MM-DD')
          } else if (this.filterState[FilterNames.PERIOD_FILTER].length === 1) {
            this.customerPlacePost.date = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
          } else {
            this.customerPlacePost.date = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
          }
          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    async loadRatePlanInfo () {
      try {
        const ratePlanFindPost: PlaceCustomerRatePlanFindPost = {}
        const result = await findRatePlans(ratePlanFindPost)
        const ratePlans = JSON.parse(JSON.stringify(result))
        this.ratePlanInfo = ratePlans.filter((element: any) => element.ratePlanDiscountTypeId !== null)
        this.unlimitedRatePlans = this.ratePlanInfo.filter((element: any) => element.ratePlanDiscountTypeId === 1)
        this.limitedRatePlans = this.ratePlanInfo.filter((element: any) => element.ratePlanDiscountTypeId === 2)
        this.allRatePlans = this.ratePlanInfo.filter((element: any) => element.ratePlanDiscountTypeId === 1 || element.ratePlanDiscountTypeId === 2)
        this.unlimitedRatePlans.sort((a: any, b: any) => (Number(a.code) > Number(b.code)) ? 1 : ((Number(b.code) > Number(a.code)) ? -1 : 0))
        this.limitedRatePlans.sort((a: any, b: any) => (Number(a.code) > Number(b.code)) ? 1 : ((Number(b.code) > Number(a.code)) ? -1 : 0))
        this.allRatePlans.sort((a: any, b: any) => (Number(a.code) > Number(b.code)) ? 1 : ((Number(b.code) > Number(a.code)) ? -1 : 0))
        this.unlimitedRatePlans.forEach((element: any) => {
          const products = element.ratePlanProducts.filter((ratePlanProduct: any) => Number(ratePlanProduct.groupId) === 1051)
          if (products !== undefined) {
            products.forEach((product: any) => {
              const i = this.unlimitedProducts.findIndex((unlimitedProduct: any) => Number(unlimitedProduct.id) === Number(product.productId))
              if (i <= -1) {
                this.unlimitedProducts.push({ id: product.productId })
              }
            })
          }
        })
        this.limitedRatePlans.forEach((element: any) => {
          const products = element.ratePlanProducts.filter((ratePlanProduct: any) => Number(ratePlanProduct.groupId) === 1051)
          if (products !== undefined) {
            products.forEach((product: any) => {
              const i = this.limitedProducts.findIndex((unlimitedProduct: any) => Number(unlimitedProduct.id) === Number(product.productId))
              if (i <= -1) {
                this.limitedProducts.push({ id: product.productId })
              }
            })
          }
        })
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    createReport () {
      this.places.forEach((element: any) => {
        let classType = 0
        if (element.name.match(/^[1-4][^0-9].*|^[1-4]$/)) {
          classType = 1051
        }
        element.classType = classType
      })

      const tempRatePlans: any = []
      this.ratePlanInfo.forEach((element: any) => {
        const ratePlanProducts: any = []
        element.ratePlanProducts.forEach((ratePlanProduct: any) => {
          ratePlanProducts.push({
            id: Number(ratePlanProduct.id),
            productId: Number(ratePlanProduct.productId)
          })
        })
        tempRatePlans.push({
          id: Number(element.id),
          ratePlanId: Number(element.placeCustomerRatePlans[0].customerRatePlanId),
          ratePlanProducts: ratePlanProducts
        })
      })

      this.parallelsHeadName = '1-4'
      this.placeElectronTalons.forEach((placeElectronTalon: any) => {
        const tempDate = moment(placeElectronTalon.date).format('DD.MM.YYYY')
        placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
          if (placeElectronTalonItem.removed == null) {
            placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
              if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId != null) {
                const place = this.places.find((place: any) =>
                  place.id === placeElectronTalon.placeId && place.classType === 1051
                )
                if (place !== undefined) {
                  if (!this.bodyDates.find((el: any) => el === tempDate)) {
                    this.bodyDates.push(tempDate)
                  }
                  let productPrice = 0
                  this.products.forEach((product: any) => {
                    if (product.id === placeElectronTalonItemSale.productId) {
                      placeElectronTalon.placeElectronTalonProducts.forEach((placeElectronTalonProduct: any) => {
                        if (product.id === placeElectronTalonProduct.productId && placeElectronTalonProduct.removed === null) {
                          productPrice = placeElectronTalonProduct.price
                          this.productDatePrices.push({
                            date: tempDate,
                            product: placeElectronTalonItemSale.productId,
                            price: placeElectronTalonProduct.price
                          })
                        }
                      })
                    }
                  })
                  const ratePlan = tempRatePlans.find((el: any) => el.ratePlanId === Number(placeElectronTalonItem.userRatePlan))
                  if (ratePlan !== undefined) {
                    this.userWithProducts.push({
                      userId: Number(placeElectronTalonItem.userId),
                      date: tempDate,
                      productId: Number(placeElectronTalonItemSale.productId),
                      ratePlanId: Number(ratePlan.id),
                      talonNumber: placeElectronTalon.talonNumber
                    })
                    const ratePlanProduct = ratePlan.ratePlanProducts.find((el: any) => el.productId === Number(placeElectronTalonItemSale.productId))
                    if (ratePlanProduct !== undefined) {
                      const i = this.users.findIndex((el: any) => el.date === tempDate && el.ratePlanId === Number(ratePlan.id) && el.userId === Number(placeElectronTalonItem.userId))
                      if (i <= -1) {
                        this.users.push({
                          date: tempDate,
                          ratePlanId: Number(ratePlan.id),
                          userId: Number(placeElectronTalonItem.userId)
                        })
                        const k = this.userCount.findIndex((el: any) => el.date === tempDate && el.ratePlanId === Number(ratePlan.id))
                        if (k <= -1) {
                          this.userCount.push({ date: tempDate, ratePlanId: Number(ratePlan.id), count: 1 })
                        } else {
                          this.userCount[k].count += 1
                        }
                      }
                      const j = this.userSum.findIndex((el: any) => el.date === tempDate && el.ratePlanId === Number(ratePlan.id))
                      if (j > -1) {
                        this.userSum[j].sum += Number(productPrice)
                      } else {
                        this.userSum.push({
                          date: tempDate,
                          ratePlanId: Number(ratePlan.id),
                          sum: Number(productPrice)
                        })
                      }
                    }
                  }
                }
              } else {
                if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                  if (!this.notAcceptDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notAcceptDates.push(moment(placeElectronTalon.date).format('DD'))
                }
              }
            })
          }
          if (placeElectronTalonItem.error != null) {
            if (String(placeElectronTalonItem.error) !== '15') {
              if (!this.errorDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.errorDates.push(moment(placeElectronTalon.date).format('DD'))
            }
          }
        })
      })

      this.productDatePrices.forEach((productDatePrice: any) => {
        if (!this.datePrices.find((el: any) => el.product === productDatePrice.product && el.date === productDatePrice.date)) {
          this.datePrices.push({
            date: productDatePrice.date,
            product: productDatePrice.product,
            price: Number(productDatePrice.price),
            count: 1
          })
        } else {
          this.datePrices.forEach((datePrice: any) => {
            if (datePrice.product === productDatePrice.product && datePrice.date === productDatePrice.date) {
              datePrice.count = datePrice.count + 1
              datePrice.price = datePrice.price + Number(productDatePrice.price)
            }
          })
        }
      })

      this.bodyDates.sort((a: Date, b: Date) => (a > b) ? 1 : ((b > a) ? -1 : 0))

      const tempUsers: any = []
      this.bodyDates.forEach((element: any) => {
        const tempUserWithProducts = this.userWithProducts.filter((userWithProduct: any) => userWithProduct.date === element)
        tempUserWithProducts.forEach((user: any) => {
          let withRatePlan = 0
          this.unlimitedRatePlans.forEach((ratePlan: any) => {
            if (user.ratePlanId === ratePlan.id) {
              withRatePlan = 1
            }
          })
          if (withRatePlan === 1) {
            const i = tempUsers.findIndex((el: any) => el.date === element && el.userId === Number(user.userId))
            if (i <= -1) {
              const tempUser = this.usersInfo.find((a: any) => Number(a.id) === Number(user.userId))
              tempUsers.push({
                date: element,
                talonNumber: user.talonNumber,
                userId: Number(user.userId),
                productCount: 1,
                firstName: tempUser.firstName,
                lastName: tempUser.lastName,
                middleName: tempUser.middleName,
                products: [{ id: user.productId }]
              })
            } else {
              tempUsers[i].productCount += 1
              tempUsers[i].products.push({ id: user.productId })
            }
          }
        })
      })
      this.reportUsers = tempUsers.filter((t: any) => t.productCount === 1)
    },
    load () {
      this.showReportUsers = false
      this.errorDates = []
      this.notAcceptDates = []
      this.userWithProducts = []
      this.usersInfo = []
      this.reportUsers = []
      this.unlimitedRatePlans = []
      this.limitedRatePlans = []
      this.allRatePlans = []
      this.bodyDates = []
      this.productDatePrices = []
      this.datePrices = []
      this.users = []
      this.userCount = []
      this.userSum = []
      this.placeElectronTalons = []
      this.places = []
      this.products = []
      this.ratePlans = []
      this.placeElectronTalonsAnswere = []
      this.customerPlaceInfo = null
      this.loadCustomerPlaceInfo()
      this.loadRatePlanInfo()
      this.loadPlaceElectronTalons()
      this.isPost = true
      this.selectedMonthName = moment().locale('ru').month(this.filterState[FilterNames.MONTH_FILTER]).format('MMMM')
      this.selectedYearName = this.filterState[FilterNames.YEAR_FILTER]
    },
    searchProductCount (ratePlan: any, date: any) {
      const ratePlanId = Number(ratePlan.id)
      const productCount = this.userCount.find((el: any) => el.date === date && el.ratePlanId === ratePlanId)
      if (productCount === undefined) {
        return 0
      }
      return productCount.count
    },
    searchProductSum (ratePlan: any, date: any) {
      const ratePlanId = Number(ratePlan.id)
      const productSum = this.userSum.find((el: any) => el.date === date && el.ratePlanId === ratePlanId)
      if (productSum === undefined) {
        return 0
      }
      return productSum.sum.toFixed(2)
    },
    searchProductCountByRatePlansAndDate (ratePlans: any, date: any) {
      let productCount = 0
      ratePlans.forEach((element: any) => {
        const ratePlanId = Number(element.id)
        const tempProductCount = this.userCount.find((el: any) => el.date === date && el.ratePlanId === ratePlanId)
        if (tempProductCount !== undefined) {
          productCount += Number(tempProductCount.count)
        }
      })
      return productCount
    },
    searchProductSumByRatePlansAndDate (ratePlans: any, date: any) {
      let productSum = 0
      ratePlans.forEach((element: any) => {
        const ratePlanId = Number(element.id)
        const tempProductSum = this.userSum.find((el: any) => el.date === date && el.ratePlanId === ratePlanId)
        if (tempProductSum !== undefined) {
          productSum += Number(tempProductSum.sum)
        }
      })
      return productSum.toFixed(2)
    },
    getProductCount (ratePlan: any) {
      let productCount = 0
      const ratePlanId = Number(ratePlan.id)
      const productCounts = this.userCount.filter((el: any) => el.ratePlanId === ratePlanId)
      if (productCounts === undefined) {
        return 0
      }
      if (productCounts.length === 0) {
        return 0
      }
      productCounts.forEach((element: any) => {
        productCount += Number(element.count)
      })
      return productCount
    },
    getProductSum (ratePlan: any) {
      let productSum = 0
      const ratePlanId = Number(ratePlan.id)
      const productSums = this.userSum.filter((el: any) => el.ratePlanId === ratePlanId)
      if (productSums === undefined) {
        return 0
      }
      if (productSums.length === 0) {
        return 0
      }
      productSums.forEach((element: any) => {
        productSum += Number(element.sum)
      })
      return productSum.toFixed(2)
    },
    getProductCountByRatePlans (ratePlans: any) {
      let productCount = 0
      ratePlans.forEach((element: any) => {
        const ratePlanId = Number(element.id)
        const tempProductCounts = this.userCount.filter((el: any) => el.ratePlanId === ratePlanId)
        if (tempProductCounts !== undefined) {
          if (tempProductCounts.length > 0) {
            tempProductCounts.forEach((element: any) => {
              productCount += Number(element.count)
            })
          }
        }
      })
      return productCount
    },
    getProductSumByRatePlans (ratePlans: any) {
      let productSum = 0
      ratePlans.forEach((element: any) => {
        const ratePlanId = Number(element.id)
        const tempProductSums = this.userSum.filter((el: any) => el.ratePlanId === ratePlanId)
        if (tempProductSums !== undefined) {
          if (tempProductSums.length > 0) {
            tempProductSums.forEach((element: any) => {
              productSum += Number(element.sum)
            })
          }
        }
      })
      return productSum.toFixed(2)
    },
    getAveragePrice (products: any) {
      let allSum = 0
      products.forEach((element: any) => {
        const prices = this.productDatePrices.filter((el: any) => el.product === element.id)
        let sum = 0
        let count = 0
        prices.forEach((price: any) => {
          sum += Number(price.price)
          count = count + 1
        })
        if (count > 0) {
          allSum += Number(sum / count)
        }
      })
      return (allSum).toFixed(2)
    },
    getDateRange (dates: any) {
      if (dates.length > 0) {
        return dates[0] + '-' + dates[dates.length - 1]
      }
      return ''
    },
    getBodyDay (bodyDate: any) {
      const temp = moment(bodyDate, 'DD.MM.YYYY')
      return temp.format('D')
    },
    checkProduct (reportUser: any, product: any) {
      const findProduct = reportUser.products.find((reportUserProduct: any) => Number(reportUserProduct.id) === Number(product.id))
      if (findProduct === undefined) {
        return 'V'
      }
      return ''
    },
    getTalonNumber (reportUser: any) {
      return reportUser.talonNumber
    },
    getProductName (productId: any) {
      const tempProduct = this.products.find((pr: any) => Number(pr.id) === Number(productId))
      if (tempProduct === undefined) {
        return ''
      }
      return tempProduct.name.toLowerCase()
    },
    print () {
      printer('printableArea', 'print.css')
    }
  }
})
</script>

<style lang="scss">
.rotate {
  padding: 5px !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 1.5em;
}

.rotate div {
  margin: 5px;
  padding: 5px;
  -moz-transform: rotate(-90.0deg); /* FF3.5+ */
  -o-transform: rotate(-90.0deg); /* Opera 10.5 */
  -webkit-transform: rotate(-90.0deg); /* Saf3.1+, Chrome */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)"; /* IE8 */
  margin-left: -10em;
  margin-right: -10em;
}

.report-rateplan-day table thead tr th {
  font-size: 12px !important;
  padding: 0 4px !important;
}
</style>
