import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b0d94b7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "whole_page" }
const _hoisted_2 = { style: {"display":"grid","justify-content":"center"} }
const _hoisted_3 = { class: "control_panel" }
const _hoisted_4 = { style: {"display":"flex","gap":"12px"} }
const _hoisted_5 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_6 = {
  key: 0,
  style: {display: 'grid' }
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "body-text-roboto-medium" }
const _hoisted_9 = { style: {"text-align":"end","display":"flex","flex-direction":"column","gap":"8px"} }
const _hoisted_10 = { class: "quote-text-roboto-regular" }
const _hoisted_11 = { class: "quote-text-roboto-regular" }
const _hoisted_12 = { style: {"display":"grid","justify-content":"center"} }
const _hoisted_13 = {
  key: 1,
  style: {"text-align":"center"}
}
const _hoisted_14 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ration_card_new = _resolveComponent("ration-card-new")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_menu, {
            modelValue: _ctx.menu1,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menu1) = $event)),
            "close-on-content-click": false,
            offset: 40,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "390px"
          }, {
            activator: _withCtx(({props}) => [
              _createVNode(_component_v_text_field, _mergeProps({
                "model-value": _ctx.formatDate(_ctx.dateFrom),
                variant: "solo",
                density: "compact",
                label: "C",
                "append-icon": "mdi-calendar",
                readonly: "",
                style: {"width":"155px"}
              }, props), null, 16, ["model-value"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_date_picker, {
                "hide-weekdays": "",
                modelValue: _ctx.dateFrom,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFrom) = $event)),
                title: "C",
                color: "green-accent-2",
                max: _ctx.dateToMaxFrom,
                onInput: _ctx.updateDatepicker,
                onDateSelect: _ctx.changeDateFrom
              }, null, 8, ["modelValue", "max", "onInput", "onDateSelect"])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_menu, {
            modelValue: _ctx.menu,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.menu) = $event)),
            "close-on-content-click": false,
            offset: 40,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "390px"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_text_field, _mergeProps({
                "model-value": _ctx.formatDate(_ctx.dateTo),
                variant: "solo",
                density: "compact",
                label: "По",
                "append-icon": "mdi-calendar",
                readonly: "",
                style: {"width":"155px"}
              }, props), null, 16, ["model-value"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_date_picker, {
                "hide-weekdays": "",
                modelValue: _ctx.dateTo,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateTo) = $event)),
                title: "По",
                min: _ctx.dateFrom,
                max: _ctx.dateToMaxTo,
                color: "green-accent-2",
                onInput: _ctx.updateDatepicker,
                onDateSelect: _ctx.changeDateTo
              }, null, 8, ["modelValue", "min", "max", "onInput", "onDateSelect"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("button", {
          class: "secondary-white-button",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.getChosenPreorderList && _ctx.getChosenPreorderList(...args)))
        }, " Показать ")
      ]),
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.preorderList.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.preorderList, (preorder, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "orderElem",
                      onClick: ($event: any) => (_ctx.showPreorderItem(index))
                    }, [
                      _createElementVNode("h4", _hoisted_8, _toDisplayString(preorder.name), 1),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("h4", _hoisted_10, _toDisplayString(preorder.date), 1),
                        _createElementVNode("h4", _hoisted_11, _toDisplayString(preorder.id), 1)
                      ]),
                      _createVNode(_component_v_dialog, {
                        modelValue: _ctx.showOrderCard,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showOrderCard) = $event)),
                        width: "330px",
                        "light:true": "",
                        "retain-focus": false
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, {
                            "min-height": "280",
                            "min-width": "300"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                style: {top: '7%', left: '91%' },
                                onClick: _ctx.closeRationCard
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" mdi-close ")
                                ]),
                                _: 1
                              }, 8, ["onClick"]),
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_ration_card_new, {
                                  "accepted-ration": _ctx.currentOrder,
                                  class: "body-text-roboto-medium"
                                }, null, 8, ["accepted-ration"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ], 8, _hoisted_7))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.preorderList.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, " В выбранный период времени нету совершенных предзаказов "))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_v_progress_circular, {
              size: 100,
              width: 7,
              color: "green",
              indeterminate: ""
            })
          ]))
    ])
  ]))
}