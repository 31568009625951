<template>
  <div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="content_container">
<!--          <div-->
<!--            class="menu_element pointed"-->
<!--            @click="showReminder"-->
<!--          >-->
<!--            <img-->
<!--              src="@/assets/images/wallet.png"-->
<!--              height="20"-->
<!--              width="20"-->
<!--            >-->
<!--            <h4 class="button-text-roboto-medium">-->
<!--              Остаток на счете-->
<!--            </h4>-->
<!--          </div>-->
<!--          <v-dialog-->
<!--            v-model="showReminderCard"-->
<!--            width="340px"-->
<!--            light:true-->
<!--            :retain-focus="false"-->
<!--          >-->
<!--            <v-card :style="{padding: '0px 12px'}">-->
<!--              <v-icon-->
<!--                :style="{top: '9%', left: '95%' }"-->
<!--                @click="closeReminder"-->
<!--              >-->
<!--                mdi-close-->
<!--              </v-icon>-->
<!--              <v-row style="padding: 12px; border-bottom: 1px solid #ebeaec">-->
<!--                <h3 class="title-h3-roboto-medium">-->
<!--                  Остаток ваших денежных средств на счете "Глолайм"-->
<!--                </h3>-->
<!--              </v-row>-->
<!--              <v-row style="justify-content: space-between; padding: 12px; border-bottom: 1px solid #ebeaec">-->
<!--                <h4 class="body-text-roboto-medium">-->
<!--                  Горячее питание-->
<!--                </h4>-->
<!--                <h4 class="body-text-roboto-medium">-->
<!--                  {{ accountsSum[0] }}-->
<!--                </h4>-->
<!--              </v-row>-->
<!--              <v-row style="justify-content: space-between; padding: 12px; border-bottom: 1px solid #ebeaec">-->
<!--                <h4 class="body-text-roboto-medium">-->
<!--                  Буфет-->
<!--                </h4>-->
<!--                <h4 class="body-text-roboto-medium">-->
<!--                  {{ accountsSum[1] }}-->
<!--                </h4>-->
<!--              </v-row>-->
<!--              <div style="margin-top: 24px; display: grid; padding-bottom: 40px">-->
<!--                <button-->
<!--                  class="primary-green-button"-->
<!--                  style="align-self: center"-->
<!--                >-->
<!--                  Заказать выписку-->
<!--                </button>-->
<!--              </div>-->
<!--            </v-card>-->
<!--          </v-dialog>-->
          <!--    <div class="menu_element" @click="showTariff">-->
          <!--      <img src="@/assets/images/info.png" height="20" width="20">-->
          <!--      <h4 class="button-text-roboto-medium">Действующий тариф</h4>-->
          <!--    </div>-->
          <!--    <v-dialog v-model="showTariffCard" width="350px" light:true :retain-focus="false">-->
          <!--      <v-card class="tariff_card">-->
          <!--        <v-icon @click="closeTariff" :style="{top: '9%', left: '45%' }">mdi-close</v-icon>-->
          <!--        <h3 class="title-h3-roboto-medium">Действующий тарифный план SMS/PUSH информация</h3>-->
          <!--        <table class="small-text-roboto-medium custom-table" style="border-radius: 8px; border: 1px solid #C0BEC5">-->
          <!--          <thead>-->
          <!--          <tr style="background: #ECF2F6">-->
          <!--            <td>Название</td>-->
          <!--            <td>Назначен</td>-->
          <!--            <td>Показать</td>-->
          <!--            <td>Заблокирован</td>-->
          <!--          </tr>-->
          <!--          </thead>-->
          <!--          <tr>-->
          <!--            <td>Мобильное приложение</td>-->
          <!--            <td>26.04.2019 14:40:08</td>-->
          <!--            <td style="text-decoration: underline; color: #f39614">Показать</td>-->
          <!--            <td>Примечание</td>-->
          <!--          </tr>-->
          <!--        </table>-->
          <!--        <div class="attention_notification" >-->
          <!--          <div class="attention-notification-text-box">-->
          <!--            <h4  class="body-text-roboto-medium">-->
          <!--              Внимание! Переход на другой тарифный план или отказ вступает в силу на следующий календарный день.-->
          <!--              При переходе на другой тарифный план, дополнительная плата не взимается.-->
          <!--              Для подключения тарифного плана, сумма на остатке должна быть не менее месячной стоимост тарифа</h4>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </v-card>-->
          <!--    </v-dialog>-->
          <!--    <div @click="showNotification" class="menu_element">-->
          <!--      <img src="@/assets/images/notification.png" height="20" width="20">-->
          <!--      <h4 class="button-text-roboto-medium">Уведомления</h4>-->
          <!--    </div>-->
          <!--    <v-dialog v-model="showNotificationCard" width="340px" light:true :retain-focus="false">-->
          <!--      <v-card class="notification_card">-->
          <!--        <v-icon @click="closeNotification" :style="{top: '9%', left: '45%' }">mdi-close</v-icon>-->
          <!--        <h3 class="title-h3-roboto-medium">Уведомления</h3>-->
          <!--        <v-row class="content_row">-->
          <!--        <h4 class="quote-text-roboto-regular">Сумма на балансе, при достижении которой будет отправлено SMS/PUSH-уведомление (руб.)</h4>-->
          <!--        </v-row>-->
          <!--        <v-row class="content_row">-->
          <!--          <div style="display: flex; flex-direction: row; gap: 12px">-->
          <!--          <div style="align-self: center">-->
          <!--            <label class="toggle-switch">-->
          <!--              <input type="checkbox">-->
          <!--              <div class="slider round"></div>-->
          <!--            </label>-->
          <!--          </div>-->
          <!--          <h4 class="body-text-roboto-medium" style="align-self: center">Горячее питание</h4>-->
          <!--          </div>-->
          <!--          <v-text-field solo clearable hide-details=true label="120 P" style="max-width: 79px; min-height: 36px"></v-text-field>-->
          <!--        </v-row>-->
          <!--        <v-row class="content_row">-->
          <!--          <div style="display: flex; flex-direction: row; gap: 12px">-->
          <!--            <div style="align-self: center">-->
          <!--              <label class="toggle-switch">-->
          <!--                <input type="checkbox">-->
          <!--                <div class="slider round"></div>-->
          <!--              </label>-->
          <!--            </div>-->
          <!--            <h4 class="body-text-roboto-medium" style="align-self: center">Буфет</h4>-->
          <!--          </div>-->
          <!--          <v-text-field solo clearable hide-details=true label="0 P" style="max-width: 79px"></v-text-field>-->
          <!--        </v-row>-->
          <!--        <div style="display: grid">-->
          <!--        <button class="primary-green-button">Изменить</button>-->
          <!--        </div>-->
          <!--        <div class="attention_notification" >-->
          <!--          <div class="attention-notification-text-box">-->
          <!--            <h4  class="body-text-roboto-medium">-->
          <!--              Данный функционал доступен только в школах, где предусмотрен предварительный заказ питания школьника.</h4>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </v-card>-->
          <!--    </v-dialog>-->
          <!--    <div @click="showSMSNotification" class="menu_element">-->
          <!--      <img src="@/assets/images/add_comment.png" height="20" width="20">-->
          <!--      <h4 class="button-text-roboto-medium">SMS уведомления</h4>-->
          <!--    </div>-->
          <!--    <v-dialog v-model="this.showSMSNotificationCard" width="350px" light:true :retain-focus="false">-->
          <!--      <v-card class="tariff_card">-->
          <!--        <v-icon @click="closeSMSNotification" :style="{top: '9%', left: '45%' }">mdi-close</v-icon>-->
          <!--        <h3 class="title-h3-roboto-medium">Выбор тарифного плана SMS-уведомлений</h3>-->
          <!--        <table class="small-text-roboto-medium custom-table" style="border-radius: 8px; border: 1px solid #C0BEC5">-->
          <!--          <thead>-->
          <!--          <tr style="background: #ECF2F6">-->
          <!--            <td>Название</td>-->
          <!--            <td>Стоимость</td>-->
          <!--            <td>Действие</td>-->
          <!--          </tr>-->
          <!--          </thead>-->
          <!--          <tr>-->
          <!--            <td>Базовый</td>-->
          <!--            <td>60 р/мес.</td>-->
          <!--            <td style="color: #f39614; text-decoration: underline">Подписаться</td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td>Расширенный</td>-->
          <!--            <td>180 р/мес.</td>-->
          <!--            <td style="color: #f39614; text-decoration: underline">Подписаться</td>-->
          <!--          </tr>-->
          <!--        </table>-->
          <!--        <h3 class="title-h3-roboto-medium">Полная мобильная версия личного кабинета (включает PUSH-уведомления)</h3>-->
          <!--        <table class="small-text-roboto-medium custom-table" style="border-radius: 8px; border: 1px solid #C0BEC5">-->
          <!--          <thead>-->
          <!--          <tr style="background: #ECF2F6">-->
          <!--            <td>Название</td>-->
          <!--            <td>Стоимость</td>-->
          <!--            <td>Действие</td>-->
          <!--          </tr>-->
          <!--          </thead>-->
          <!--          <tr>-->
          <!--            <td>Мобильное приложение (PUSH)</td>-->
          <!--            <td>80 р/мес.</td>-->
          <!--            <td style="color: #f39614; text-decoration: underline">Подписаться</td>-->
          <!--          </tr>-->
          <!--        </table>-->
          <!--        <h3 class="title-h3-roboto-medium">Описание</h3>-->
          <!--        <div style="display: flex; flex-direction: row; gap: 12px">-->
          <!--          <button class="secondary-white-button" style="width: 162px">Расчетов</button>-->
          <!--          <button class="secondary-white-button" style="width: 162px">Тарифов</button>-->
          <!--        </div>-->
          <!--          <h4 class="quote-text-roboto-medium">Вы подписали <span class="link_text"> соглашение об обработке персональных данных </span> 08.10.2018 21:49:40</h4>-->
          <!--      </v-card>-->
          <!--    </v-dialog>-->
          <div
            class="menu_element pointed"
            @click="showChangeCredentials"
          >
            <img
              src="@/assets/images/passkey.png"
              height="20"
              width="20"
            >
            <h4 class="button-text-roboto-medium">
              Изменить логин и пароль
            </h4>
          </div>
          <v-dialog
            v-model="showChangeCredentialsCard"
            width="340px"
            light:true
            :retain-focus="false"
          >
            <v-card class="change_credentials_card">
              <v-icon
                :style="{top: '9%', left: '95%' }"
                @click="closeChangeCredentials"
              >
                mdi-close
              </v-icon>
              <h3 class="title-h3-roboto-medium">
                Изменить логин и пароль
              </h3>
              <v-text-field
                v-model="newLogin"
                variant="solo"
                clearable
                hide-details="true"
                label="Новый логин"
                style="width: 317px"
              />
              <v-text-field
                v-model="oldPassword"
                variant="solo"
                clearable
                hide-details="true"
                label="Старый пароль"
                style="width: 317px"
              />
              <v-text-field
                v-model="newPassword[0]"
                variant="solo"
                clearable
                hide-details="true"
                label="Новый пароль"
                style="width: 317px"
              />
              <v-text-field
                v-model="newPassword[1]"
                variant="solo"
                clearable
                hide-details="true"
                label="Новый пароль (еще раз)"
                style="width: 317px"
              />
              <button
                :disabled="!newLogin || !oldPassword || !newPassword[0] || !newPassword[1] || newPassword[0] !== newPassword[1]"
                class="primary-green-button"
                style="margin-top: 4px"
                @click="changePassword"
              >
                Сохранить
              </button>
              <div class="attention_notification">
                <div class="attention-notification-text-box">
                  <h4 class="body-text-roboto-medium">
                    Все поля обязательны для заполнения
                  </h4>
                </div>
              </div>
            </v-card>
          </v-dialog>
          <!--    <div @click="showTelegramPermission" class="menu_element">-->
          <!--      <img src="@/assets/images/security.png" height="20" width="20">-->
          <!--      <h4 class="button-text-roboto-medium">Разрешения телеграм-каналам</h4>-->
          <!--    </div>-->
          <!--    <v-dialog v-model="showTelegramPermissionsCard" width="350px" light:true :retain-focus="false">-->
          <!--      <v-card class="tariff_card">-->
          <!--        <v-icon @click="closeTelegramPermission" :style="{top: '9%', left: '45%' }">mdi-close</v-icon>-->
          <!--        <h3 class="title-h3-roboto-medium">Разрешения о предоставлении персональных данных телеграм-каналам</h3>-->
          <!--        <table class="small-text-roboto-medium custom-table" style="border-radius: 8px; border: 1px solid #C0BEC5">-->
          <!--          <thead>-->
          <!--          <tr style="background: #ECF2F6">-->
          <!--            <td>ID канала</td>-->
          <!--            <td>Имя канала</td>-->
          <!--            <td>Разрешено</td>-->
          <!--            <td>Действие</td>-->
          <!--          </tr>-->
          <!--          </thead>-->
          <!--          <tr>-->
          <!--            <td>521226114</td>-->
          <!--            <td>Чирков Владимир-->
          <!--              (VladimirChirkov)</td>-->
          <!--            <td>03.10.2023 12:56:28</td>-->
          <!--            <td></td>-->
          <!--          </tr>-->
          <!--          <tr>-->
          <!--            <td>521226114</td>-->
          <!--            <td>Grigoriev Alexandr (AVGrigorev)</td>-->
          <!--            <td>17.01.2023 10:56:24</td>-->
          <!--            <td></td>-->
          <!--          </tr>-->
          <!--        </table>-->
          <!--        <div class="attention_notification" >-->
          <!--          <div class="attention-notification-text-box">-->
          <!--            <h4  class="body-text-roboto-medium">-->
          <!--              Внимание! Переход на другой тарифный план или отказ вступает в силу на следующий календарный день.-->
          <!--              При переходе на другой тарифный план, дополнительная плата не взимается.-->
          <!--              Для подключения тарифного плана, сумма на остатке должна быть не менее месячной стоимост тарифа</h4>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </v-card>-->
          <!--    </v-dialog>-->
          <div
            class="menu_element pointed"
            @click="showRemoveKid"
          >
            <img
              src="@/assets/images/group.png"
              height="20"
              width="20"
            >
            <h4 class="button-text-roboto-medium">
              Открепить аккаунт ребенка
            </h4>
          </div>
          <v-dialog
            v-model="showRemoveKidCard"
            width="350px"
            light:true
            :retain-focus="false"
          >
            <v-card class="tariff_card">
              <v-icon
                :style="{top: '9%', left: '95%' }"
                @click="closeRemoveKid"
              >
                mdi-close
              </v-icon>
              <h3 class="title-h3-roboto-medium">
                Открепить аккаунт ребенка
              </h3>
              <!--        <v-row style="display: flex; justify-content: space-between; flex-direction: row; padding: 12px; border-bottom: 1px solid #ebeaec">-->
              <!--          <h4 class="body-text-roboto-medium" style="align-self: center">Максим Г.</h4>-->
              <!--          <button class="primary-green-button">Открепить</button>-->
              <!--        </v-row>-->
              <v-row
                v-for="user in authUsers"
                :key="user.user.id"
                style="display: flex; justify-content: space-between; flex-direction: row; padding: 12px; border-bottom: 1px solid #ebeaec"
              >
                <h4
                  class="body-text-roboto-medium"
                  style="align-self: center"
                >
                  {{ user.user.firstName + ' ' + user.user.lastName.charAt(0) + '.' }}
                </h4>
                <button class="primary-green-button">
                  Открепить
                </button>
              </v-row>
              <br>
            </v-card>
          </v-dialog>
          <div
            class="menu_element pointed"
            @click="showChangePayment"
          >
            <img
              src="@/assets/images/wallet.png"
              height="20"
              width="20"
            >
            <h4 class="button-text-roboto-medium">
              Привязанные карты
            </h4>
          </div>
          <v-dialog
            v-model="showChangePaymentCard"
            width="350px"
            light:true
            :retain-focus="false"
          >
            <v-card class="tariff_card">
              <v-icon
                :style="{top: '9%', left: '95%' }"
                @click="closeChangePayment"
              >
                mdi-close
              </v-icon>
              <h3 class="title-h3-roboto-medium">
                Cпособы оплаты
              </h3>
              <!--        <button class="secondary-white-button" style="margin-bottom: 10px">Добавить способ оплаты</button>-->
              <h4 class="title-h4-roboto-medium">
                Привязанные карты
              </h4>
              <v-row style="display: flex; justify-content: space-between; flex-direction: row; padding: 12px; border-bottom: 1px solid #ebeaec">
                <h4
                  class="body-text-roboto-medium"
                  style="align-self: center"
                >
                  4640 ХХХХ ХХХХ 1111
                </h4>
                <button
                  class="primary-green-button"
                  @click="deleteBindedCard"
                >
                  Удалить
                </button>
              </v-row>
              <v-row style="display: flex; justify-content: space-between; flex-direction: row; padding: 12px; border-bottom: 1px solid #ebeaec">
                <h4
                  class="body-text-roboto-medium"
                  style="align-self: center"
                >
                  4640 ХХХХ ХХХХ 1111
                </h4>
                <button
                  class="primary-green-button"
                  @click="deleteBindedCard"
                >
                  Удалить
                </button>
              </v-row>
              <br>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { changePassword, deleteBinding } from '@/shared/api/parents/settings/api-requests'
import { getBindings } from '@/shared/api/parents/singlePayment/api-requests'
import { moneyAccountsFind } from '@/shared/api/parents/moneyAccounting/api-requests'
import { AccountsFindItem } from '@/shared/api/parents/moneyAccounting/api-types'
import { TelegramAuthUsersApiRequest, TelegramAuthUsersItem } from '@/shared/api/preorders/api-types'
import { telegramAuthUsers } from '@/shared/api/preorders/api-requests'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsSettings',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      showReminderCard: false,
      showTariffCard: false,
      showChangeCredentialsCard: false,
      showNotificationCard: false,
      showTelegramPermissionsCard: false,
      showSMSNotificationCard: false,
      showRemoveKidCard: false,
      showChangePaymentCard: false,
      newLogin: '',
      oldPassword: '',
      newPassword: [] as string[],
      accountsSum: [] as number[],
      isTelegram: false,
      isLoading: false,
      authUsers: null as null | TelegramAuthUsersItem[]
    }
  },
  async beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.isLoading = true
    this.currentUser = this.user.person
    await this.getBindedCards()
    await this.getAccountBalance()
    await this.getAuthUsersSettings()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async changePassword () {
      try {
        if (this.newLogin && this.newPassword && this.oldPassword) {
          console.log('21')
        }
        const changePasswordReqObj = {
          login: this.newLogin,
          oldPassword: this.oldPassword,
          password: this.newPassword[1]
        }
        const changePasswordRes = await changePassword(changePasswordReqObj, this.isTelegram)
        if (changePasswordRes.data) {
          this.setSnackbar({
            showing: true,
            text: 'Данные успешно изменены',
            timeout: 3000
          })
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось изменить пароль!',
          timeout: 3000
        })
      } finally {
        this.newPassword = []
        this.oldPassword = ''
        this.newLogin = ''
      }
    },
    async getBindedCards () {
      try {
        const getBindedCardsReqObj = {
          userId: this.currentUser.id,
          accountTypeId: 1 // todo fix later ?? this.currentUser.userType
        }
        const getBindedCardsRes = await getBindings(getBindedCardsReqObj, this.isTelegram)
        console.log(getBindedCardsRes.data)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список привязанных карт!',
          timeout: 3000
        })
      }
    },
    async deleteBindedCard () {
      try {
        const deleteBindedCardReqObj = {
          bindingId: 0, // todo fix later
          userId: this.currentUser.id,
          accountTypeId: 0 // todo fix later ?? this.currentUser.userType
        }
        const deleteBindedCardRes = await deleteBinding(deleteBindedCardReqObj, this.isTelegram)
        console.log(deleteBindedCardRes.data)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось удалить привязанную карту!',
          timeout: 3000
        })
      }
    },
    async getAccountBalance () {
      try {
        const getAccountBalanceReqObj = {
          userId: this.currentUser.id,
          accountTypeId: null,
          accountTypes: [1, 2]
        }
        const getAccountBalanceRes = await moneyAccountsFind(getAccountBalanceReqObj, this.isTelegram)
        getAccountBalanceRes.data.forEach((account: AccountsFindItem) => {
          this.accountsSum.push(account.sum)
        })
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить баланс счетов!',
          timeout: 3000
        })
      }
    },
    async getAuthUsersSettings () {
      console.log('getAuthUsers')
      try {
        const chatId = localStorage.getItem('chatId')
        const tgToken = localStorage.getItem('tgToken')
        if (chatId !== null && tgToken !== null) {
          const telegramAuthUsersReqObj: TelegramAuthUsersApiRequest = {
            chatId: chatId,
            token: tgToken
          }

          const res = await telegramAuthUsers(telegramAuthUsersReqObj)
          if (res.data?.length > 0) {
            this.authUsers = res.data
          }
          console.log('telegramAuthUsers', res)
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список авторизованных пользователей!',
          timeout: 3000
        })
      } finally {
      }
    },
    goToHome () {
      this.$router.push('./home')
    },
    showReminder () {
      this.showReminderCard = true
    },
    closeReminder () {
      this.showReminderCard = false
    },
    showTariff () {
      this.showTariffCard = true
    },
    closeTariff () {
      this.showTariffCard = false
    },
    showChangeCredentials () {
      this.showChangeCredentialsCard = true
    },
    closeChangeCredentials () {
      this.showChangeCredentialsCard = false
    },
    showNotification () {
      this.showNotificationCard = true
    },
    closeNotification () {
      this.showNotificationCard = false
    },
    showTelegramPermission () {
      this.showTelegramPermissionsCard = true
    },
    closeTelegramPermission () {
      this.showTelegramPermissionsCard = false
    },
    showSMSNotification () {
      this.showSMSNotificationCard = true
    },
    closeSMSNotification () {
      this.showSMSNotificationCard = false
    },
    showRemoveKid () {
      this.showRemoveKidCard = true
    },
    closeRemoveKid () {
      this.showRemoveKidCard = false
    },
    showChangePayment () {
      this.showChangePaymentCard = true
    },
    closeChangePayment () {
      this.showChangePaymentCard = false
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";

.content_container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 550px;
}
.menu_element {
  padding: 13px 28px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: rgba(255, 255, 255);
  align-items: flex-start;
  border: 1px solid $color-dark-50;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.tariff_card {
  padding: 10px 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.notification_card {
  padding: 10px 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.change_credentials_card {
  padding: 10px 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.content_row {
  padding: 12px 0px;
  margin: 0px 1px;
  display: flex;
  border-bottom: 2px solid $color-dark-50;
  justify-content: space-between
}
.custom-table {
  border-radius: 8px;
  border: 1px solid #C0BEC5;
  border-collapse: collapse;
  td {
    padding: 5px 5px;
    border-collapse: collapse;
    border: 1px solid #C0BEC5;
  }
  td:first-child {
    border-top-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
  }
}
.link_text {
  color: #f39614;
  text-decoration: underline;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    padding: 0px;
    background: transparent;
    width: 100%;
  }
}
</style>
