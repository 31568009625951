import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0fd0f898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "whole_page" }
const _hoisted_2 = { class: "page_container" }
const _hoisted_3 = {
  key: 0,
  class: "news_block"
}
const _hoisted_4 = { class: "title-h4-roboto-medium" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsList, (newsItem, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "news-list-item"
              }, [
                _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.moment(newsItem.publishedDate).format('DD.MM.YYYY')), 1),
                _createElementVNode("div", {
                  innerHTML: newsItem.text
                }, null, 8, _hoisted_5)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_v_progress_circular, {
              size: 100,
              width: 7,
              color: "green",
              indeterminate: ""
            })
          ]))
    ])
  ]))
}