<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col
        cols="7"
        sm="7"
      >
        <filters-panel
          :filter-options="filterOptions"
          :filter-state="filterState"
          @filter-changed-state="filterChangedHandler"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <class-selector
          :place-id-props="filterState['placeFilter']"
          @on-change="classSelectorChangeHandler"
          @parallel-select="createReport"
        />
      </v-col>
      <v-col
        class="d-flex flex-column"
        cols="3"
        sm="3"
      >
        <v-btn
          :style="{maxHeight: '36px'}"
          class="mb-1"
          :is-loading="isLoading"
          block
          @click="goToSettings()"
        >
          Настроить
        </v-btn>
        <template v-if="parallels?.parallels?.length > 0">
          <v-btn
            :style="{maxHeight: '36px'}"
            :is-loading="isLoading"
            block
            @click="load()"
          >
            Показать
          </v-btn>
        </template>
        <template v-else>
          <p> Необходимо настроить параллели</p>
        </template>
      </v-col>
      <v-col
        v-if="!isLoading && isPost && headerPlaceProducts.length > 0"
        class="d-flex"
        cols="1"
        sm="1"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="!isLoading && isPost && products.length > 0"
      class="justify-content-center"
    >
      <v-col>
        <v-select
          v-model="selectedProductSelect"
          :items="products"
          item-title="name"
          item-value="id"
          label="Вид питания"
          @update:model-value="createReport"
        />
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <div id="printableArea">
        <template v-if="headerPlaceProducts.length > 0">
          <v-row
            v-if="isPost && products.length > 0"
            class="justify-content-center"
          >
            <v-col>
              <h3 class="text-center print-header">
                Акт реализации талонов на бесплатное питание учащихся<br>
                за {{ selectedMonthName }} {{ selectedYearName }} года (бюджет, льготное питание)<br>
                {{ selectedUserAllowedPlaceName }}<br>{{ parallelsHeadName }} классы ({{ getProductName() }})
              </h3>
            </v-col>
          </v-row>
          <!--
          <v-row class="justify-content-center" v-if="!isLoading && isPost && errorDates.length > 0">
            <span class="text-warning">Ошибки в талонах за: </span><template v-for="(errorDate, i) in errorDates"><span class="span-with-space" v-bind:key="i"><i> {{ errorDate }} </i></span></template><span v-if="errorDates.length > 1" class="text-warning span-with-space"> числа</span><span v-else class="text-warning span-with-space"> число</span>
          </v-row>
          <v-row class="justify-content-center" v-if="!isLoading && isPost && notAcceptDates.length > 0">
            <span class="text-warning">Неучтены талоны за: </span><template v-for="(notAcceptDate, i) in notAcceptDates"><span class="span-with-space" v-bind:key="i"><i> {{ notAcceptDate }} </i></span></template><span v-if="notAcceptDates.length > 1" class="text-warning span-with-space"> числа</span><span v-else class="text-warning span-with-space"> число</span>
          </v-row>
          -->
          <v-row class="justify-content-center">
            <v-col
              v-if="isLoading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                :width="7"
                color="green"
                indeterminate
              />
            </v-col>
            <v-col
              v-if="headerPlaceRatePlans.length > 0"
              cols="8"
            >
              <p>Цена {{ getAveragePrice() }}</p>
              <v-table class="report-rateplan-day">
                <template #default>
                  <thead>
                  <tr>
                    <th
                      class="text-center"
                      rowspan="2"
                    >
                      Дата
                    </th>
                    <th
                      v-for="(headerPlaceRatePlan, i) in headerPlaceRatePlans"
                      :key="i"
                      class="text-center"
                      colspan="2"
                    >
                      {{ headerPlaceRatePlan.ratePlan.name }}<br>
                    </th>
                    <th colspan="2">
                      Всего
                    </th>
                  </tr>
                  <tr>
                    <template
                      v-for="(headerPlaceRatePlan, i) in headerPlaceRatePlans"
                      :key="i + '_' + '1'"
                    >
                      <th
                        class="text-center"
                      >
                        Кол-во
                      </th>
                      <th
                        class="text-center"
                      >
                        Сумма
                      </th>
                    </template>
                    <th>
                      Кол-во
                    </th>
                    <th>
                      Сумма
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(bodyDate, i) in bodyDates"
                    :key="i"
                  >
                    <td>
                      {{ bodyDate }}
                    </td>
                    <template
                      v-for="(headerPlaceRatePlan, j) in headerPlaceRatePlans"
                      :key="j + '_' + i + '_' + '1'"
                    >
                      <td
                        :class="compareProductPrice(headerPlaceRatePlan.product.id, headerPlaceRatePlan.type, bodyDate)"
                      >
                        {{
                          searchProductCount(headerPlaceRatePlan.product.id, headerPlaceRatePlan.type, bodyDate, headerPlaceRatePlan.ratePlan.id)
                        }}
                      </td>
                      <td
                        :class="compareProductPrice(headerPlaceRatePlan.product.id, headerPlaceRatePlan.type, bodyDate)"
                      >
                        {{
                          searchProductSum(headerPlaceRatePlan.product.id, headerPlaceRatePlan.type, bodyDate, headerPlaceRatePlan.ratePlan.id)
                        }}
                      </td>
                    </template>
                    <td>
                      {{ getCount(bodyDate) }}
                    </td>
                    <td>
                      {{ getSum(bodyDate) }}
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th>
                      ИТОГО
                    </th>
                    <template
                      v-for="(headerPlaceRatePlan, j) in headerPlaceRatePlans"
                      :key="j + '_' + '1'"
                    >
                      <th
                        class="text-center"
                      >
                        {{
                          getProductCount(headerPlaceRatePlan.product.id, headerPlaceRatePlan.type, headerPlaceRatePlan.ratePlan.id)
                        }}
                      </th>
                      <th
                        class="text-center"
                      >
                        {{
                          getProductSum(headerPlaceRatePlan.product.id, headerPlaceRatePlan.type, headerPlaceRatePlan.ratePlan.id)
                        }}
                      </th>
                    </template>
                    <th>
                      {{ getAllCount() }}
                    </th>
                    <th>
                      {{ getAllSum() }}
                    </th>
                  </tr>
                  </tfoot>
                </template>
              </v-table>
            </v-col>
            <v-col
              v-if="comparePlaceProducts.length > 0"
              cols="2"
              class="hidden-print"
            >
              <v-row>
                Проверка цены:
              </v-row>
              <v-row>
                <v-select
                  v-model="selectedHeaderPlaceProduct"
                  :items="comparePlaceProducts"
                  item-title="name"
                  item-value="id"
                  label="Продукт"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="selectedProductPrice"
                  label="Цена"
                />
              </v-row>
              <v-row>
                <v-btn @click="createReport()">
                  Проверить
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="headerPlaceProducts.length > 0">
            <v-col class="print-sign">
              Директор школы _____________________________/{{ customerPlaceInfo.directorName }}/
            </v-col>
          </v-row>
          <v-row v-if="headerPlaceProducts.length > 0">
            <v-col class="print-sign">
              Организатор школьного питания _____________________________/{{ customerPlaceInfo.ladminName }}/
            </v-col>
          </v-row>
        </template>
        <template v-else-if="isPost">
          <v-row class="justify-content-center">
            <v-col>
              <h3 class="text-center">
                По заданным фильтрам записей не найдено!
              </h3>
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
    <template v-else>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import ClassSelector from '@/shared/uikit/selectors/ClassSelector.vue'
import { mapActions, mapGetters } from 'vuex'
import { PlaceElectronTalonFindWithItemsAndRatePlansPost } from '@/shared/api/placeElectronTalon/api-types'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { findWithItemsAndRatePlans } from '@/shared/api/placeElectronTalon/api-requests'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'
import FiltersPanel, { FilterNames, FilterOptions } from '@/shared/uikit/FiltersPanel.vue'
import { printer } from '@/shared/lib/print'

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlaceName: string;
  selectedMonthName: string;
  selectedYearName: string;
  parallels: any;
  selectedProductSelect: any;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsAndRatePlansPost;
  customerPlacePost: CustomerPlaceFindPost;
  placeElectronTalonsAnswere: any;
  customerPlaceInfo: any;
  placeElectronTalons: any;
  places: any;
  products: any;
  ratePlans: any;
  notAcceptDates: any;
  errorDates: any;
  headerPlaceProducts: any;
  comparePlaceProducts: any;
  selectedProductPrice: any;
  selectedHeaderPlaceProduct: any;
  headerPlaceRatePlans: any;
  bodyPlaceProducts: any;
  bodyPlaceRatePlans: any;
  bodyByDatePlaceProducts: any;
  bodyByDatePlaceRatePlans: any;
  bodyDates: any;
  productDatePrices: any;
  datePrices: any;
  parallelsHeadName: any;
  filterOptions: FilterOptions;
  filterState: any;
}

export default defineComponent({
  components: {
    FiltersPanel,
    ClassSelector
  },
  data (): BaseData {
    return {
      filterState: {
        placeFilter: 0,
        classFilter: 0,
        productTypeFilter: 0,
        ratePlanFilter: 0,
        periodFilter: [],
        monthFilter: moment().month(),
        yearFilter: moment().year(),
        initialState: false
      } as any,
      filterOptions: {
        placeFilter: true,
        classFilter: false,
        productTypeFilter: false,
        ratePlanFilter: false,
        monthFilter: true,
        yearFilter: true,
        classItems: [],
        productTypeItems: [],
        placeItems: [],
        ratePlanItems: [],
        periodFilter: true
      } as FilterOptions,
      isLoading: false,
      isPost: false,
      selectedUserAllowedPlaceName: '',
      selectedMonthName: 'Неизвестно',
      selectedYearName: 'Неизвестно',
      parallels: {
        parallels: [], selectedParallel: 0
      },
      selectedProductSelect: null,
      placeElectronTalonGetByIdPost: {
        parentPlaceId: 0,
        dateFrom: '',
        dateTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      placeElectronTalonsAnswere: [],
      customerPlaceInfo: null,
      placeElectronTalons: [],
      places: [],
      errorDates: [],
      notAcceptDates: [],
      products: [],
      ratePlans: [],
      headerPlaceProducts: [],
      comparePlaceProducts: [],
      selectedHeaderPlaceProduct: null,
      selectedProductPrice: null,
      headerPlaceRatePlans: [],
      bodyPlaceProducts: [],
      bodyPlaceRatePlans: [],
      bodyByDatePlaceProducts: [],
      bodyByDatePlaceRatePlans: [],
      bodyDates: [],
      productDatePrices: [],
      datePrices: [],
      parallelsHeadName: ''
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  async beforeMount () {
    this.isLoading = true
    const userAllowedPlaces = await this.userAllowedPlaceAll()
    this.filterOptions.placeItems = userAllowedPlaces
    this.filterState[FilterNames.PLACE_FILTER] = userAllowedPlaces[0].placeId
    await this.loadCustomerPlaceInfo()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    filterChangedHandler (filterName: FilterNames, oldValue: any, newValue: any) {
      this.filterState[filterName] = newValue
    },
    async loadPlaceElectronTalons () {
      try {
        this.isLoading = true
        if (this.filterOptions.placeItems.length !== 0) {
          this.selectedUserAllowedPlaceName = this.filterOptions.placeItems.find((placeItem: any) => {
            return placeItem.placeId === this.filterState[FilterNames.PLACE_FILTER]
          }).place.name
          this.placeElectronTalonGetByIdPost.parentPlaceId = this.filterState[FilterNames.PLACE_FILTER]
          // this.placeElectronTalonGetByIdPost.dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).startOf('month').format('YYYY-MM-DD')
          // this.placeElectronTalonGetByIdPost.dateTo = moment(`${this.placeElectronTalonGetByIdPost.dateFrom}`).add(1, 'M').startOf('month').format('YYYY-MM-DD')
          if (this.filterState[FilterNames.PERIOD_FILTER].length >= 2){
            this.placeElectronTalonGetByIdPost.dateFrom = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).add('days', 1).format('YYYY-MM-DD')
          } else if (this.filterState[FilterNames.PERIOD_FILTER].length === 1) {
            this.placeElectronTalonGetByIdPost.dateFrom = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).add('days', 1).format('YYYY-MM-DD')
          } else {
            this.placeElectronTalonGetByIdPost.dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).startOf('month').format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).endOf('month').format('YYYY-MM-DD')
          }
          const result = await findWithItemsAndRatePlans(this.placeElectronTalonGetByIdPost)
          this.placeElectronTalonsAnswere = JSON.parse(JSON.stringify(result))
          if (Array.isArray(this.placeElectronTalonsAnswere.placeEletronTalons)) {
            this.placeElectronTalons = this.placeElectronTalonsAnswere.placeEletronTalons
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.products)) {
            this.products = this.placeElectronTalonsAnswere.products
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.places)) {
            this.places = this.placeElectronTalonsAnswere.places
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.ratePlans)) {
            this.ratePlans = this.placeElectronTalonsAnswere.ratePlans
          }
          if (this.places.length > 0 && this.products.length > 0 && this.ratePlans.length > 0 && this.placeElectronTalons.length > 0) {
            this.selectedProductSelect = this.products[0].id
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.filterOptions.placeItems.length !== 0) {
          this.customerPlacePost.placeId = this.filterState[FilterNames.PLACE_FILTER]
          if (this.filterState[FilterNames.PERIOD_FILTER].length >= 2) {
            this.customerPlacePost.date = moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).format('YYYY-MM-DD')
          } else if (this.filterState[FilterNames.PERIOD_FILTER].length === 1) {
            this.customerPlacePost.date = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
          } else {
            this.customerPlacePost.date = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
          }
          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    classSelectorChangeHandler (event: any) {
      this.parallels = event
    },
    createReport () {
      this.clearData()
      this.places.forEach((element: any) => {
        let classType = 0
        this.parallels.parallels.forEach((el: any) => {
          if (element.name.match(/^[1-4][^0-9].*|^[1-4]|[5-9].*|^[5-9]|[1-4][0-3].*$|^[1-4][0-3]$/)) {
            if (parseInt(element.name) >= el.startValue && parseInt(element.name) <= el.endValue) {
              classType = el.classType
            }
          }
        })
        element.classType = classType
      })
      this.parallelsHeadName = this.parallels.parallels.find((parallel: any) => parallel.id === this.parallels?.selectedParallel?.id)?.name
      this.placeElectronTalons.forEach((placeElectronTalon: any) => {
        placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
          if (placeElectronTalonItem.removed == null) {
            placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
              if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId != null) {
                if (this.selectedProductSelect === placeElectronTalonItemSale.productId) {
                  this.places.forEach((place: any) => {
                    if (place.id === placeElectronTalon.placeId && place.classType === this.parallels.selectedParallel.id) {
                      let productName = ''
                      let ratePlanName = ''
                      let productPrice = 0
                      this.products.forEach((product: any) => {
                        if (product.id === placeElectronTalonItemSale.productId) {
                          placeElectronTalon.placeElectronTalonProducts.forEach((placeElectronTalonProduct: any) => {
                            if (product.id === placeElectronTalonProduct.productId) {
                              productPrice = placeElectronTalonProduct.price
                              this.productDatePrices.push({
                                date: moment(placeElectronTalon.date).format('DD.MM.YYYY'),
                                product: placeElectronTalonItemSale.productId,
                                price: placeElectronTalonProduct.price
                              })
                            }
                          })
                          productName = product.name
                        }
                      })

                      if (!this.headerPlaceProducts.find((el: any) => el.type === place.classType && el.product.id === placeElectronTalonItemSale.productId)) {
                        this.headerPlaceProducts.push({
                          type: place.classType,
                          product: { id: placeElectronTalonItemSale.productId, name: productName }
                        })
                        this.comparePlaceProducts.push({ id: placeElectronTalonItemSale.productId, name: productName })
                      }
                      this.bodyPlaceProducts.push({
                        date: moment(placeElectronTalon.date).format('DD.MM.YYYY'),
                        product: placeElectronTalonItemSale.productId,
                        type: place.classType,
                        sum: productPrice
                      })

                      this.ratePlans.forEach((ratePlan: any) => {
                        if (ratePlan.id === placeElectronTalonItem.userRatePlan) {
                          ratePlanName = ratePlan.name
                        }
                      })
                      if (!this.headerPlaceRatePlans.find((el: any) => el.type === place.classType && el.product.id === placeElectronTalonItemSale.productId && el.ratePlan.id === placeElectronTalonItem.userRatePlan)) {
                        this.headerPlaceRatePlans.push({
                          type: place.classType,
                          ratePlan: { id: placeElectronTalonItem.userRatePlan, name: ratePlanName },
                          product: { id: placeElectronTalonItemSale.productId, name: productName }
                        })
                      }
                      this.bodyPlaceRatePlans.push({
                        date: moment(placeElectronTalon.date).format('DD.MM.YYYY'),
                        product: placeElectronTalonItemSale.productId,
                        type: place.classType,
                        sum: productPrice,
                        ratePlan: placeElectronTalonItem.userRatePlan
                      })
                    }
                  })
                }
              } else {
                if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                  if (!this.notAcceptDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notAcceptDates.push(moment(placeElectronTalon.date).format('DD'))
                }
              }
            })
          }
          if (placeElectronTalonItem.error != null) {
            if (String(placeElectronTalonItem.error) !== '15') {
              if (!this.errorDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.errorDates.push(moment(placeElectronTalon.date).format('DD'))
            }
          }
        })
      })
      this.bodyPlaceProducts.forEach((bodyPlaceProduct: any) => {
        if (!this.bodyDates.find((el: any) => el === bodyPlaceProduct.date)) {
          this.bodyDates.push(bodyPlaceProduct.date)
        }
        if (!this.bodyByDatePlaceProducts.find((el: any) => el.type === bodyPlaceProduct.type && el.product === bodyPlaceProduct.product && el.date === bodyPlaceProduct.date)) {
          this.bodyByDatePlaceProducts.push({
            date: bodyPlaceProduct.date,
            product: bodyPlaceProduct.product,
            type: bodyPlaceProduct.type,
            count: 1,
            sum: Number(bodyPlaceProduct.sum)
          })
        } else {
          this.bodyByDatePlaceProducts.forEach((bodyByDatePlaceProduct: any) => {
            if (bodyByDatePlaceProduct.type === bodyPlaceProduct.type && bodyByDatePlaceProduct.product === bodyPlaceProduct.product && bodyByDatePlaceProduct.date === bodyPlaceProduct.date) {
              bodyByDatePlaceProduct.count = bodyByDatePlaceProduct.count + 1
              bodyByDatePlaceProduct.sum = bodyByDatePlaceProduct.sum + Number(bodyPlaceProduct.sum)
            }
          })
        }
      })

      this.bodyPlaceRatePlans.forEach((bodyPlaceRatePlan: any) => {
        if (!this.bodyDates.find((el: any) => el === bodyPlaceRatePlan.date)) {
          this.bodyDates.push(bodyPlaceRatePlan.date)
        }
        if (!this.bodyByDatePlaceRatePlans.find((el: any) => el.type === bodyPlaceRatePlan.type && el.product === bodyPlaceRatePlan.product && el.date === bodyPlaceRatePlan.date && el.ratePlan === bodyPlaceRatePlan.ratePlan)) {
          this.bodyByDatePlaceRatePlans.push({
            date: bodyPlaceRatePlan.date,
            product: bodyPlaceRatePlan.product,
            type: bodyPlaceRatePlan.type,
            ratePlan: bodyPlaceRatePlan.ratePlan,
            count: 1,
            sum: Number(bodyPlaceRatePlan.sum)
          })
        } else {
          this.bodyByDatePlaceRatePlans.forEach((bodyByDatePlaceRatePlan: any) => {
            if (bodyByDatePlaceRatePlan.type === bodyPlaceRatePlan.type && bodyByDatePlaceRatePlan.product === bodyPlaceRatePlan.product && bodyByDatePlaceRatePlan.date === bodyPlaceRatePlan.date && bodyByDatePlaceRatePlan.ratePlan === bodyPlaceRatePlan.ratePlan) {
              bodyByDatePlaceRatePlan.count = bodyByDatePlaceRatePlan.count + 1
              bodyByDatePlaceRatePlan.sum = bodyByDatePlaceRatePlan.sum + Number(bodyPlaceRatePlan.sum)
            }
          })
        }
      })

      this.productDatePrices.forEach((productDatePrice: any) => {
        if (!this.datePrices.find((el: any) => el.product === productDatePrice.product && el.date === productDatePrice.date)) {
          this.datePrices.push({
            date: productDatePrice.date,
            product: productDatePrice.product,
            price: Number(productDatePrice.price),
            count: 1
          })
        } else {
          this.datePrices.forEach((datePrice: any) => {
            if (datePrice.product === productDatePrice.product && datePrice.date === productDatePrice.date) {
              datePrice.count = datePrice.count + 1
              datePrice.price = datePrice.price + Number(productDatePrice.price)
            }
          })
        }
      })

      this.bodyDates.sort((a: Date, b: Date) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      this.headerPlaceProducts.sort((a: any, b: any) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : ((a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0))))
      this.headerPlaceRatePlans.sort((a: any, b: any) => (a.ratePlan.name > b.ratePlan.name) ? 1 : ((b.ratePlan.name > a.ratePlan.name) ? -1 : 0))
    },
    goToSettings () {
      this.$router.push({
        name: 'ReportSettings',
        query: {
          placeId: this.filterState[FilterNames.PLACE_FILTER],
          report: 'ReportRatePlanDayRatePlan'
        }
      })
    },
    clearData () {
      this.headerPlaceProducts = []
      this.headerPlaceRatePlans = []
      this.bodyPlaceProducts = []
      this.comparePlaceProducts = []
      this.bodyPlaceRatePlans = []
      this.bodyByDatePlaceProducts = []
      this.bodyByDatePlaceRatePlans = []
      this.bodyDates = []
      this.productDatePrices = []
      this.datePrices = []
    },
    load () {
      this.placeElectronTalons = []
      this.places = []
      this.products = []
      this.ratePlans = []
      this.errorDates = []
      this.notAcceptDates = []
      this.placeElectronTalonsAnswere = []
      this.comparePlaceProducts = []
      this.selectedHeaderPlaceProduct = null
      this.selectedProductPrice = null
      this.clearData()
      this.customerPlaceInfo = null
      this.loadPlaceElectronTalons()
      this.loadCustomerPlaceInfo()
      this.isPost = true
      this.selectedMonthName = moment().locale('ru').month(this.filterState[FilterNames.MONTH_FILTER]).format('MMMM')
      this.selectedYearName = this.filterState[FilterNames.YEAR_FILTER]
    },
    compareProductPrice (productId: any, productType: any, date: any) {
      if (this.selectedHeaderPlaceProduct === null) {
        return 'text-center'
      }
      if (this.selectedHeaderPlaceProduct !== productId) {
        return 'text-center'
      }
      let productCount = 0
      let productSum = 0
      const product = this.bodyByDatePlaceProducts.find((bodyByDatePlaceProduct: any) => String(bodyByDatePlaceProduct.date) === String(date) &&
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (product !== undefined) {
        productCount = product.count
        productSum = product.sum
      }
      if (productCount === 0) {
        return 'text-center'
      }
      if ((Number(productSum) / Number(productCount)).toFixed(2) === Number(this.selectedProductPrice).toFixed(2)) {
        return 'text-center'
      }
      return 'text-center item-error'
    },
    searchProductCount (productId: any, productType: any, date: any, ratePlanId: any) {
      const product = this.bodyByDatePlaceRatePlans.find((bodyByDatePlaceRatePlan: any) => String(bodyByDatePlaceRatePlan.date) === String(date) &&
        String(bodyByDatePlaceRatePlan.product) === String(productId) && String(bodyByDatePlaceRatePlan.type) === String(productType) &&
        String(bodyByDatePlaceRatePlan.ratePlan) === String(ratePlanId))
      if (product === undefined) {
        return 0
      }
      return product.count
    },
    searchProductSum (productId: any, productType: any, date: any, ratePlanId: any) {
      const product = this.bodyByDatePlaceRatePlans.find((bodyByDatePlaceRatePlan: any) => String(bodyByDatePlaceRatePlan.date) === String(date) &&
        String(bodyByDatePlaceRatePlan.product) === String(productId) && String(bodyByDatePlaceRatePlan.type) === String(productType) &&
        String(bodyByDatePlaceRatePlan.ratePlan) === String(ratePlanId))
      if (product === undefined) {
        return 0
      }
      return product.sum.toFixed(2)
    },
    getPrice (productId: any, productType: any, ratePlanId: any) {
      const products = this.bodyByDatePlaceRatePlans.filter((bodyByDatePlaceRatePlan: any) =>
        String(bodyByDatePlaceRatePlan.product) === String(productId) && String(bodyByDatePlaceRatePlan.type) === String(productType) &&
        String(bodyByDatePlaceRatePlan.ratePlan) === String(ratePlanId))
      if (products === undefined) {
        return 0
      }
      let count = 0
      let sum = 0
      products.forEach((product: any) => {
        count = count + product.count
        sum = sum + product.sum
      })
      if (count === 0) return 0
      return (sum / count).toFixed(2)
    },
    getSum (date: any) {
      const products = this.bodyByDatePlaceRatePlans.filter((bodyByDatePlaceRatePlan: any) => String(bodyByDatePlaceRatePlan.date) === String(date))
      if (products === undefined) {
        return 0
      }
      let sum = 0
      products.forEach((product: any) => {
        sum = sum + product.sum
      })
      return sum.toFixed(2)
    },
    getCount (date: any) {
      const products = this.bodyByDatePlaceRatePlans.filter((bodyByDatePlaceRatePlan: any) => String(bodyByDatePlaceRatePlan.date) === String(date))
      if (products === undefined) {
        return 0
      }
      let count = 0
      products.forEach((product: any) => {
        count = count + product.count
      })
      return count
    },
    getProductCount (productId: any, productType: any, ratePlanId: any) {
      const products = this.bodyByDatePlaceRatePlans.filter((bodyByDatePlaceRatePlan: any) =>
        String(bodyByDatePlaceRatePlan.product) === String(productId) && String(bodyByDatePlaceRatePlan.type) === String(productType) &&
        String(bodyByDatePlaceRatePlan.ratePlan) === String(ratePlanId))
      if (products === undefined) {
        return 0
      }
      let count = 0
      products.forEach((product: any) => {
        count = count + product.count
      })

      return count
    },
    getProductSum (productId: any, productType: any, ratePlanId: any) {
      const products = this.bodyByDatePlaceRatePlans.filter((bodyByDatePlaceRatePlan: any) =>
        String(bodyByDatePlaceRatePlan.product) === String(productId) && String(bodyByDatePlaceRatePlan.type) === String(productType) &&
        String(bodyByDatePlaceRatePlan.ratePlan) === String(ratePlanId))
      if (products === undefined) {
        return 0
      }
      let sum = 0
      products.forEach((product: any) => {
        sum = sum + product.sum
      })

      return (sum).toFixed(2)
    },
    getAllSum () {
      let sum = 0
      this.bodyByDatePlaceRatePlans.forEach((bodyByDatePlaceRatePlan: any) => {
        sum = sum + bodyByDatePlaceRatePlan.sum
      })
      return sum.toFixed(2)
    },
    getAllCount () {
      let count = 0
      this.bodyByDatePlaceRatePlans.forEach((bodyByDatePlaceRatePlan: any) => {
        count = count + bodyByDatePlaceRatePlan.count
      })
      return count
    },
    getAveragePrice () {
      let sum = 0
      let count = 0
      this.bodyByDatePlaceRatePlans.forEach((bodyByDatePlaceRatePlan: any) => {
        if (bodyByDatePlaceRatePlan.product === this.selectedProductSelect) {
          sum = sum + bodyByDatePlaceRatePlan.sum
          count = count + bodyByDatePlaceRatePlan.count
        }
      })
      if (count === 0) return 0
      return (sum / count).toFixed(2)
    },
    getProductName () {
      const product = this.products.find((product: any) => product.id === this.selectedProductSelect)
      return product.name
    },
    print () {
      printer('printableArea', 'print.css')
    }
  }
})
</script>

<style lang="scss"></style>
