<!--todo fix rationCard-->
<!--todo remove 6-day toggler-->
<template>
  <div class="whole_page">
    <div class="control_panel">
      <div class="buttons-container">
        <button
          class="secondary-white-button"
          style="width: 337px; height: 46px"
          @click="getRations"
        >
          <img
            src="~@/assets/images/setting.png"
            height="20"
            width="20"
          >Настройка автоназначения рациона
        </button>
        <button
          class="secondary-white-button"
          style="width: 337px; height: 46px"
          @click="getPreordersList"
        >
          <img
            src="~@/assets/images/grading.png"
            height="20"
            width="20"
          >План заказов
        </button>
      </div>

      <div class="toggle-container">
        <div class="toggle_box">
          <div :style="{border: '5px'}">
            <label class="toggle-switch">
              <input
                v-model="userRationSetting"
                type="checkbox"
                @change="updateRationSetting"
              >
              <div class="slider round" />
            </label>
          </div>
          <div class="small-text-roboto-medium">
            <h4>Автоматическое назначение</h4>
          </div>
        </div>
        <div class="toggle_box">
          <div :style="{border: '5px'}">
            <label class="toggle-switch">
              <input
                v-model="userSixWeekSetting"
                type="checkbox"
                @change="updateUserSixWeekSetting"
              >
              <div class="slider round" />
            </label>
          </div>
          <div class="small-text-roboto-medium">
            <h4>Шестидневка</h4>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isLoading">
      <div class="some">
        <div
          v-if="rationOrders.isUserOrderExists && rationOrders.rationOrders && rationOrders.rationOrders.length > 0"
          class="menu"
        >
          <div class="ration-content">
            <h2 class="title-h2-roboto-medium">
              Предзаказ на {{ formatDateTime(rationOrders.date) }}
            </h2>
            <!--            <div align="center">-->
            <!--              <v-btn :style="{width: '90px', fontsize: 12}" @click="removePreorder">Отменить</v-btn>-->
            <!--            </div>-->
            <ration-card-new
              :accepted-ration="getCurrentRation()"
              :with-checkbox="false"
            />
          </div>
        </div>
        <div
          v-else
          class="createOrderBtn"
        >
          <template v-if="rationOrders.rationUserPreorders && rationOrders.rationUserPreorders.length > 0">
            <div class="ration-content">
              <h2 class="title-h2-roboto-medium">
                Предзаказ на {{
                  formatDateTime(rationOrders.date)
                }}
              </h2>
              <!--            <div align="center">-->
              <!--              <v-btn :style="{width: '90px', fontsize: 12}" @click="removePreorder">Отменить</v-btn>-->
              <!--            </div>-->
              <div class="ration-content__cards-list">
                <template
                  v-for="ration in getCurrentFromRationUserPreorders()"
                  :key="ration.rationId"
                >
                  <ration-card-new
                    :accepted-ration="ration"
                    :with-checkbox="false"
                  />
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="ration-content">
              <h2 class="title-h2-roboto-medium">
                На {{ formatDateTime(rationOrders.date) }}
                предзаказ не создан
              </h2>
              <template v-if="rationOrders.rationSchoolAccepts && rationOrders.rationSchoolAccepts.length > 0 ">
                <!--          <v-btn v-if="!showCreateOrder" @click="createPreorder">Создать заказ</v-btn>-->
              </template>
              <template v-else>
                <div class="attention_notification">
                  <div class="attention-notification-text-box">
                    <h4 class="body-text-roboto-medium">
                      Список доступных рационов для заказа не настроен!
                    </h4>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <template v-if="showCreateOrder">
        <div class="menu_box">
          <div class="rations_cards">
            <template v-for="acceptedRation in convertedApiData">
              <template v-if="acceptedRation.rationAspectId === 1">
                <div
                  :key="acceptedRation.rationId"
                  style="position: relative"
                >
                  <ration-card-new
                    :accepted-ration="acceptedRation"
                    :with-checkbox="false"
                  />
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { TableData } from '@/shared/uikit/BaseSimpleTable.vue'
import {
  rationOrdersFindByUserOnDate,
  userRationSettingsFind,
  userRationSettingsSave
} from '@/shared/api/preorders/api-requests'
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import {
  RationItem,
  rationOrderedProductItem,
  RationOrderItem,
  RationOrdersFindByUserOnDateApiRequest,
  RationOrdersFindByUserOnDateApiResponse,
  RationProductItem,
  rationUserPreorderItem,
  UserRationSettingsFindApiRequest,
  UserRationSettingsSaveApiRequest
} from '@/shared/api/preorders/api-types'
import moment from 'moment'
import RationCardNew from '@/components/parents/preorders/RationCardNew.vue'
import { defineComponent } from 'vue'

export type ConvertedApiType = ConvertedApiTypeItem[]

export type ConvertedApiTypeItem = {
  rationId: number;
  productId: number | null;
  accountTypeId: number | null;
  rationAspectId: number | null;
  staticPrice: number | null;
  productPrice: number | null;
  name: string;
  products: {
    name: string;
    productId: number;
    meelTypeId: number;
    price: number | null;
  }[];
}

export default defineComponent({
  name: 'PreorderForTomorrowNew',
  components: { RationCardNew },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      showCreateOrder: false,
      rationOrders: {} as RationOrdersFindByUserOnDateApiResponse['data'],
      convertedApiData: [] as ConvertedApiType,
      selectedItemIdToSave: null as null | number,
      userRationSetting: false as boolean,
      userSixWeekSetting: false as boolean,
      currentUser: {} as Person,
      tableData: {} as TableData,
      tableData2: {} as TableData,
      currDate: '08.02.2024',
      overallSum: 0,
      isPreorderForTommorow: true,
      isLoading: false
    }
  },
  async beforeMount () {
    this.isLoading = true
    this.currentUser = this.user.person
    console.log('currentUser', this.currentUser)
    await this.getUserSettings()
    this.rationOrders = await this.getRationOrders()
    this.convertedApiData = this.convertRawApi(this.rationOrders)
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getUserSettings () {
      try {
        const userRationSettingsFindReqObj: UserRationSettingsFindApiRequest = {
          id: null,
          userId: this.currentUser?.id
        }
        const res = await userRationSettingsFind(userRationSettingsFindReqObj)
        if (res?.data?.length === 0 || !res?.data) {
          this.userRationSetting = true
          this.userSixWeekSetting = false
        } else {
          const userRationSetting = res?.data.find((setting: any) => setting.rationSettingTypeId === 1)
          this.userRationSetting = !!userRationSetting?.value
          const userSixWeekSetting = res?.data.find((setting: any) => setting.rationSettingTypeId === 3)
          this.userSixWeekSetting = !!userSixWeekSetting?.value
        }
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить настройки пользователя',
          timeout: 3000
        })
      }
    },

    async updateRationSetting () {
      try {
        const userRationSettingsSaveReqObj: UserRationSettingsSaveApiRequest = {
          id: null,
          userId: this.currentUser.id,
          rationSettingTypeId: 1,
          value: this.userRationSetting ? 1 : 0
        }
        const res = await userRationSettingsSave(userRationSettingsSaveReqObj)
        this.userRationSetting = !!res.data.value
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось обновить настройку "Автоназначение рациона"!',
          timeout: 3000
        })
      }
    },
    async updateUserSixWeekSetting () {
      try {
        const userRationSettingsSaveReqObj: UserRationSettingsSaveApiRequest = {
          id: null,
          userId: this.currentUser.id,
          rationSettingTypeId: 3,
          value: this.userSixWeekSetting ? 1 : 0
        }
        const res = await userRationSettingsSave(userRationSettingsSaveReqObj)
        this.userSixWeekSetting = !!res.data.value
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось обновить настройку "Автоназначение рациона"!',
          timeout: 3000
        })
      }
    },
    getCurrentRation (): ConvertedApiTypeItem {
      const currentRationProduct = this.rationOrders.rationOrders[0].rationOrderProducts[0]
      const currentOrder = this.convertedApiData.find(convertedItem => convertedItem.productId === currentRationProduct.productId)
      if (currentOrder) {
        return currentOrder
      }
      return {} as ConvertedApiTypeItem
    },
    getCurrentFromRationUserPreorders (): ConvertedApiTypeItem[] {
      const res: ConvertedApiType = []
      this.rationOrders.rationProducts.forEach((rationProduct: RationProductItem) => {
        if (rationProduct.weekDay === this.rationOrders.day && rationProduct.weekNumber === this.rationOrders.week) {
          const existsInResIdx = res.findIndex(el => el.rationId === rationProduct.rationId)
          if (existsInResIdx !== -1) {
            const existedProductIdx = res[existsInResIdx].products.findIndex(product => product.productId === rationProduct.product.id)
            if (existedProductIdx === -1) {
              res[existsInResIdx].products.push({
                name: rationProduct.product.name,
                meelTypeId: rationProduct.meelTypeId,
                productId: rationProduct.productId,
                price: rationProduct.price
              })
            }
            // else {
            // Варианта когда есть рацион без продуктов быть не может
            // }
          } else {
            res.push({
              rationId: rationProduct.rationId,
              productId: null,
              accountTypeId: null,
              name: '',
              rationAspectId: null,
              staticPrice: null,
              productPrice: null,
              products: [
                {
                  name: rationProduct.product.name,
                  meelTypeId: rationProduct.meelTypeId,
                  productId: rationProduct.productId,
                  price: rationProduct.price
                }
              ]
            })
          }
        }
      })
      res.filter(ration => {
        const existInRationPreorders = this.rationOrders.rationUserPreorders.find(rationUserPreorder => rationUserPreorder.rationId === ration.rationId)
        if (existInRationPreorders) {
          ration.name = existInRationPreorders.product.name
          return true
        }
        return false
      })
      res.forEach(ration => {
        const rationInfo = this.rationOrders.rations.find(rationInfoItem => rationInfoItem.id === ration.rationId)
        if (rationInfo) {
          ration.accountTypeId = rationInfo.accountTypeId
          ration.rationAspectId = rationInfo.rationAspectId
          ration.staticPrice = rationInfo.staticPrice
        }
      })
      return res
    },
    async getRationOrders (): Promise<RationOrdersFindByUserOnDateApiResponse['data']> {
      try {
        const rationOrdersFindByUserOnDateReqObj: RationOrdersFindByUserOnDateApiRequest = {
          userId: this.currentUser.id
        }
        const res = await rationOrdersFindByUserOnDate(rationOrdersFindByUserOnDateReqObj)
        return res.data
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить заказ на ближайшую дату!',
          timeout: 3000
        })
        return {
          date: '',
          isDeadLine: true,
          day: 0,
          week: 0,
          isRationSchoolAccept: false,
          isUserOrderExists: false,
          rationOrders: [] as rationOrderedProductItem[],
          rationSchoolAccepts: [] as RationOrderItem[],
          rationUserPreorders: [] as rationUserPreorderItem[],
          rationProducts: [] as RationProductItem[],
          rations: [] as RationItem[]
        }
      }
    },
    convertRawApi (rawData: RationOrdersFindByUserOnDateApiResponse['data']): ConvertedApiType {
      const res: ConvertedApiType = []
      rawData.rationSchoolAccepts.forEach((acceptedRation: RationOrderItem) => {
        if (acceptedRation.type === 1) {
          const existsInResIdx = res.findIndex(el => el.rationId === acceptedRation.rationId)
          if (existsInResIdx === -1) {
            res.push({
              rationId: acceptedRation.rationId,
              productId: acceptedRation.product.id,
              accountTypeId: null,
              name: acceptedRation.product.name,
              rationAspectId: null,
              staticPrice: null,
              productPrice: acceptedRation.productPrice,
              products: []
            })
          } else {
            res[existsInResIdx].rationId = acceptedRation.rationId
            res[existsInResIdx].name = acceptedRation.product.name
            res[existsInResIdx].productPrice = acceptedRation.productPrice
          }
        } else if (acceptedRation.type === 2) {
          const existsInResIdx = res.findIndex(el => el.rationId === acceptedRation.rationId)
          if (existsInResIdx !== -1) {
            const existedProductIdx = res[existsInResIdx].products.findIndex(product => product.productId === acceptedRation.product.id)
            if (existedProductIdx === -1) {
              res[existsInResIdx].products.push({
                name: acceptedRation.product.name,
                meelTypeId: acceptedRation.meelTypeId,
                productId: acceptedRation.productId,
                price: acceptedRation.price
              })
            }
            // else {
            //
            // }
          } else {
            res.push({
              rationId: acceptedRation.rationId,
              productId: null,
              accountTypeId: null,
              name: '',
              rationAspectId: null,
              staticPrice: null,
              productPrice: null,
              products: [
                {
                  name: acceptedRation.product.name,
                  meelTypeId: acceptedRation.meelTypeId,
                  productId: acceptedRation.productId,
                  price: acceptedRation.price
                }
              ]
            })
          }
        }
      })
      res.forEach(ration => {
        const rationInfo = rawData.rations.find(rationInfo => rationInfo.id === ration.rationId)
        if (rationInfo) {
          ration.accountTypeId = rationInfo.accountTypeId
          ration.rationAspectId = rationInfo.rationAspectId
          ration.staticPrice = rationInfo.staticPrice
        }
      })
      return res
    },
    getLoginPage () {
      const telegramToken = localStorage.getItem('tgToken')
      const chatId = localStorage.getItem('chatId')
      this.$router.push(`./../telegramLoginNew/${chatId}&${telegramToken}`)
    },
    removePreorder () {
      this.isPreorderForTommorow = false
    },
    getPreordersList () {
      this.$router.push('./6')
    },
    getPriceList () {
      this.$router.push('./9')
    },
    getRations () {
      this.$router.push('./7')
    },
    createPreorder () {
      this.isLoading = true
      this.showCreateOrder = true
      this.isLoading = false
      // this.$router.push('./8')
    },
    selectRationToSave (rationItem: ConvertedApiTypeItem) {
      this.selectedItemIdToSave = rationItem.rationId
    },
    formatDateTime (dateTime: any) {
      if (!dateTime) {
        return null
      } else if (dateTime.length === 0) {
        return null
      }
      return moment(dateTime).format('DD.MM.YYYY')
    }
  }
})

</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.whole_page {
  background-image: url("~@/assets/images/background.png");
  background-position: center center;
  background-size: cover;
  min-height: 81vh;
  padding: 20px 16px;
}

.menu_list {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

.buttons-container {
  margin: 10px 0;
  display: flex;
  justify-content: left;
  flex-direction: column;
  gap: 12px;

  .nav-button {
  }

  .nav-button:not(:first-child) {
    margin-left: 10px;
  }
}

.menu {
  //justify-content: left;
  width: 70%;
  display: grid;
  float: left;
  margin-top: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #90ee90;
}

input:focus + .slider {
  box-shadow: 0 0 1px #90ee90;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.small-text-roboto-medium {
  //text-align: center;
}

.toggle-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  .toggle_box {
    display: flex;
    width: 20%;
    gap: 5px;
  }

  .toggle_text {
    margin-left: 5px;
  }
}

.settingRationButton {
  //margin-left: 10px;
  font-size: small;
}
.goToPreorderListBtn {
  //margin-left: 10px;
  font-size: small;
}

.goToPriceListBtn {
  margin-left: 10px;
  font-size: small;
}

.control_panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 12px;
  gap: 24px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.some {
  display: grid;
  justify-content: center;
}

.ration-content {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  padding: 25px 16px;
  gap: 19px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  .ration-content__cards-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.createOrderBtn {
  display: grid;
  justify-content: center;
  margin-top: 0px;
}

.rations_cards {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.rations_cards__checkbox {
  position: absolute;
  top: -5px;
  left: 25px;
}

.menu_box {
  margin-top: 10px;
  //justify-content: left;
  //width: 70%;
  display: flex;
  //float: left;
}

@media (max-aspect-ratio: 6/5) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    //width: 337px;
    height: 104px;

    .nav-button {
      margin: 0 5px 5px 0;
      width: 220px;
      font-size: xx-small;
    }

    .nav-button:not(:first-child) {
      margin-left: 0;
    }
  }
  .control_panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 12px;
    gap: 24px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

  }
  .toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    .toggle_box {
      display: flex;
      width: 50%;
    }

    .toggle_text {
      font-size: small;
    }
  }
  .ration-content {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-items: center;

    .ration-content__cards-list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .menu {
    justify-content: center;
    width: 97%;
    display: grid;
    margin-top: 10px;
  }
  .settingRationButton {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 28px;
    gap: 8px;

    width: 337px;
    height: 46px;

    background: #FFFFFF;
    border: 1px solid #EBEAEC;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  .goToPreorderListBtn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 28px;
    gap: 8px;
    width: 337px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #EBEAEC;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  .goToPriceListBtn {
    margin-top: 10px;
    margin-left: 0px;
  }
  .rations_cards {
    display: grid;
    justify-content: center;
    margin-top: 5px;
    width: 96%;
    position: relative;
  }
  .rations_cards__checkbox {
    position: absolute;
    top: -5px;
    left: 25px;
  }
  .menu_box {
    width: 100%;
    justify-content: center;
    margin-left: 0px;
  }
  .some {
    display: grid;
  }
  .createOrderBtn {
    margin-top: 0px;
  }
}

</style>
