<!-- todo подумать про логику свитча и сохранить. Валидацию сделать-->
<template>
  <div class="whole_page">
    <div class="page_container">
      <div style="display: flex; flex-direction: column; gap: 20px">
        <template v-if="!isLoading">
          <div class="content_container">
            <h1 class="title-h1-roboto-medium">
              Ограничение суммы на "Буфет"
            </h1>
            <div>
              <v-row style="padding: 12px; display: flex; border-bottom: 2px solid #ebeaec; justify-content: space-between">
                <h4
                  class="body-text-roboto-medium"
                >
                  Использовать лимит
                </h4>
                <div style="align-self: center">
                  <label class="toggle-switch">
                    <input
                      v-model="limitSetting"
                      type="checkbox"
                      @change="resetLimitBuffet"
                    >
                    <div class="slider round" />
                  </label>
                </div>
              </v-row>
              <v-row style="justify-content: space-between; padding: 12px;">
                <div style="display: flex; flex-direction: row; text-align: right; gap: 12px">
                  <h4
                    class="body-text-roboto-medium"
                    style="width: 95px"
                  >
                    Максимум в день
                  </h4>
                  <v-text-field
                    v-model="currentLimit"
                    variant="solo"
                    density="compact"
                    :rules="limitRules"
                    style="min-width: 70px; max-height: 46px; border-radius: 7px;"
                  />
                </div>
                <button
                  class="primary-green-button"
                  style="padding: 10px 20px"
                  :disabled="!limitSetting"
                  @click="setLimitBuffet"
                >
                  Сохранить
                </button>
              </v-row>
            </div>
          </div>
          <div
            class="attention_notification"
          >
            <div class="attention-notification-text-box">
              <h4 class="body-text-roboto-medium">
                Для отключения лимита уберите соответствующую галочку
              </h4>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="text-center"
          >
            <v-progress-circular
              :size="100"
              :width="7"
              color="green"
              indeterminate
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { getLimitBuffet, setLimitBuffet } from '@/shared/api/parents/moneyAccounting/api-requests'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsSettingLimits',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      currentLimit: 0,
      limitSetting: false as boolean,
      isTelegram: false,
      isLoading: false,
      limitRules: [
        (value: any) => {
          if (value || value === undefined) return true
          return 'Пустое поле.'
        },
        // (value: any) => {
        //   if (value?.length <= 4 || value === undefined) return true
        //   return 'Максимум 4 цифры.'
        // },
        (value: any) => {
          if (!isNaN(value) || value === undefined) return true
          return 'Должно быть число.'
        },
        // (value: any) => {
        //   if (value <= this.maxInputValue || value === undefined) return true
        //   return 'Максимальная сумма оплаты 2000 руб.'
        // },
        (value: any) => {
          if (Number.isInteger(parseFloat(value)) || value === undefined) return true
          return 'Введите целое число'
        },
        (value: any) => {
          if (parseFloat(value) >= 0 || value === undefined) return true
          return 'Введите натуральное число'
        }
      ],
    }
  },
  async beforeMount () {
    this.isLoading = true
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
    await this.getLimitBuffet()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    async getLimitBuffet () {
      try {
        const getLimitBuffetReqObj = {
          userId: this.currentUser.id
        }
        const getLimitBuffetRes = await getLimitBuffet(getLimitBuffetReqObj, this.isTelegram)
        this.currentLimit = getLimitBuffetRes.data[0].limitDaily
        if (getLimitBuffetRes.data[0].limitDaily === null) {
          this.limitSetting = false
        } else {
          this.limitSetting = true
        }
        console.log(getLimitBuffetRes.data[0].limitDaily)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить лимит счета!',
          timeout: 3000
        })
      }
    },
    async setLimitBuffet () {
      try {
        const setLimitBuffetReqObj = {
          userId: this.currentUser.id,
          sum: this.currentLimit,
          resetLimit: null
        }
        await setLimitBuffet(setLimitBuffetReqObj, this.isTelegram)
        await this.getLimitBuffet()
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось установить лимит счета!',
          timeout: 3000
        })
      }
    },
    async resetLimitBuffet () {
      try {
        let resetLimitBuffetReqObj
        if (!this.limitSetting) {
          resetLimitBuffetReqObj = {
            userId: this.currentUser.id,
            sum: this.currentLimit,
            resetLimit: 1
          }
        } else {
          resetLimitBuffetReqObj = {
            userId: this.currentUser.id,
            sum: this.currentLimit,
            resetLimit: null
          }
        }
        await setLimitBuffet(resetLimitBuffetReqObj, this.isTelegram)
        await this.getLimitBuffet()
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось установить лимит счета!',
          timeout: 3000
        })
      }
    },
    goToMoneyAccounting () {
      this.$router.push('./moneyAccounting')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.content_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 480px;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
