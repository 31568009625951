<template>
  <v-container style="position: relative">
    <v-row class="justify-content-center">
      <v-col
        cols="7"
        sm="7"
      >
        <filters-panel
          :filter-options="filterOptions"
          :filter-state="filterState"
          @filter-changed-state="filterChangedHandler"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="2"
        sm="2"
      >
        <v-checkbox
          v-model="separateClasses"
          label="Использовать настройки"
          @click="createReport"
        />
      </v-col>
      <v-col
        class="d-flex flex-column"
        cols="3"
        sm="3"
      >
        <v-btn
          :style="{maxHeight: '36px'}"
          class="mb-1"
          :is-loading="isLoading"
          block
          @click="goToSettings()"
        >
          Настроить
        </v-btn>
        <v-btn
          :style="{maxHeight: '36px'}"
          :is-loading="isLoading"
          block
          @click="load()"
        >
          Показать
        </v-btn>
      </v-col>
      <v-col
        class="d-flex"
      />
      <v-col
        v-if="!isLoading && isPost && headerPlaceProducts.length > 0"
        class="d-flex"
        cols="1"
        sm="1"
      >
        <v-btn
          block
          @click="print()"
        >
          Печать
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!isLoading">
      <template v-if="isPost">
        <v-row
          v-if="errorDates.length > 0"
          class="justify-content-center"
        >
          <span class="text-warning">Ошибки в талонах за: </span>
          <template
            v-for="(errorDate, i) in errorDates"
            :key="i"
          >
            <span
              class="span-with-space"
            >
              <i> {{ errorDate }}</i>
            </span>
          </template>
          <span class="text-warning span-with-space"> {{ errorDates.length > 1 ? 'числа' : 'число' }}</span>
        </v-row>
        <v-row
          v-if="notAcceptDates.length > 0"
          class="justify-content-center"
        >
          <span class="text-warning">Неучтены талоны за: </span>
          <template
            v-for="(notAcceptDate, i) in notAcceptDates"
            :key="i"
          >
            <span
              class="span-with-space"
            >
              <i> {{ notAcceptDate }} </i>
            </span>
          </template>
          <span class="text-warning span-with-space"> {{ notAcceptDates.length > 1 ? 'числа' : 'число' }}</span>
        </v-row>
      </template>
      <div id="printableArea">
        <template v-if="headerPlaceProducts.length > 0">
          <v-row class="justify-content-center">
            <v-col>
              <h3 class="text-center print-header">
                Акт реализации талонов на бесплатное питание учащихся<br>
                за {{ selectedMonthName }} {{ selectedYearName }} года (бюджет, льготное питание)<br>
                {{ selectedUserAllowedPlaceName }}
              </h3>
            </v-col>
          </v-row>
          <v-row class="justify-content-center">
            <v-col cols="8">
              <v-table class="report-rateplan-day">
                <template #default>
                  <thead>
                    <tr>
                      <th
                        class="text-center"
                        rowspan="2"
                      >
                        Дата
                      </th>
                      <th
                        v-for="(headerPlaceProduct, i) in headerPlaceProducts"
                        :key="i"
                        class="text-center"
                        colspan="2"
                      >
                        {{ headerPlaceProduct.product.name }}<br>
                        <span>{{ getParallels(headerPlaceProduct.type) }}</span>
                      </th>
                      <th>
                        Всего сумма<br>за день
                      </th>
                    </tr>
                    <tr>
                      <template
                        v-for="(headerPlaceProduct, i) in headerPlaceProducts"
                        :key="i + '_' + '1'"
                      >
                        <th
                          class="text-center"
                        >
                          Кол-во
                        </th>
                        <th
                          class="text-center"
                        >
                          Сумма
                        </th>
                      </template>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        цена
                      </td>
                      <template
                        v-for="(headerPlaceProduct, j) in headerPlaceProducts"
                        :key="j + '_' + '1'"
                      >
                        <td
                          class="text-center"
                        />
                        <td
                          class="text-center"
                        >
                          {{ getPrice(headerPlaceProduct.product.id, headerPlaceProduct.type) }}
                        </td>
                      </template>
                      <td />
                    </tr>
                    <tr
                      v-for="(bodyDate, i) in bodyDates"
                      :key="i"
                    >
                      <td>
                        {{ bodyDate }}
                      </td>
                      <template
                        v-for="(headerPlaceProduct, j) in headerPlaceProducts"
                        :key="j + '_' + i + '_' + '1'"
                      >
                        <td
                          :class="compareProductPrice(headerPlaceProduct.product.id, headerPlaceProduct.type, bodyDate)"
                        >
                          {{ searchProductCount(headerPlaceProduct.product.id, headerPlaceProduct.type, bodyDate) }}
                        </td>
                        <td
                          :class="compareProductPrice(headerPlaceProduct.product.id, headerPlaceProduct.type, bodyDate)"
                        >
                          {{ searchProductSum(headerPlaceProduct.product.id, headerPlaceProduct.type, bodyDate) }}
                        </td>
                      </template>
                      <td>
                        {{ getSum(bodyDate) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>
                        ИТОГО
                      </th>
                      <template
                        v-for="(headerPlaceProduct, j) in headerPlaceProducts"
                        :key="j + '_' + '1'"
                      >
                        <th
                          class="text-center"
                        >
                          {{ getProductCount(headerPlaceProduct.product.id, headerPlaceProduct.type) }}
                        </th>
                        <th
                          class="text-center"
                        >
                          {{ getProductSum(headerPlaceProduct.product.id, headerPlaceProduct.type) }}
                        </th>
                      </template>
                      <th>
                        {{ getAllSum() }}
                      </th>
                    </tr>
                  </tfoot>
                </template>
              </v-table>
            </v-col>
            <v-col
              v-if="comparePlaceProducts.length > 0"
              cols="2"
              class="hidden-print"
            >
              <v-row>
                Проверка цены:
              </v-row>
              <v-row>
                <v-select
                  v-model="selectedHeaderPlaceProduct"
                  :items="comparePlaceProducts"
                  item-title="name"
                  item-value="id"
                  label="Продукт"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="selectedProductPrice"
                  label="Цена"
                />
              </v-row>
              <v-row>
                <v-btn @click="createReport()">
                  Проверить
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="print-sign">
              Директор школы _____________________________/{{ customerPlaceInfo.directorName }}/
            </v-col>
          </v-row>
          <v-row>
            <v-col class="print-sign">
              Организатор школьного питания _____________________________/{{ customerPlaceInfo.ladminName }}/
            </v-col>
          </v-row>
        </template>
        <template v-else-if="isPost">
          <v-row class="justify-content-center">
            <v-col>
              <h3 class="text-center">
                По заданным фильтрам записей не найдено!
              </h3>
            </v-col>
          </v-row>
        </template>
      </div>
    </template>
    <template v-else>
      <v-row class="justify-content-center">
        <v-col
          v-if="isLoading"
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { PlaceElectronTalonFindWithItemsPost } from '@/shared/api/placeElectronTalon/api-types'
import { find as findPlaceReportSettings } from '@/shared/api/placeReportSettings/api-requests'
import { CustomerPlaceFindPost } from '@/shared/api/customerPlace/api-types'
import { findWithItems } from '@/shared/api/placeElectronTalon/api-requests'
import { find } from '@/shared/api/customerPlace/api-requests'
import { AxiosError } from 'axios'
import FiltersPanel, { FilterNames, FilterOptions } from '@/shared/uikit/FiltersPanel.vue'
import { printer } from '@/shared/lib/print'

type BaseData = {
  isLoading: boolean;
  isPost: boolean;
  selectedUserAllowedPlaceName: string;
  selectedMonthName: string;
  selectedYearName: string;
  separateClasses: boolean;
  placeElectronTalonGetByIdPost: PlaceElectronTalonFindWithItemsPost;
  customerPlacePost: CustomerPlaceFindPost;
  placeElectronTalonsAnswere: any;
  customerPlaceInfo: any;
  placeElectronTalons: any;
  places: any;
  products: any;
  settings: any;
  notAcceptDates: any;
  errorDates: any;
  header: any;
  headerPlaces: any;
  headerPlaceProducts: any;
  comparePlaceProducts: any;
  selectedProductPrice: any;
  selectedHeaderPlaceProduct: any;
  bodyPlaceProducts: any;
  bodyByDatePlaceProducts: any;
  bodyDates: any;
  productDatePrices: any;
  datePrices: any;
  filterOptions: FilterOptions;
  filterState: any;
  dateFrom: number;
  dateTo: number;
}

export default defineComponent({
  name: 'DayTable',
  components: {
    FiltersPanel
  },
  data (): BaseData {
    return {
      filterState: {
        placeFilter: 0,
        classFilter: 0,
        productTypeFilter: 0,
        ratePlanFilter: 0,
        periodFilter: [],
        monthFilter: moment().month(),
        yearFilter: moment().year(),
        initialState: false
      } as any,
      filterOptions: {
        placeFilter: true,
        classFilter: false,
        productTypeFilter: false,
        ratePlanFilter: false,
        monthFilter: true,
        yearFilter: true,
        classItems: [],
        productTypeItems: [],
        placeItems: [],
        ratePlanItems: [],
        periodFilter: true
      } as FilterOptions,
      isLoading: false,
      isPost: false,
      selectedUserAllowedPlaceName: '',
      selectedMonthName: 'Неизвестно',
      selectedYearName: 'Неизвестно',
      separateClasses: false,
      placeElectronTalonGetByIdPost: {
        parentPlaceId: 0,
        dateFrom: '',
        dateTo: ''
      },
      customerPlacePost: {
        placeId: 0,
        date: ''
      },
      placeElectronTalonsAnswere: [],
      customerPlaceInfo: null,
      placeElectronTalons: [],
      places: [],
      errorDates: [],
      notAcceptDates: [],
      products: [],
      settings: [],
      header: [],
      headerPlaces: [],
      headerPlaceProducts: [],
      comparePlaceProducts: [],
      selectedHeaderPlaceProduct: null,
      selectedProductPrice: null,
      bodyPlaceProducts: [],
      bodyByDatePlaceProducts: [],
      bodyDates: [],
      productDatePrices: [],
      datePrices: [],
      dateFrom: 0,
      dateTo: 0
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  props: {
    propPlaceId: {
      type: String,
      required: false
    }
  },
  async beforeMount () {
    this.isLoading = true
    const userAllowedPlaces = await this.userAllowedPlaceAll()
    console.log(this.dateFrom, this.dateTo)
    this.filterOptions.placeItems = userAllowedPlaces
    this.filterState[FilterNames.PLACE_FILTER] = userAllowedPlaces[0].placeId
    await this.loadCustomerPlaceInfo()
    this.isLoading = false
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('userAllowedPlaces', { userAllowedPlaceAll: 'all' }),
    filterChangedHandler (filterName: FilterNames, oldValue: any, newValue: any) {
      console.log(filterName, newValue)
      this.filterState[filterName] = newValue
    },
    async loadPlaceElectronTalons () {
      try {
        this.isLoading = true
        if (this.filterOptions.placeItems.length !== 0) {
          this.selectedUserAllowedPlaceName = this.filterOptions.placeItems.find((placeItem: any) => {
            return placeItem.placeId === this.filterState[FilterNames.PLACE_FILTER]
          }).place.name
          const reportSettingsFindPost = {
            placeId: this.filterState[FilterNames.PLACE_FILTER],
            reportType: 1
          }
          const result1 = await findPlaceReportSettings(reportSettingsFindPost)
          const settingsResponse = JSON.parse(JSON.stringify(result1))
          this.getSettings(settingsResponse)

          this.placeElectronTalonGetByIdPost.parentPlaceId = this.filterState[FilterNames.PLACE_FILTER]
          if (this.filterState[FilterNames.PERIOD_FILTER].length >= 2){
            this.placeElectronTalonGetByIdPost.dateFrom = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).add('days', 1).format('YYYY-MM-DD')
          } else if (this.filterState[FilterNames.PERIOD_FILTER].length === 1) {
            this.placeElectronTalonGetByIdPost.dateFrom = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).add('days', 1).format('YYYY-MM-DD')
          } else {
            this.placeElectronTalonGetByIdPost.dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).startOf('month').format('YYYY-MM-DD')
            this.placeElectronTalonGetByIdPost.dateTo = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).endOf('month').format('YYYY-MM-DD')
          }
          // this.placeElectronTalonGetByIdPost.dateFrom = moment(`${this.filterState[FilterNames.YEAR_FILTER]}-${this.filterState[FilterNames.MONTH_FILTER] + 1}`).startOf('month').format('YYYY-MM-DD')
          // this.placeElectronTalonGetByIdPost.dateTo = moment(`${this.placeElectronTalonGetByIdPost.dateFrom}`).add(1, 'M').startOf('month').format('YYYY-MM-DD')
          const result = await findWithItems(this.placeElectronTalonGetByIdPost)
          this.placeElectronTalonsAnswere = JSON.parse(JSON.stringify(result))
          if (Array.isArray(this.placeElectronTalonsAnswere.placeEletronTalons)) {
            this.placeElectronTalons = this.placeElectronTalonsAnswere.placeEletronTalons
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.products)) {
            this.products = this.placeElectronTalonsAnswere.products
          }
          if (Array.isArray(this.placeElectronTalonsAnswere.places)) {
            this.places = this.placeElectronTalonsAnswere.places
          }
          if (this.places.length > 0 && this.products.length > 0 && this.placeElectronTalons.length > 0) {
            this.createReport()
          }
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      } finally {
        this.isLoading = false
      }
    },
    async loadCustomerPlaceInfo () {
      try {
        if (this.filterOptions.placeItems.length !== 0) {
          this.customerPlacePost.placeId = this.filterState[FilterNames.PLACE_FILTER]
          if (this.filterState[FilterNames.PERIOD_FILTER].length >= 2) {
            this.customerPlacePost.date = moment(this.filterState[FilterNames.PERIOD_FILTER][this.filterState[FilterNames.PERIOD_FILTER].length - 1]).format('YYYY-MM-DD')
          } else if (this.filterState[FilterNames.PERIOD_FILTER].length === 1) {
            this.customerPlacePost.date = moment(this.filterState[FilterNames.PERIOD_FILTER][0]).format('YYYY-MM-DD')
          } else {
            this.customerPlacePost.date = moment(new Date(this.filterState[FilterNames.YEAR_FILTER], this.filterState[FilterNames.MONTH_FILTER], 1)).endOf('month').format('YYYY-MM-DD')
          }
          const result = await find(this.customerPlacePost)
          this.customerPlaceInfo = JSON.parse(JSON.stringify(result))
        }
      } catch (error: any) {
        let errorText = 'Ошибка. Повторите попытку.'
        const err = error as AxiosError
        if (err.response) {
          errorText = err.response.data.errorMessage
        }
        this.setSnackbar({
          showing: true,
          text: errorText,
          timeout: 3000
        })
      }
    },
    getParallels (type: any) {
      let parallels = ''
      if (!this.separateClasses) {
        if (type === 1) {
          parallels = '1-4 классы'
        } else {
          parallels = '5-11 классы'
        }
      } else {
        this.settings.forEach((element: any) => {
          if (element.classType === type) {
            parallels = element.startValue + '-' + element.endValue + ' классы'
          }
        })
      }
      return parallels
    },
    getSettings (settingsResponse: any) {
      this.settings = []
      if (Array.isArray(settingsResponse)) {
        settingsResponse.forEach((element: any) => {
          const elementCopy = element
          const values = elementCopy.value.split(';')
          const setting = {
            id: element.id,
            startValue: values[0],
            endValue: values[1]
          }
          this.settings.push(setting)
        })
      }
      this.sortSettings()
      let i = 1
      this.settings.forEach((element: any) => {
        element.classType = i
        i = i + 1
      })
    },
    sortSettings () {
      this.settings.sort((a: any, b: any) => (a.startValue > b.startValue) ? 1 : ((b.startValue > a.startValue) ? -1 : 0))
    },
    createReport () {
      this.clearData()
      this.places.forEach((element: any) => {
        let classType = 0
        if (!this.separateClasses) {
          if (element.name.match(/^[1-4][^0-9].*|^[1-4]$/)) classType = 1
          if (element.name.match(/^[5-9].*|^[5-9]$/)) classType = 2
          if (element.name.match(/^[1-4][0-9].*$|^[1-4][0-9]$/)) classType = 2
        } else {
          this.settings.forEach((el: any) => {
            if (element.name.match(/^[1-4][^0-9].*|^[1-4]|[5-9].*|^[5-9]|[1-4][0-3].*$|^[1-4][0-3]$/)) {
              if (parseInt(element.name) >= el.startValue && parseInt(element.name) <= el.endValue) {
                classType = el.classType
              }
            }
          })
        }
        element.classType = classType
        if (!this.headerPlaces.find((el: number) => el === classType)) this.headerPlaces.push(classType)
      })

      this.headerPlaces = this.headerPlaces.sort((a: number, b: number) => a - b)
      this.placeElectronTalons.forEach((placeElectronTalon: any) => {
        placeElectronTalon.placeElectronTalonItems.forEach((placeElectronTalonItem: any) => {
          if (placeElectronTalonItem.removed == null) {
            placeElectronTalonItem.placeElectronTalonItemSales.forEach((placeElectronTalonItemSale: any) => {
              if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId != null) {
                this.places.forEach((place: any) => {
                  if (place.id === placeElectronTalon.placeId) {
                    let productName = ''
                    let productPrice = 0
                    this.products.forEach((product: any) => {
                      if (product.id === placeElectronTalonItemSale.productId) {
                        placeElectronTalon.placeElectronTalonProducts.forEach((placeElectronTalonProduct: any) => {
                          if (product.id === placeElectronTalonProduct.productId) {
                            productPrice = placeElectronTalonProduct.price
                            this.productDatePrices.push({
                              date: moment(placeElectronTalon.date).format('DD.MM.YYYY'),
                              product: placeElectronTalonItemSale.productId,
                              price: placeElectronTalonProduct.price
                            })
                          }
                        })
                        productName = product.name
                      }
                    })
                    if (!this.headerPlaceProducts.find((el: any) => el.type === place.classType && el.product.id === placeElectronTalonItemSale.productId)) {
                      this.headerPlaceProducts.push({
                        type: place.classType,
                        product: { id: placeElectronTalonItemSale.productId, name: productName }
                      })
                      this.comparePlaceProducts.push({ id: placeElectronTalonItemSale.productId, name: productName })
                    }
                    this.bodyPlaceProducts.push({
                      date: moment(placeElectronTalon.date).format('DD.MM.YYYY'),
                      product: placeElectronTalonItemSale.productId,
                      type: place.classType,
                      sum: productPrice
                    })
                  }
                })
              } else {
                if (placeElectronTalonItemSale.removed == null && placeElectronTalonItemSale.saleId == null) {
                  if (!this.notAcceptDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.notAcceptDates.push(moment(placeElectronTalon.date).format('DD'))
                }
              }
            })
          }
          if (placeElectronTalonItem.error != null) {
            if (String(placeElectronTalonItem.error) !== '15') {
              if (!this.errorDates.find((el: any) => el === moment(placeElectronTalon.date).format('DD'))) this.errorDates.push(moment(placeElectronTalon.date).format('DD'))
            }
          }
        })
      })
      this.bodyPlaceProducts.forEach((bodyPlaceProduct: any) => {
        if (!this.bodyDates.find((el: any) => el === bodyPlaceProduct.date)) {
          this.bodyDates.push(bodyPlaceProduct.date)
        }
        if (!this.bodyByDatePlaceProducts.find((el: any) => el.type === bodyPlaceProduct.type && el.product === bodyPlaceProduct.product && el.date === bodyPlaceProduct.date)) {
          this.bodyByDatePlaceProducts.push({
            date: bodyPlaceProduct.date,
            product: bodyPlaceProduct.product,
            type: bodyPlaceProduct.type,
            count: 1,
            sum: Number(bodyPlaceProduct.sum)
          })
        } else {
          this.bodyByDatePlaceProducts.forEach((bodyByDatePlaceProduct: any) => {
            if (bodyByDatePlaceProduct.type === bodyPlaceProduct.type && bodyByDatePlaceProduct.product === bodyPlaceProduct.product && bodyByDatePlaceProduct.date === bodyPlaceProduct.date) {
              bodyByDatePlaceProduct.count = bodyByDatePlaceProduct.count + 1
              bodyByDatePlaceProduct.sum = bodyByDatePlaceProduct.sum + Number(bodyPlaceProduct.sum)
            }
          })
        }
      })

      this.productDatePrices.forEach((productDatePrice: any) => {
        if (!this.datePrices.find((el: any) => el.product === productDatePrice.product && el.date === productDatePrice.date)) {
          this.datePrices.push({
            date: productDatePrice.date,
            product: productDatePrice.product,
            price: Number(productDatePrice.price),
            count: 1
          })
        } else {
          this.datePrices.forEach((datePrice: any) => {
            if (datePrice.product === productDatePrice.product && datePrice.date === productDatePrice.date) {
              datePrice.count = datePrice.count + 1
              datePrice.price = datePrice.price + Number(productDatePrice.price)
            }
          })
        }
      })

      this.bodyDates.sort((a: Date, b: Date) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      this.headerPlaceProducts.sort((a: any, b: any) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : ((a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0))))
    },
    clearData () {
      this.header = []
      this.headerPlaces = []
      this.headerPlaceProducts = []
      this.comparePlaceProducts = []
      this.bodyPlaceProducts = []
      this.bodyByDatePlaceProducts = []
      this.bodyDates = []
      this.productDatePrices = []
      this.datePrices = []
    },
    load () {
      this.placeElectronTalons = []
      this.places = []
      this.products = []
      this.errorDates = []
      this.notAcceptDates = []
      this.placeElectronTalonsAnswere = []
      this.settings = []
      this.comparePlaceProducts = []
      this.selectedHeaderPlaceProduct = null
      this.selectedProductPrice = null
      this.clearData()
      this.customerPlaceInfo = null
      this.loadPlaceElectronTalons()
      this.loadCustomerPlaceInfo()
      this.isPost = true
      this.selectedMonthName = moment().locale('ru').month(this.filterState[FilterNames.MONTH_FILTER]).format('MMMM')
      this.selectedYearName = this.filterState[FilterNames.YEAR_FILTER]
    },
    compareProductPrice (productId: any, productType: any, date: any) {
      if (this.selectedHeaderPlaceProduct === null) {
        return 'text-center'
      }
      if (this.selectedHeaderPlaceProduct !== productId) {
        return 'text-center'
      }
      let productCount = 0
      let productSum = 0
      const product = this.bodyByDatePlaceProducts.find((bodyByDatePlaceProduct: any) => String(bodyByDatePlaceProduct.date) === String(date) &&
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (product !== undefined) {
        productCount = product.count
        productSum = product.sum
      }
      if (productCount === 0) {
        return 'text-center'
      }
      if ((Number(productSum) / Number(productCount)).toFixed(2) === Number(this.selectedProductPrice).toFixed(2)) {
        return 'text-center'
      }
      return 'text-center item-error'
    },
    searchProductCount (productId: any, productType: any, date: any) {
      const product = this.bodyByDatePlaceProducts.find((bodyByDatePlaceProduct: any) => String(bodyByDatePlaceProduct.date) === String(date) &&
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (product === undefined) {
        return 0
      }
      return product.count
    },
    searchProductSum (productId: any, productType: any, date: any) {
      const product = this.bodyByDatePlaceProducts.find((bodyByDatePlaceProduct: any) => String(bodyByDatePlaceProduct.date) === String(date) &&
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (product === undefined) {
        return 0
      }
      return product.sum.toFixed(2)
    },
    getPrice (productId: any, productType: any) {
      const products = this.bodyByDatePlaceProducts.filter((bodyByDatePlaceProduct: any) =>
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (products === undefined) {
        return 0
      }
      let count = 0
      let sum = 0
      products.forEach((product: any) => {
        count = count + product.count
        sum = sum + product.sum
      })
      if (count === 0) return 0
      return (sum / count).toFixed(2)
    },
    getSum (date: any) {
      const products = this.bodyByDatePlaceProducts.filter((bodyByDatePlaceProduct: any) => String(bodyByDatePlaceProduct.date) === String(date))
      if (products === undefined) {
        return 0
      }
      let sum = 0
      products.forEach((product: any) => {
        sum = sum + product.sum
      })
      return sum.toFixed(2)
    },
    getProductCount (productId: any, productType: any) {
      const products = this.bodyByDatePlaceProducts.filter((bodyByDatePlaceProduct: any) =>
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (products === undefined) {
        return 0
      }
      let count = 0
      products.forEach((product: any) => {
        count = count + product.count
      })

      return count
    },
    getProductSum (productId: any, productType: any) {
      const products = this.bodyByDatePlaceProducts.filter((bodyByDatePlaceProduct: any) =>
        String(bodyByDatePlaceProduct.product) === String(productId) && String(bodyByDatePlaceProduct.type) === String(productType))
      if (products === undefined) {
        return 0
      }
      let sum = 0
      products.forEach((product: any) => {
        sum = sum + product.sum
      })

      return (sum).toFixed(2)
    },
    getAllSum () {
      let sum = 0
      this.bodyByDatePlaceProducts.forEach((product: any) => {
        sum = sum + product.sum
      })
      return sum.toFixed(2)
    },
    print () {
      printer('printableArea', 'print.css')
    },
    goToSettings () {
      this.$router.push({
        name: 'ReportSettings',
        query: {
          placeId: this.filterState[FilterNames.PLACE_FILTER],
          report: 'ReportRatePlanDay'
        }
      })
    }
  }
})
</script>

<style lang="scss"></style>
