<template>
  <div class="whole_page">
    <div class="menu_container">
      <div
        class="menu_element pointed"
        @click="goToMyChildren"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #A3B9F8 0%, #B5E0F0 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/group.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Мои дети
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToMoneyAccounting"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #A09BEE 0%, #DCCCFA 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/currency.png"
            height="22"
            width="22"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Учет денег
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToPreorders"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #F7C490 0%, #FDF1C2 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/grading.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Предзаказ
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToSinglePayment"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px;background: linear-gradient(180deg, #F19D97 0%, #F9D7C2 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/wallet.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Единый платеж
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToSubSinglePayment"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px;background: linear-gradient(180deg, #F19D97 0%, #F9D7C2 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/wallet.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Единый платеж (подписка)
        </h4>
      </div>
      <!--    <div @click="goToSchoolAttendance" class="menu_element">-->
      <!--      <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #F4B5E5 0%, #FADDE7 100%); display: flex; justify-content: center">-->
      <!--        <img src="@/assets/images/door.png" height="26" width="26" class="white-png">-->
      <!--      </div>-->
      <!--      <h4 class="body-text-roboto-medium">Проход в школу</h4>-->
      <!--    </div>-->
      <div
        class="menu_element pointed"
        @click="goToNotAllowed"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #97DFB4 0%, #EBF9BC 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/block.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Ребенку нельзя
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToSubscription"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #A3B9F8 0%, #B5E0F0 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/wallet.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Подписка
        </h4>
      </div>
<!--      <div-->
<!--        class="menu_element pointed"-->
<!--        @click="goToAttendanceReport"-->
<!--      >-->
<!--        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #A3B9F8 0%, #B5E0F0 100%); display: flex; justify-content: center">-->
<!--          <img-->
<!--            src="@/assets/images/calendar.png"-->
<!--            height="26"-->
<!--            width="26"-->
<!--            class="white-png"-->
<!--          >-->
<!--        </div>-->
<!--        <h4 class="body-text-roboto-medium">-->
<!--          Табель занятий-->
<!--        </h4>-->
<!--      </div>-->
      <div
        class="menu_element pointed"
        @click="goToDigitalReceipts"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #A09BEE 0%, #DCCCFA 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/receipt_long.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Эл. квитанции
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToInfo"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #F7C490 0%, #FDF1C2 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/info.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Информация
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToSettings"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px;background: linear-gradient(180deg, #F19D97 0%, #F9D7C2 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/setting.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Настройки
        </h4>
      </div>
      <div
        class="menu_element pointed"
        @click="goToNews"
      >
        <div style="width: 36px; height: 36px; border-radius: 18px; background: linear-gradient(180deg, #97DFB4 0%, #EBF9BC 100%); display: flex; justify-content: center">
          <img
            src="@/assets/images/receipt_long.png"
            height="26"
            width="26"
            class="white-png"
          >
        </div>
        <h4 class="body-text-roboto-medium">
          Новости
        </h4>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex'
import { Person } from '@/store/user/types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParentsHome',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      currentUser: {} as Person,
      isTelegram: false
    }
  },
  beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    this.currentUser = this.user.person
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    goToMyChildren () {
      this.$router.push('./myChildren')
    },
    goToMoneyAccounting () {
      this.$router.push('./moneyAccounting')
    },
    goToPreorders () {
      this.$router.push('./preordersNew/5')
    },
    goToSettings () {
      this.$router.push('./settings')
    },
    goToSinglePayment () {
      this.$router.push('./singlePayment')
    },
    goToSubSinglePayment () {
      this.$router.push({name: 'ParentsSubscriptionSinglePayment'})
    },
    goToSchoolAttendance () {
      this.$router.push('./schoolAttendance')
    },
    goToNotAllowed () {
      this.$router.push('./notAllowed')
    },
    goToAttendanceReport () {
      this.$router.push('./attendanceReport')
    },
    goToSubscription () {
      this.$router.push({name: 'ParentsSubscription'})
    },
    goToInfo () {
      this.$router.push('./info')
    },
    goToDigitalReceipts () {
      this.$router.push('./digitalReceipts')
    },
    goToNews () {
      this.$router.push('./news')
    }
  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";

.menu_container {
  padding: 85px 375px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.menu_element {
  width: 187px;
  gap: 20px;
  border-radius: 12px;
  padding: 16px 12px;
  background: #FFFFFFCC;
  display: flex;
  flex-direction: column;
}
.white-png {
  filter: invert(1) brightness(2);
  align-self: center
}
@media (max-aspect-ratio: 6/5) {
  .menu_container {
    padding: 0px;
    justify-content: center;
  }
  .menu_element {
    width: 160px;
  }
}
</style>
