<template>
  <v-container v-if="error">
    <v-row class="justify-content-center">
      <v-col>
        <h1 class="text-center">
          <strong> {{ errorMessage }}</strong>
        </h1>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row class="justify-content-center">
      <v-col style="display: grid; justify-content: center">
        <h1 class="text-center">
          <strong> {{ title }}</strong>
        </h1>
        <p
          v-if="loading"
          class="text-center"
          style="padding-top: 30px;  padding-bottom: 30px;"
        >
          <v-progress-circular
            class="text-center"
            :size="150"
            :width="15"
            color="blue"
            indeterminate
          />
        </p>
        <h2
          v-if="settings.subValid && !loading"
          class="text-center"
          style="color: #1976d2 !important;"
        >
          <strong>Подписка действительна до {{ getDateFormat(settings.paidUpTo) }}</strong>
        </h2>
        <h2
          v-if="!settings.subValid && !loading"
          class="text-center"
        >
          <strong>Подписка не активна</strong>
        </h2>
        <v-row
          v-if="settings.subValid && !loading"
          class="justify-content-center"
          justify="center"
          align="center"
        >
          <v-col cols="7">
            <v-radio-group
              v-model="settings.autoPayedService"
              class="text-center"
              :disabled="disabled"
            >
              <v-radio
                class="text-center"
                label="Автоматическая"
                :value="2"
              />
              <v-radio
                class="text-center"
                label="По предупреждению"
                :value="1"
              />
              <v-radio
                class="text-center"
                label="Отключено"
                :value="3"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row
          v-if="!disabled && settings.autoPayedService != 3 && !loading"
          class="justify-content-center"
          justify="center"
          align="center"
        >
          <v-col cols="7">
            <v-checkbox
              v-model="settings.summerAutoPayed"
              class="text-center"
              label="Отключить в летний период"
              :true-value="0"
              :false-value="1"
            />
          </v-col>
        </v-row>

        <p
          v-if="checkBankBindingsAndSbp"
          class="text-center"
        >
          <v-btn
            :disabled="disabled"
            class="ma-2"
            color="primary"
            @click="telegramPaymentServiceSettingSave()"
          >
            Сохранить
          </v-btn>
        </p>

        <div v-if="bankBindings.length > 0">
          <p class="text-center" style="padding: 10px 0px">
            Привязанные карты
          </p>
          <template v-for="bankBinding in bankBindings">
            <v-sheet
              v-if="true"
              :key="bankBinding.id"
              elevation="2"
              max-width="500"
              rounded="lg"
              width="100%"
              min-height="70px"
              style="background-color: lightgray; margin-bottom: 10px;"
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4"
            >
              <v-container>
                <v-row no-gutters>
                  <v-col
                    cols="10"
                    class="d-flex align-left justify-left"
                    style="cursor: pointer"
                    @click="payCardBySub(bankBinding.id, bankBinding.maskedPan)"
                  >
                    <span style="text-align: left;">Продлить подписку на месяц с помощью карты {{ bankBinding.maskedPan }}</span>
                  </v-col>
                  <v-col
                    v-if="bankBindingFlag"
                    cols="2"
                    class="d-flex align-center justify-center"
                  >
                    <v-progress-circular
                      color="primary"
                      indeterminate
                    />
                  </v-col>
                  <v-col
                    v-if="!bankBindingFlag"
                    cols="2"
                    class="d-flex align-center justify-center"
                    style="cursor: pointer"
                    @click="deleteBankCard(bankBinding.id, bankBinding.maskedPan)"
                  >
                    <v-icon
                      size="medium"
                      color="grey-darken-2"
                    >
                      mdi-delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </template>
          <p class="text-center" />
        </div>

        <div v-if="sbpSubscriptions.length > 0">
          <p class="text-center">
            Сохранённые варианты платежей <img
              :src="require('@/assets/images/logosbp.svg')"
              style="height: 30px; position: relative; left: 5px; top: 9px;"
            > СБП
          </p>
          <template v-for="sbpSubscription in sbpSubscriptions">
            <v-sheet
              v-if="true"
              :key="sbpSubscription.id"
              elevation="2"
              max-width="500"
              rounded="lg"
              width="100%"
              min-height="70px"
              style="background-color: lightgray; margin-bottom: 10px;"
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4"
            >
              <v-row no-gutters>
                <v-col
                  cols="10"
                  class="d-flex align-left justify-left"
                  style="cursor: pointer"
                  @click="paySbpBySub(sbpSubscription.id, sbpSubscription.memberId)"
                >
                  <img
                    v-if="sbpSubscription.memberPictExist == 1"
                    :src="require('@/assets/images/banklogo/'+sbpSubscription.memberId+'.svg')"
                    style="max-height: 40px; margin-top: 5px; margin-right: 10px;"
                  >
                  <img
                    v-else
                    :src="require('@/assets/images/banklogo/empty.svg')"
                    style="max-height: 40px; margin-top: 5px; margin-right: 10px;"
                  >
                  <span style="text-align: left;">Продлить подписку на месяц с помощью {{ sbpSubscription.memberRusName }}</span>
                </v-col>
                <v-col
                  v-if="sbpBindingFlag"
                  cols="2"
                  class="d-flex align-center justify-center"
                >
                  <v-progress-circular
                    color="primary"
                    indeterminate
                  />
                </v-col>
                <v-col
                  v-if="!sbpBindingFlag"
                  cols="2"
                  class="d-flex align-center justify-center"
                  style="cursor: pointer"
                  @click="deleteSbpBinding(sbpSubscription.id)"
                >
                  <v-icon
                    size="medium"
                    color="grey-darken-2"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </v-sheet>
          </template>
          <p class="text-center" />
        </div>

        <h2
          v-if="!checkBankBindingsAndSbp && settings.subValid"
          class="text-center"
        >
          <strong>Нет привязок СБП и карт, автоматическое продление подписки отключено.</strong>
        </h2>

        <v-container
          v-if="!checkBankBindingsAndSbp && !loading"
          class="text-center justify-content-center align-center justify-center"
        >
          <v-row>
            <v-col>
              <v-btn
                class="ma-2"
                variant="outlined"
                size="x-large"
                width="300"
                @click="paySbp()"
              >
                <img
                  :src="require('@/assets/images/logosbp.svg')"
                  style="max-height: 30px;"
                >Оплатить СБП
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                class="ma-2"
                variant="outlined"
                size="x-large"
                width="300"
                @click="payCard()"
              >
                Оплатить картой
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import {
  telegramPaymentServiceCreateAquaringOrder,
  telegramPaymentServiceCreateSbpOrder,
  telegramPaymentServiceCreateSbpOrderBySub,
  telegramPaymentServiceSettingGet,
  telegramPaymentServiceSettingSave,
  telegramPaymentServiceuCreateAquaringOrderBySubscription,
  telegramPaymentServiceUnbindCard,
  telegramPaymentServiceuUbindSbpSubscription
} from '@/shared/api/telegram/api-requests'
import {
  TelegramPaymentServiceCreateAquaringOrderPost,
  TelegramPaymentServiceCreateSbpOrderBySubPost,
  TelegramPaymentServiceCreateSbpOrderPost,
  TelegramPaymentServiceSettingGetPost,
  TelegramPaymentServiceSettingSavePost,
  TelegramPaymentServiceuCreateAquaringOrderBySubscriptionPost,
  TelegramPaymentServiceUnbindCardPost,
  TelegramPaymentServiceUnbindSbpSubscriptionPost
} from '@/shared/api/telegram/api-types'
import {
  BankBinding,
  PaymentServicePayAquaringBySubResponse,
  PaymentServicePayAquaringResponse,
  PaymentServicePaySbpResponse,
  PaymentServiceSettings,
  SbpSubscription,
  TelegramPaymentServiceSettingResponse
} from '@/store/telegram/types'
import moment from 'moment'
import { AxiosError } from 'axios'

type BaseData = {
  token: string;
  loading: boolean;
  bankBindingFlag: boolean;
  sbpBindingFlag: boolean;
  checkBankBindingsAndSbp: boolean;
  payTemp: boolean;
  disabled: boolean;
  error: boolean;
  errorMessage: string;
  errorMessageUnknown: string;
  title: string;
  settings: PaymentServiceSettings;
  bankBindings: BankBinding[];
  sbpSubscriptions: SbpSubscription[];
  telegramPaymentServiceSettingGetParams: TelegramPaymentServiceSettingGetPost;
  telegramPaymentServiceSettingSaveParams: TelegramPaymentServiceSettingSavePost;
  telegramPaymentServiceUnbindCardPost: TelegramPaymentServiceUnbindCardPost;
  telegramPaymentServiceCreateSbpOrderPost: TelegramPaymentServiceCreateSbpOrderPost;
  telegramPaymentServiceCreateSbpOrderBySubPost: TelegramPaymentServiceCreateSbpOrderBySubPost;
  telegramPaymentServiceCreateAquaringOrderPost: TelegramPaymentServiceCreateAquaringOrderPost;
  telegramPaymentServiceUnbindSbpSubscriptionPost: TelegramPaymentServiceUnbindSbpSubscriptionPost;
  telegramPaymentServiceuCreateAquaringOrderBySubscriptionPost: TelegramPaymentServiceuCreateAquaringOrderBySubscriptionPost;
}

export default defineComponent({
  props: {
    tokenProp: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      token: '',
      loading: true,
      bankBindingFlag: false,
      sbpBindingFlag: false,
      checkBankBindingsAndSbp: false,
      disabled: true,
      error: false,
      errorMessage: 'Неизвестная',
      errorMessageUnknown: 'Неизвестная',
      title: 'Настройка подписки телеграм-бота',
      payTemp: false,
      settings: {
        autoPayedService: 0,
        summerAutoPayed: 0,
        paidUpTo: '',
        subValid: false
      },
      bankBindings: [],
      sbpSubscriptions: [],
      telegramPaymentServiceSettingGetParams: {
        token: ''
      },
      telegramPaymentServiceSettingSaveParams: {
        token: '',
        settings: {
          autoPayedService: 0,
          summerAutoPayed: 0,
          paidUpTo: '',
          subValid: false
        }
      },
      telegramPaymentServiceUnbindCardPost: {
        token: '',
        id: -1,
        mask: ''
      },
      telegramPaymentServiceCreateSbpOrderPost: {
        token: ''
      },
      telegramPaymentServiceCreateSbpOrderBySubPost: {
        id: -1,
        token: '',
        memberId: ''
      },
      telegramPaymentServiceCreateAquaringOrderPost: {
        token: ''
      },
      telegramPaymentServiceUnbindSbpSubscriptionPost: {
        token: '',
        id: -1
      },
      telegramPaymentServiceuCreateAquaringOrderBySubscriptionPost: {
        token: '',
        id: -1,
        mask: ''
      }
    }
  },
  mounted () {
    if (this.tokenProp) {
      this.token = this.tokenProp
      this.telegramPaymentServiceSettingLoad()
    }
  },
  methods: {
    async telegramPaymentServiceSettingLoad () {
      try {
        this.loading = true
        this.telegramPaymentServiceSettingGetParams.token = this.token
        await telegramPaymentServiceSettingGet(this.telegramPaymentServiceSettingGetParams)
          .then((telegramPaymentServiceSettings: TelegramPaymentServiceSettingResponse) => {
            this.token = telegramPaymentServiceSettings.token
            this.settings = telegramPaymentServiceSettings.settings
            this.bankBindings = telegramPaymentServiceSettings.bankBindings
            this.sbpSubscriptions = telegramPaymentServiceSettings.sbpSubscriptions
            this.checkBankCardsAndSbp()
            this.title = 'Настройка подписки телеграм-бота'
            console.log(telegramPaymentServiceSettings)
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    async telegramPaymentServiceSettingSave () {
      try {
        this.loading = true
        this.telegramPaymentServiceSettingSaveParams.token = this.token
        this.telegramPaymentServiceSettingSaveParams.settings = this.settings
        await telegramPaymentServiceSettingSave(this.telegramPaymentServiceSettingSaveParams)
          .then((telegramPaymentServiceSettings: PaymentServiceSettings) => {
            this.settings = telegramPaymentServiceSettings
            this.checkBankCardsAndSbp()
            this.title = 'Настройка подписки телеграм-бота'
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.loading = false
      }
    },
    async deleteBankCard (id: number, mask: string) {
      try {
        this.bankBindingFlag = true
        this.telegramPaymentServiceUnbindCardPost.token = this.token
        this.telegramPaymentServiceUnbindCardPost.id = id
        this.telegramPaymentServiceUnbindCardPost.mask = mask
        await telegramPaymentServiceUnbindCard(this.telegramPaymentServiceUnbindCardPost)
          .then((bankBindings: BankBinding[]) => {
            this.bankBindings = bankBindings
            this.checkBankCardsAndSbp()
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.bankBindingFlag = false
      }
    },
    async deleteSbpBinding (id: number) {
      try {
        this.sbpBindingFlag = true
        this.telegramPaymentServiceUnbindSbpSubscriptionPost.token = this.token
        this.telegramPaymentServiceUnbindSbpSubscriptionPost.id = id
        await telegramPaymentServiceuUbindSbpSubscription(this.telegramPaymentServiceUnbindSbpSubscriptionPost)
          .then((sbpSubscriptions: SbpSubscription[]) => {
            this.sbpSubscriptions = sbpSubscriptions
            this.checkBankCardsAndSbp()
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.sbpBindingFlag = false
      }
    },
    async paySbp () {
      try {
        this.loading = true
        this.bankBindingFlag = true
        this.telegramPaymentServiceCreateSbpOrderPost.token = this.token
        await telegramPaymentServiceCreateSbpOrder(this.telegramPaymentServiceCreateSbpOrderPost)
          .then((res: PaymentServicePaySbpResponse) => {
            window.location.href = res.data.payload
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.bankBindingFlag = false
        this.loading = false
      }
    },
    async paySbpBySub (id: number, memberId: string) {
      try {
        this.bankBindingFlag = true
        this.telegramPaymentServiceCreateSbpOrderBySubPost.token = this.token
        this.telegramPaymentServiceCreateSbpOrderBySubPost.id = id
        this.telegramPaymentServiceCreateSbpOrderBySubPost.memberId = memberId
        await telegramPaymentServiceCreateSbpOrderBySub(this.telegramPaymentServiceCreateSbpOrderBySubPost)
          .then((res: any) => {
            if (res) {
              console.log(res)
              window.location.href = 'https://sc-backend.glolime.ru/api/telegram/success?chatId=' + res
            }
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.bankBindingFlag = false
      }
    },
    async payCard () {
      try {
        this.loading = true
        this.bankBindingFlag = true
        this.telegramPaymentServiceCreateAquaringOrderPost.token = this.token
        await telegramPaymentServiceCreateAquaringOrder(this.telegramPaymentServiceCreateAquaringOrderPost)
          .then((res: PaymentServicePayAquaringResponse) => {
            if (res.formUrl !== null) {
              window.location.href = (res.formUrl)
            }
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.bankBindingFlag = false
        this.loading = false
      }
    },
    async payCardBySub (id: number, mask: string) {
      try {
        this.bankBindingFlag = true
        this.telegramPaymentServiceuCreateAquaringOrderBySubscriptionPost.token = this.token
        this.telegramPaymentServiceuCreateAquaringOrderBySubscriptionPost.id = id
        this.telegramPaymentServiceuCreateAquaringOrderBySubscriptionPost.mask = mask
        await telegramPaymentServiceuCreateAquaringOrderBySubscription(this.telegramPaymentServiceuCreateAquaringOrderBySubscriptionPost)
          .then((res: PaymentServicePayAquaringBySubResponse) => {
            if (res.formUrl !== null) {
              window.location.href = 'https://sc.glolime.ru/tg/pset/' + res.token
            }
          })
      } catch (error: any) {
        this.catchError(error)
      } finally {
        this.bankBindingFlag = false
      }
    },
    checkBankCardsAndSbp () {
      if (this.bankBindings.length === 0 && this.sbpSubscriptions.length === 0) {
        this.checkBankBindingsAndSbp = false
        this.settings.autoPayedService = 3
      } else {
        this.disabled = false
        this.checkBankBindingsAndSbp = true
      }
    },
    getDateFormat (date: any) {
      moment.locale('ru')
      console.log(date)
      return moment(date).format('DD MMMM YYYY, HH:mm')
    },
    catchError (error: any) {
      const err = error as AxiosError
      this.errorMessage = this.errorMessageUnknown
      if (err.response) {
        this.errorMessage = err.response.data.errorMessage
      }
      this.error = true
    }
  }
})
</script>
