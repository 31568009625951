<template>
  <v-container>
    <v-row class="justify-content-center">
      <v-col>
        <h1>
          <strong>Дети</strong>
        </h1>
        <br>
        <a style="cursor: pointer" @click="back">Назад</a>
        <v-row align="center">
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Добавить детей"
              loading-text="Подождите..."
              :is-loading="loading"
              :disabled="disabled"
              @submit-clicked="addChilds"
            />
          </v-col>
          <v-col>
            <submit-button
              color="success"
              name="place-electron-talon-search-button"
              text="Сохранить"
              loading-text="Подождите..."
              :is-loading="loading"
              :disabled="disabled"
              @submit-clicked="handleSubmit"
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="placeElectronTalon.placeElectronTalonItems"
          item-key="id"
          class="elevation-1"
          :items-per-page="-1"
          :hide-default-footer="true"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
          :return-object="true"
        >
          <template
            v-if="!loading"
            #top
          >
            <span>
              <p
                v-if="placeElectronTalon.talonNumber != 0"
                align="center"
                style="padding: 15px"
              >
                Талон № {{ placeElectronTalon.talonNumber }} от {{ placeElectronTalon.date }} - класс {{ className }}
              </p>
            </span>
          </template>
          <template
            v-if="loading"
            #no-data
          >
            <v-alert
              :value="false"
              icon="warning"
            >
              Нет данных
            </v-alert>
          </template>
          <template
            v-if="!loading"
            #body="props"
          >
            <tr
              v-for="(index, key) in props.items"
              :key="index.id"
            >
              <td
                :class="rowClasses(index.error)"
                style="width: max-content"
              >
                {{ index.user.fio }}
              </td>
              <td
                v-if="index.user.userRatePlan != null"
                style="text-align:center;"
              >
                {{ index.user.userRatePlan.ratePlan.placeCustomerCustomerRatePlans[0]?.ratePlan?.code }}
              </td>
              <td v-else />
              <td
                v-for="(header) in headers.filter((header) => header.value !== 'fio' && header.value !== 'ratePlan' && header.value !== 'action')"
                :key="header.value"
                class="text-center"
              >
                <span
                  v-for="(product, key2) in placeElectronTalon.placeElectronTalonItems[key].user.products"
                  :key="product.productId"
                >
                  <span v-if="product.index == header.value">
                    <v-checkbox
                      v-model="placeElectronTalon.placeElectronTalonItems[key].user.products[key2].value"
                      class="d-inline-flex"
                      :checked="placeElectronTalon.placeElectronTalonItems[key].user.products[key2].value"
                    />
                  </span>
                </span>
              </td>
              <td style="text-align:center;">
                <v-btn
                  v-if="index.error != null"
                  action
                  @click="getErrors(index)"
                >
                  Ошибки
                </v-btn>
                <v-btn
                  v-if="index.error != null"
                  action
                  @click="removeItem(index)"
                >
                  Исключить
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { ServerError } from '@/shared/lib/api'
import {
  PlaceElectronTalonGetByIdPost,
  PlaceElectronTalonSavePost,
  PlaceElectronTalonUserRemovePost
} from '@/shared/api/placeElectronTalon/api-types'
import {
  save as placeElectronTalonSave,
  userRemove as PlaceElectronTalonUserRemove
} from '@/shared/api/placeElectronTalon/api-requests'
import SubmitButton from '@/shared/uikit/inputs/SubmitButton.vue'
import { PlaceElectronTalon, PlaceElectronTalonItem } from '@/store/placeElectronTalonList/types'
import { ErrorGroups, ErrorMessages, Errors } from '@/app/types/vueEnums'
import Swal from 'sweetalert2'

type BaseData = {
  result: [];
  loading: boolean;
  disabled: boolean;
  className: string;
  placeElectronTalonGetByIdPost: PlaceElectronTalonGetByIdPost;
  placeElectronTalonSavePost: PlaceElectronTalonSavePost;
  placeElectronTalonUserRemovePost: PlaceElectronTalonUserRemovePost;
  placeElectronTalon: PlaceElectronTalon;
  placeCustomerProducts: any;
  selectedPlaceCustomerProduct: any;
}
export default defineComponent({
  components: {
    SubmitButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    placeId: {
      type: String,
      required: true
    },
    selectedPlaceItemId: {
      type: String,
      required: true
    }
  },
  data (): BaseData {
    return {
      result: [],
      loading: true,
      disabled: false,
      className: '',
      selectedPlaceCustomerProduct: '',
      placeElectronTalonSavePost: {
        id: 0,
        date: '',
        placeElectronTalonItems: []
      },
      placeElectronTalonGetByIdPost: {
        id: 0,
        itemsInclude: true,
        salesInclude: false,
        historyInclude: false,
        productsInclude: true
      },
      placeElectronTalonUserRemovePost: {
        id: 0
      },
      placeCustomerProducts: [],
      placeElectronTalon: {
        id: 0,
        placeId: 0,
        parentPlaceId: 0,
        talonYear: 0,
        talonNumber: 0,
        creationDate: null,
        ownerUserId: 0,
        updateUserId: 0,
        updateTimestamp: null,
        date: null,
        status: 0,
        guid: null,
        controlUserId: 0,
        controlTimestamp: 0,
        ownerTimestamp: null,
        placeElectronTalonItems: [],
        placeElectronTalonSales: [],
        placeElectronTalonHistoryItems: [],
        placeElectronTalonProducts: [],
        place: {
          id: 0,
          parentId: 0,
          name: '',
          typeId: 0,
          latitude: null,
          longitude: null,
          creationDate: null,
          name2: '',
          updateTimestamp: null,
          removed: null,
          shortName: '',
          ownerUserId: 0,
          placeTempAttributes: []
        }
      }
    }
  },
  computed: {
    headers () {
      const a = [
        { title: 'Ф.И.О', align: 'start', value: 'fio' },
        { title: 'Категория', align: 'center', value: 'ratePlan' }
      ]
      let placeElectronTalonProducts: any = []
      if (this.placeElectronTalon.placeElectronTalonProducts !== undefined) {
        placeElectronTalonProducts = this.placeElectronTalon.placeElectronTalonProducts
      }
      placeElectronTalonProducts.sort((a: any, b: any) => (a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0))
      placeElectronTalonProducts.forEach(function (item: any) {
        let headerItem: any = []
        headerItem = { title: item.product.name, align: 'center', value: 'products' + item.productId, sortable: false }
        a.push(headerItem)
      })
      let headerItem: any = []
      headerItem = { title: 'Действие', align: 'center', value: 'action', sortable: false }
      a.push(headerItem)
      return a
    }
  },
  methods: {
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapActions('placeElectronTalon', { placeElectronTalonFind: 'getById' }),
    async getTalonForEdit (placeElectronTalonGetByIdPost: PlaceElectronTalonGetByIdPost) {
      const n: PlaceElectronTalon = await this.placeElectronTalonFind(placeElectronTalonGetByIdPost)
      if (!n) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Группа не найдена.',
          timeout: 3000
        })
        this.$router.push({ name: 'TalonRegister' })
        return
      }
      this.placeElectronTalon = JSON.parse(JSON.stringify(n))
      if (this.placeElectronTalon.place.placeTempAttributes.length > 0) {
        this.className = this.placeElectronTalon.place.placeTempAttributes.sort(this.sortByDate)[0].name
      } else {
        this.className = this.placeElectronTalon.place.name
      }
      let placeElectronTalonProducts: any = []
      if (this.placeElectronTalon.placeElectronTalonProducts !== undefined) {
        placeElectronTalonProducts = this.placeElectronTalon.placeElectronTalonProducts
      }
      if (this.placeElectronTalon.placeElectronTalonItems !== undefined) {
        this.placeElectronTalon.placeElectronTalonItems = this.placeElectronTalon.placeElectronTalonItems.map((placeElectronTalonItem: PlaceElectronTalonItem) => {
          placeElectronTalonItem.user.fio = placeElectronTalonItem.user.lastName + ' ' +
            placeElectronTalonItem.user.firstName + ' ' + placeElectronTalonItem.user.middleName
          if (placeElectronTalonItem.user.userRatePlans.length > 0) {
            placeElectronTalonItem.user.ratePlan = placeElectronTalonItem.user.userRatePlans.sort(this.sortByCreationDate)[0].ratePlanId
          } else {
            placeElectronTalonItem.user.ratePlan = null
          }
          placeElectronTalonItem.user.products = []
          const userProducts: any = []
          placeElectronTalonProducts.forEach(function (item: any) {
            const products: any = []
            products.productId = item.productId
            products.value = false
            products.name = item.product.name
            products.index = 'products' + item.productId
            if (placeElectronTalonItem.placeElectronTalonItemSales !== undefined) {
              let productExists = 0
              placeElectronTalonItem.placeElectronTalonItemSales.forEach(function (item2: any) {
                if (item2.productId === item.productId && item2.removed === null) {
                  productExists = 1
                }
              })
              if (productExists === 1) {
                products.value = true
              }
            }
            userProducts.push({ index: products.index, productId: products.productId, value: products.value, name: products.name })
          })
          placeElectronTalonItem.user.products = userProducts
          return placeElectronTalonItem
        })
      }

      this.loading = false
    },
    getErrors (index: any) {
      if (index.error) {
        let textError = ''
        let textWarnings = ''
        index.error.split(',').forEach((error: any) => {
          const key: string = Errors[error]
          const errorType: number = ErrorGroups[key as keyof typeof ErrorGroups]
          if (errorType === 1 || errorType === 2) {
            textError = textError + ErrorMessages[error] + '<br>'
          } else {
            textWarnings = textWarnings + ErrorMessages[error] + '<br>'
          }
        })
        Swal.fire({
          title: '',
          html: '<h2>Ошибки в талонах:</h2><br>' + textError + '<br><h2>Предупреждения в талонах:</h2><br>' + textWarnings
        })
      }
    },
    async removeItem (index: any) {
      if (index) {
        try {
          this.placeElectronTalonUserRemovePost.id = index.id
          const result = await PlaceElectronTalonUserRemove(this.placeElectronTalonUserRemovePost)
          if (result === true) {
            this.placeElectronTalon.placeElectronTalonItems = this.placeElectronTalon.placeElectronTalonItems?.filter(el => el.id !== index.id)
          } else {
            Swal.fire({
              title: 'Ошибка исключения',
              html: 'Исключение из талона невозможно'
            })
          }
        } catch (error: any) {
          this.setSnackbar({
            showing: true,
            text: 'Ошибка сервера. Повторите попытку позже.',
            timeout: 3000
          })
        }
      } else {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка интерфейса. Сообщите об ошибке.',
          timeout: 3000
        })
      }
    },
    rowClasses (item: any) {
      let levelError = 100
      if (item) {
        item.split(',').forEach((error: any) => {
          const key: string = Errors[error]
          for (const groupValue in ErrorGroups) {
            if (groupValue === key) {
              if (levelError > parseInt(ErrorGroups[groupValue])) {
                levelError = parseInt(ErrorGroups[groupValue])
              }
            }
          }
        })
      }
      if (levelError < 100) {
        return 'error-row-' + levelError.toString()
      }
    },
    sortByDate (a: any, b: any) {
      if (a.timestampFrom < b.timestampFrom) return 1
      else return -1
    },
    sortByCreationDate (a: any, b: any) {
      if (a.creationDate < b.creationDate) return 1
      else return -1
    },
    addChilds () {
      this.$router.push({ name: 'AddChilds', params: { id: this.id, date: this.date, placeId: this.placeId } })
    },
    async handleSubmit () {
      this.loading = true
      try {
        this.placeElectronTalonSavePost.id = this.placeElectronTalon.id
        this.placeElectronTalonSavePost.date = this.placeElectronTalon.date
        this.placeElectronTalonSavePost.placeElectronTalonItems = this.placeElectronTalon.placeElectronTalonItems
        const result = await placeElectronTalonSave(this.placeElectronTalonSavePost)
      } catch (error: any) {
        const e = error as ServerError
        if (e && (e.error || e.errors)) {
          //
        }
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Повторите попытку.',
          timeout: 3000
        })
      } finally {
        if (this.result) {
          this.placeElectronTalonGetByIdPost.id = Number(this.placeElectronTalon.id)
          await this.getTalonForEdit(this.placeElectronTalonGetByIdPost)
        }
        this.loading = false
      }
    },
    back () {
      this.$router.push({ name: 'TalonRegister', query: {back: 'true', date: this.date, placeId: this.placeId, selectedPlaceItemId: this.selectedPlaceItemId} })
    }
  },
  async mounted () {
    this.loading = true
    if (!this.id) {
      this.$router.push({ name: 'dashboard' })
    }
    this.placeElectronTalonGetByIdPost.id = Number(this.id)
    await this.getTalonForEdit(this.placeElectronTalonGetByIdPost)
  }
})
</script>

<style lang='scss'></style>
