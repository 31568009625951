<template>
  <div class="whole_page">
    <div class="page_container">
      <template v-if="!isLoading">
        <div class="content_container">
          <div style="display: flex; flex-direction: column; gap: 10px">
            <a
              href="https://school.glolime.ru/new_site/telegram/instructions/autopay/"
              target="_blank"
              rel="noopener noreferrer"
              style="display: flex; flex-direction: row; gap: 5px; padding: 8px"
            >
              <img
                src="@/assets/images/info.png"
                height="26"
                width="26"
                class="white-png"
              >
              <h4
                class="button-text-roboto-medium"
                style="text-align: center; align-self: center"
              >
                Об интеллектуальном платеже
              </h4>
            </a>
            <button
              class="primary-green-button"
              style="width: 100%"
            >
              Сохранить
            </button>
            <v-row
              class="content_row"
            >
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <h3
                  class="button-text-roboto-medium"
                  style="text-align: center"
                >
                  Активная подписка
                </h3>
                <v-select
                  variant="solo"
                  density="compact"
                  hide-details="true"
                  style="max-width: 145px"
                />
              </div>
            </v-row>
            <v-row
              class="content_row"
            >
              <v-card>
                <v-tabs bg-color="green-accent-2">
                  <v-tab value="0">
                    Горячее
                  </v-tab>
                  <v-tab value="1">
                    Питание
                  </v-tab>
                </v-tabs>
                <v-window>
                  <v-window-item
                    :key="0"
                  >
                    <v-row class="content_row" style="padding: 12px 10px">
                      <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                        <h3 class="title-h3-roboto-medium">
                          Платеж включен
                        </h3>
                        <div>
                          <label class="toggle-switch">
                            <input

                              type="checkbox"
                            >
                            <div class="slider round" />
                          </label>
                        </div>
                      </div>
                      <div style="display: flex; flex-direction: row">
                        <v-col>
                          <h4 class="body-text-roboto-medium">
                            Периодичность
                          </h4>
                          <v-radio-group>
                            <v-radio
                              class="text-center"
                              label="День"
                              :value="1"
                            />
                            <v-radio
                              class="text-center"
                              label="Неделя"
                              :value="2"
                            />
                            <v-radio
                              class="text-center"
                              label="Месяц"
                              :value="3"
                            />
                          </v-radio-group>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="2"
                        >
                          <v-select
                            variant="solo"
                            density="compact"
                            hide-details="true"

                            style="position: relative;  left: -80px; top: 60px;"
                          />
                        </v-col>
                        <v-col
                          cols="1"
                          sm="1"
                        />
                      </div>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-row>
            <v-row class="content_row">
              <v-card>
                <v-tabs
                  v-model="tabDates"
                  bg-color="green-accent-2"
                >
                  <v-tab value="0">
                    Выходные дни
                  </v-tab>
                  <v-tab value="1">
                    Каникулы
                  </v-tab>
                </v-tabs>
                <v-window v-model="tabDates">
                  <v-window-item
                    :key="1"
                    style="height: 330px;"
                  >
                    <v-row justify="space-around">
                      <v-date-picker
                        style="top:20px"
                        multiple
                        hide-weekdays
                        hide-header
                      />
                    </v-row>
                  </v-window-item>
                  <v-window-item
                    :key="2"
                    style="height: 380px;"
                  >
                    <v-row class="holidays_row">
                      <v-col
                        cols="4"
                        style="align-self: center"
                      >
                        Осенние
                      </v-col>
                      <v-col style="padding: 0px;">
                        <v-menu
                          :model-value="menu"
                          :close-on-content-click="false"
                          :offset="60"
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-text-field
                              style="padding: 0px; width: 95%;"

                              prepend-icon="mdi-calendar"
                              hide-details="true"
                              variant="solo"
                              readonly

                              v-bind="props"
                            />
                          </template>
                          <v-date-picker

                            hide-weekdays
                            hide-header
                            multiple="range"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="holidays_row">
                      <v-col
                        cols="4"
                        style="align-self: center"
                      >
                        Зимние
                      </v-col>
                      <v-col style="padding: 0px;">
                        <v-menu
                          :model-value="menu"
                          :close-on-content-click="false"
                          :offset="60"
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-text-field
                              style="padding: 0px; width: 95%;"

                              prepend-icon="mdi-calendar"
                              hide-details="true"
                              variant="solo"
                              readonly

                              v-bind="props"
                            />
                          </template>
                          <v-date-picker

                            hide-weekdays
                            hide-header
                            multiple="range"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="holidays_row">
                      <v-col
                        cols="4"
                        style="align-self: center"
                      >
                        Весенние
                      </v-col>
                      <v-col style="padding: 0px;">
                        <v-menu
                          :model-value="menu"
                          :close-on-content-click="false"
                          :offset="60"
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-text-field
                              style="padding: 0px; width: 95%;"

                              prepend-icon="mdi-calendar"
                              hide-details="true"
                              variant="solo"
                              readonly

                              v-bind="props"
                            />
                          </template>
                          <v-date-picker

                            hide-weekdays
                            hide-header
                            multiple="range"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="holidays_row">
                      <v-col
                        cols="4"
                        style="align-self: center"
                      >
                        Летние
                      </v-col>
                      <v-col style="padding: 0px;">
                        <v-menu
                          :model-value="menu"
                          :close-on-content-click="false"
                          :offset="60"
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-text-field
                              style="padding: 0px; width: 95%;"

                              prepend-icon="mdi-calendar"
                              hide-details="true"
                              variant="solo"
                              readonly

                              v-bind="props"
                            />
                          </template>
                          <v-date-picker

                            hide-weekdays
                            hide-header
                            multiple="range"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="holidays_row">
                      <v-col
                        cols="4"
                        style="align-self: center"
                      >
                        1-е классы
                      </v-col>
                      <v-col style="padding: 0px;">
                        <v-menu
                          :model-value="menu"
                          :close-on-content-click="false"
                          :offset="60"
                          transition="scale-transition"
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-text-field
                              style="padding: 0px; width: 95%;"

                              prepend-icon="mdi-calendar"
                              hide-details="true"
                              variant="solo"
                              readonly

                              v-bind="props"
                            />
                          </template>
                          <v-date-picker

                            hide-weekdays
                            hide-header
                            multiple="range"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-row>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="text-center"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="green"
            indeterminate
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ParentsSubscriptionAutoPaymentSettings',
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  data () {
    return {
      isLoading: false,
      isTelegram: false,
      tabDates: 0,
      menu: false,
      weekDays: [
        {
          id: '1',
          name: 'Пн'
        },
        {
          id: '2',
          name: 'Вт'
        },
        {
          id: '3',
          name: 'Ср'
        },
        {
          id: '4',
          name: 'Чт'
        },
        {
          id: '5',
          name: 'Пт'
        },
        {
          id: '6',
          name: 'Сб'
        },
        {
          id: '7',
          name: 'Вс'
        }
      ],
    }
  },
  methods: {

  }
}
)
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables";

.content_container {
  width: 700px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px 12px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.control_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  //width: 100%
}
.content_row {
  padding: 12px 0px;
  margin: 0px 1px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $color-dark-50;
  gap: 16px;
}
.holidays_row {
  padding: 10px;
  margin-top: 10px;
  justify-content: space-around;
  border-bottom: 2px solid $color-dark-50;
}
@media (max-aspect-ratio: 6/5) {
  .content_container {
    width: 100%;
  }
}
</style>
