<template>
  <div id="TelegramLogin">
    <template v-if="!isLoading">
      <div class="page-content">
        <div class="attention_notification">
          <div class="attention-notification-text-box">
            <h4 class="body-text-roboto-medium">
              Данный функционал
              доступен только в школах, где предусмотрен предварительный заказ питания школьника.
            </h4>
          </div>
        </div>
        <div class="content-card">
          <template v-if="!showLoginForm">
            <h1 class="title-h1-roboto-medium">
              Вход через телеграм
            </h1>
            <button
              v-if="!authUsers"
              class="primary-green-button"
              @click="openLoginForm"
            >
              <img
                src="~@/assets/images/add_circle.png"
                height="20"
                width="20"
              > Добавить учетную запись
            </button>
            <template v-if="authUsers">
              <h3 class="title-h4-roboto-medium">
                Выберите учетную запись
              </h3>
              <template
                v-for="userItem in authUsers"
                :key="userItem.user.id"
              >
                <button
                  class="primary-green-button"
                  style="width: 279px;height: 46px"
                  @click="authUser(userItem)"
                >
                  {{ userItem.user.firstName }} {{ userItem.user.lastName.substring(0, 1) }}.
                </button>
              </template>
              <button
                class="secondary-white-button"
                @click="openLoginForm"
              >
                <img
                  src="~@/assets/images/add_circle.png"
                  height="20"
                  width="20"
                > Добавить учетную запись
              </button>
            </template>
            <template v-else>
              <p class="body-text-roboto-medium">
                Привязанные учетные записи не найдены!
              </p>
            </template>
          </template>
          <template v-else>
            <div class="login-content">
              <h1 class="title-h1-roboto-medium">
                Вход через телеграм
              </h1>
              <div class="textField">
                <input
                  v-model="login"
                  style="width: 281px; height: 46px"
                  type="text"
                  placeholder="Логин"
                >
              </div>
              <div class="textField">
                <input
                  v-model="password"
                  style="width: 281px; height: 46px"
                  type="password"
                  placeholder="Пароль"
                >
              </div>
              <div class="login-buttons">
                <button
                  class="primary-green-button"
                  :disabled="!login || !password"
                  @click="loginAuthUser"
                >
                  Добавить
                </button>
                <button
                  class="secondary-white-button"
                  style="width: 132.5px; height: 46px"
                  @click="closeLoginForm"
                >
                  Отмена
                </button>
              </div>
            </div>
          </template>
        </div>

      </div>
    </template>
    <template v-else>
      <div
        style="margin-top: 60px;"
        class="text-center"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="green"
          indeterminate
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import {
  TelegramAuthUserLoginApiRequest,
  TelegramAuthUsersApiRequest,
  TelegramAuthUsersItem
} from '@/shared/api/preorders/api-types'
import { telegramAuthUserLogin, telegramAuthUsers } from '@/shared/api/preorders/api-requests'
import { SET_USER } from '@/store/user/mutations'
import { UserShort } from '@/store/user/types'
import { store } from '@/app/providers'
import { RouteParamValue, useRoute } from 'vue-router'

export default defineComponent({
  name: 'TelegramLoginNew',
  data () {
    return {
      tgToken: '',
      chatId: '',
      isLoading: false,
      authUsers: null as null | TelegramAuthUsersItem[],
      showLoginForm: false,
      login: '',
      password: '',
      isTelegram: false,
      route: useRoute()
    }
  },
  async beforeMount () {
    if (window.Telegram?.WebApp) {
      this.isTelegram = true
    }
    const [chatId, tgToken]: any = this.splitByChar(this.route.params.tgParams)
    this.chatId = chatId
    this.tgToken = tgToken
    this.isLoading = true
    await this.getAuthUsers()
    this.isLoading = false
  },
  methods: {
    splitByChar (str: string | RouteParamValue[]) {
      console.log(str)
      if (typeof str !== 'string') {
        str = str.join('')
      }
      if (str.includes('&')) {
        console.log('1cond')
        return str.split('&')
      } else {
        console.log('2cond')
        return str.split('%7C')
      }
      // 909068271%7C0D6975FA195C4EB8B5BB946C00DF9ADE
      // 909068271%7C0D6975FA195C4EB8B5BB946C00DF9ADE
    },
    showWarning (): boolean {
      const regionIdsWithoutWarning = [1016]
      if (this.authUsers && this.authUsers?.length > 0) {
        const userInRegionWithoutWarning = this.authUsers.find(user => regionIdsWithoutWarning.includes(user.placeInfo?.id))
        return !userInRegionWithoutWarning
      }
      return true
    },
    ...mapActions('snackbar', { setSnackbar: 'setSnackbar' }),
    ...mapMutations('user', { setUser: SET_USER }),
    async getAuthUsers () {
      console.log('getAuthUsers')
      try {
        const telegramAuthUsersReqObj: TelegramAuthUsersApiRequest = {
          chatId: this.chatId,
          token: this.tgToken
        }
        const res = await telegramAuthUsers(telegramAuthUsersReqObj)
        if (res.data?.length > 0) {
          this.authUsers = res.data
        }
        console.log('telegramAuthUsers', res)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось получить список авторизованных пользователей!',
          timeout: 3000
        })
      } finally {
      }
    },
    openLoginForm () {
      console.log('openLoginForm')
      this.showLoginForm = true
    },
    closeLoginForm () {
      console.log('closeLoginForm')
      this.showLoginForm = false
      this.login = ''
      this.password = ''
    },
    async loginAuthUser () {
      console.log('loginAuthUser')
      try {
        this.isLoading = true
        const telegramAuthUserLoginReqObj: TelegramAuthUserLoginApiRequest = {
          chatId: this.chatId,
          login: this.login,
          password: this.password
        }
        console.log(telegramAuthUserLoginReqObj)
        const res = await telegramAuthUserLogin(telegramAuthUserLoginReqObj)
      } catch (e) {
        this.setSnackbar({
          showing: true,
          text: 'Ошибка. Не удалось выполнить вход!',
          timeout: 3000
        })
      } finally {
        await this.refreshData()
        this.isLoading = false
      }
    },
    async authUser (userItem: TelegramAuthUsersItem) {
      console.log('authUser')
      console.log(userItem.placeSettings?.findIndex(value => value.placeSettingTypeId === 32 && value.value === 'on'))
      console.log()
      if (userItem.placeSettings !== null) {
        const existParentIdx = userItem.placeSettings.findIndex(value => value.placeSettingTypeId === 32 && value.value === 'on')
        if (existParentIdx !== -1) {
          console.log('zahodi')
          const userStoreObj: UserShort = {
            id: userItem.user.id,
            // eslint-disable-next-line
            tokens: { access_token: userItem.accessToken, refresh_token: '' },
            username: userItem.user.firstName
          }
          this.setUser(userStoreObj)
          localStorage.setItem('accessToken', userItem.accessToken)
          console.log(this.chatId, this.tgToken)
          localStorage.setItem('chatId', this.chatId)
          localStorage.setItem('tgToken', this.tgToken)
          await store.dispatch('user/getPerson')
          await this.$router.push('/parents/home')
        } else {
          this.setSnackbar({
            showing: true,
            text: 'Доступ временно ограничен',
            timeout: 3000
          })
        }
      } else {
        this.setSnackbar({
          showing: true,
          text: 'Доступ временно ограничен',
          timeout: 3000
        })
      }
    },
    async refreshData () {
      this.isLoading = true
      this.closeLoginForm()
      await this.getAuthUsers()
      this.isLoading = false
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/app/styles/uikit-variables.scss";
.page-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 12px;
    gap: 16px;
    width: 100%;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }

  .login-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 281px;
    height: 46px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 281px;
    height: 224px;
  }

  .alert_msg {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    background: #FFF3DD;
    border: 1px solid #EBEAEC;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
}
@media (max-aspect-ratio: 6/5) {
  .content-card {
    left: 16px;
  }
}
</style>
